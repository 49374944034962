<div class="tab-form">
    <div class="form-80">
        <h1 class="blue margin">{{ title }}</h1>
        <p class="lead rocal">
            {{ subtitle }}
        </p>

        <div class="flex blue-box">
            <p class="blue lead">
                {{ appointmentDetails }}
            </p>
            <div class="line-2"></div>
            <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
                <mat-radio-group aria-labelledby="appointment-radio-group-label"
                    (change)="handleForm()"
                    class="flex column"
                    style="width: 100%;"
                    [(ngModel)]="appointmentOption">
                    <ng-container *ngFor="let option of appointmentOptions; let first = first; let last = last">
                        <mat-radio-button class="appointment-button" [value]="option">
                            <strong>{{ option }}</strong>
                        </mat-radio-button>
                        <div class="flex width-100" style="height: fit-content;" *ngIf="appointmentOption !== ''">
                            <form [formGroup]="virtualForm" class="flex option" *ngIf="appointmentOption == option1 && first">
                                <div class="row">
                                    <app-input [formGroup]="virtualForm" controlName="Date"
                                        label={{dateLabel}} hint={{dateHint}}
                                        [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        [weekdaysOnly]="true"
                                        [includeSaturdays]="true"
                                        inputType="date"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="virtualForm" controlName="Time"
                                        inputType="time"
                                        label={{timeLabel}} hint={{timeHint}}
                                        [subtext]="virtualOfficeText"
                                        [selectedVirtualOfficeDay]="selectedVirtualMeetinDay"
                                        [enforceVirtualOfficeHours]="true"
                                        [timeFunction]="timeFunction.bind(this)"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="row half">
                                    <app-input [formGroup]="virtualForm" controlName="Email"
                                        [type]="'email'"
                                        label={{emailLabel}} hint={{emailHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>
                            </form>

                            <form [formGroup]="officeForm" class="flex option" *ngIf="appointmentOption == option2 && !first && !last">
                                <div class="row">
                                    <app-input [formGroup]="officeForm" controlName="Date"
                                        label={{dateLabel}} hint={{dateHint}}
                                        [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        [weekdaysOnly]="true"
                                        inputType="date"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="officeForm" controlName="Time"
                                        inputType="time"
                                        label={{timeLabel}} hint={{timeHint}} subtext="Office hours are between 8.00 AM - 4.30 PM"
                                        [enforcePhysicalOfficeHours]="true"
                                        [timeFunction]="timeFunction.bind(this)"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="row half">
                                    <app-input [formGroup]="officeForm" controlName="PersonsNumber"
                                        label={{personsLabel}} hint={{personsHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>
                            </form>

                            <form [formGroup]="locationForm" class="flex option" *ngIf="appointmentOption == option3 && last">
                                <div class="row">
                                    <app-input [formGroup]="locationForm" controlName="Date"
                                        label={{dateLabel}} hint={{dateHint}}
                                        [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        [weekdaysOnly]="true"
                                        inputType="date"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="locationForm" controlName="Time"
                                        inputType="time"
                                        label={{timeLabel}} hint={{timeHint}} subtext="Office hours are between 8.00 AM - 4.30 PM"
                                        [enforcePhysicalOfficeHours]="true"
                                        [timeFunction]="timeFunction.bind(this)"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="row half">
                                    <app-input [formGroup]="locationForm" controlName="Location"
                                        label={{locationLabel}} hint={{locationHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>
                            </form>
                            
                        </div>
                    </ng-container>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? 'Please Wait' : 'Submit'"
        [formValid]="isFormValid()"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</div>
