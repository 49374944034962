import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-form-bottom',
  templateUrl: './form-bottom.component.html',
  styleUrls: ['./form-bottom.component.scss']
})
export class FormBottomComponent {
  @Input() text: string = 'Next';
  @Input() buttonClickHandler!: () => void;
  @Input() saveFunction: () => Promise<void> = async ()=> {};
  @Input()
  formValid!: boolean;

  @Input() showPrevious: string = 'true';
  @Input() showContinue: string = 'true';

  constructor(
    private tabControlService: TabControlService,
    private apiService: ApiService,
    private router: Router ) {}

  get activeTab(): number {
    return this.tabControlService.activeTab;
  }
  get activeTabEstate(): number {
    return this.tabControlService.activeTabEstate;
  }
  get loadingUpdate(): boolean {
    return this.tabControlService.loadingUpdate;
  }

  toPreviousTab(): void {
    if (this.tabControlService.mainJourney == 0) {
      const previousTab = this.activeTab-1;
      if (previousTab === 0) {
        this.router.navigate(['/get-started']);
      } else {
        this.tabControlService.changeTab(previousTab);
      }
    } else {
      const previousTab = this.activeTabEstate-1;
      if (previousTab === 0) {
        this.router.navigate(['/get-started']);
      } else {
        this.tabControlService.changeTabEstate(previousTab);
      }
    }
    
  }
}
