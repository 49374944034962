<form [formGroup]="paymentForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal" style="font-weight: 500;">{{subtitle}}</p>

        <div class="flex blue-box" >
            <p class="blue lead" >
                {{'Payment'}}
            </p>
            <div class="line-2"></div>

            <div class="row width-50" >
                <app-input
                    [formGroup]="paymentForm"
                    controlName="PaymentMode"
                    label={{paymentModeLabel}}
                    inputType="select"
                    [ArrayItems]="PaymentModeOptions"
                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
            </div>
            <!-- MPESA SELECTED ? -->
            <div class="mini" *ngIf="paymentForm.controls['PaymentMode'].value === 'M-Pesa'">
                <div class="row">
                    <app-input [formGroup]="paymentForm" controlName="phone"
                        label={{phoneLabel}} inputType="phone"
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    <app-input [formGroup]="paymentForm" controlName="amount"
                        label={{investmentAmountLabel}} hint={{investmentAmountHint}}
                        [subtext]="investmentAmountSubtext"
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
                <div class="badge-wrapper">
                    <div class="badge">
                        <p class="lead blue" style="font-size: larger; margin-left: 2.8%; font-weight: 600;">
                            {{instructionsTitle}}
                        </p>
                        <ol>
                            <li>{{inst1}}</li>
                            <li>{{inst2}}</li>
                            <li>{{inst3}}</li>
                        </ol>

                        <p class="lead blue" style="font-size: larger; margin-left: 2.8%; font-weight: 600;">
                            {{instructionsTitle2}}
                        </p>
                        <ul>
                            <li>{{inst4}}</li>
                            <li>{{inst5}}<b>4230088</b></li>
                            <li>{{inst6}}<b>{{ paymentID }}</b></li>
                        </ul>
                        <div style="height: 20px;"></div>
                    </div>
                </div>
                
            </div>
    
            <!-- BANK SELECTED ? -->
            <div class="mini" *ngIf="paymentForm.controls['PaymentMode'].value !== 'M-Pesa'">
                <div class="row">
                    <app-input [formGroup]="paymentForm" controlName="amount"
                        label={{investmentAmountLabel}} hint={{investmentAmountHint}} [subtext]="investmentAmountSubtext"
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    <app-input [formGroup]="paymentForm" controlName="bank"
                        [selectFunction]="populateBankBranchesList.bind(this)"
                        inputType="select"
                        [ArrayItems]="BanksOptions"
                        [loadingOptions]="loadingBanks"
                        label={{bankLabel}} hint={{bankHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>
    
                <div class="row">
                    <app-input [formGroup]="paymentForm" controlName="bankBranch"
                        label={{bankBranchLabel}} hint={{bankBranchHint}}
                        inputType="select"
                        [ArrayItems]="BranchPlaceHolders"
                        [loadingOptions]="loadingBankBranches"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input [formGroup]="paymentForm" controlName="accName"
                        label={{accNameLabel}} hint={{accNameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
    
                <div class="row">
                    <app-input [formGroup]="paymentForm" controlName="acc"
                        label={{accLabel}} hint={{accHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input
                         >
                    <app-input [formGroup]="paymentForm" controlName="slipDoc"
                        [fileFunction]="updateSlipObject.bind(this)"
                        label={{uploadSlipLabel}} inputType="file"
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
    
                <div class="bankBox-wrap">
                    <div class="bankBox">
                        <p class="lead blue" style="margin-bottom: -20px; margin-left: 0px; font-weight: 600;">
                            {{instructionsTitleBank}}
                        </p>
                        <div class="line"></div>
                        <div class="row">
                            <div>{{iceaFundLabel}}</div> <div class="right-divs">{{iceaFundNameVal}}</div>    
                        </div>
                        <div class="row">
                            <div>{{iceaAcc}}</div> <div class="right-divs">{{iceaAccountVal}}</div>
                        </div>
                        <div class="row">
                            <div>{{iceaBank}}</div> <div class="right-divs">{{iceaBankVal}}</div>
                        </div>
                        <div class="row">
                            <div>{{iceaBranch}}</div> <div class="right-divs">{{iceaBranchVal}}</div>
                        </div>
                        <div class="row">
                            <div>{{iceaBankCode}}</div> <div class="right-divs">{{iceaBankCodeVal}}</div>
                        </div>
                        <div class="row">
                            <div>{{iceaBranchCode}}</div> <div class="right-divs">{{iceaBranchCodeVal}}</div>
                        </div>
                        <div class="row">
                            <div>{{iceaClearing}}</div> <div class="right-divs">{{iceaClearingVal}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? buttonText : 'Submit'"
        [formValid]="paymentForm.valid"
        [showContinue]="paymentForm.valid ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [buttonClickHandler]="submitForm.bind(this)"></app-form-bottom>
</form>