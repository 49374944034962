<div class = "flex editor-container">
    <div class="flex editor-overlay">
        <div class="nonsense" (click)="closePopup()"></div>
        <form [formGroup]="bookForm" (ngSubmit)="submitRequest()" class="flex editor-content" autocomplete="off" novalidate>
            <div class="flex x-div">
                <div (click)="closePopup()" style="cursor: pointer;" *ngIf="!loading">
                    <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
                </div>
                
            </div>

            <div class="flex mid-div">
                <div class="flex l-div">
                    <span class="sub-t">
                        Need Help?
                    </span>
                    <span class="big-t">
                        WE ARE HERE TO ASSIST YOU
                    </span>
                    <span class="smol-t">
                        Do you need more information about the Milele Trust?<br>Request a call back by filling the form
                    </span>
                </div>

                <div class="flex r-div">
                    <div class="row">
                        <app-input [formGroup]="bookForm" controlName="Name"
                            class="grid-item" label={{nameLabel}} hint={{nameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="bookForm" controlName="Email"
                            class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row">
                        <app-input [formGroup]="bookForm" controlName="Phone"
                            class="grid-item" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="bookForm" controlName="Reason"
                            class="grid-item" label={{reasonLabel}} hint={{reasonHint}} required="false"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: smaller;">
                        <mat-checkbox formControlName="iSwear" >
                            <p style="font-size: larger;">I have read and accepted ICEA LION's Online <a href="https://icealion.co.ke/icea-lion-group-privacy-policy/" target="_blank" rel="noopener" style="color: #003764;"><u>Privacy Policy</u></a></p>
                        </mat-checkbox>
                         
                    </div>
                </div>
            </div>

            <div class="flex x-div" style="margin-top: 20px; margin-bottom: 20px;">
                <button mat-button type="submit" class="sub-button"
                    [disabled]="(bookForm.controls['iSwear'].value === false || !bookForm.valid || loading)"
                >
                    <div style="display: flex; flex: 1; align-items: center; color: white !important;">
                        {{buttonText}}

                        <span class="spinning" *ngIf="loading" style="margin-left: 15px;">
                            <mat-progress-spinner
                                mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
                        </span>
                    </div>
                </button>
            </div>

        </form>
    </div>
</div>
