<div class = "flex editor-container">
    <div class="flex editor-overlay">
        <div class="nonsense" (click)="closePopup()"></div>
        <form (ngSubmit)="submitRequest()" class="flex editor-content" autocomplete="off" novalidate>
            <div class="flex x-div">
                <div (click)="closePopup()" style="cursor: pointer;">
                    <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
                </div>
                
            </div>

            <div class="flex column mid-div">
                <h2 class="blue" style="margin: 0px;">Get Started with Estate Planning</h2>
                <p style="margin-top: 8px; margin-bottom: 30px;">Please select an option below</p>

                <div class="flex fill" style=" height: 120px; justify-content: space-between;">

                    <div class="flex option" (click)="setJourneyType(0)"
                        [ngClass]="{'option-selected-left' : journeyType === 0}">
                        <div class="flex center option-tick" *ngIf="journeyType === 0">
                            <mat-icon
                                fontIcon="check"
                                style="color: white"
                                aria-hidden="false"
                                aria-label="selected onboard">
                            </mat-icon>
                        </div>

                        <div class="flex center option-icon">
                            <mat-icon
                                fontIcon="calendar_month"
                                style="color: #003764;"
                                aria-hidden="false"
                                aria-label="Calendar month">
                            </mat-icon>
                        </div>
                        <div class="flex column option-text" >
                            <strong class="blue" style="font-size: medium;">Book an Appointment</strong>
                            <p style="font-size: small; margin-bottom: 0px;">
                                Let us guide you on how to set up your plan
                            </p>
                        </div>
                    </div>

                    <div class="flex option" (click)="setJourneyType(1)"
                        [ngClass]="{'option-selected-right' : journeyType === 1}">
                        <div class="flex center option-tick" *ngIf="journeyType === 1">
                            <mat-icon
                                fontIcon="check"
                                style="color: white"
                                aria-hidden="false"
                                aria-label="selected onboard">
                            </mat-icon>
                        </div>

                        <div class="flex center option-icon">
                            <mat-icon
                                fontIcon="post_add"
                                style="color: #003764;"
                                aria-hidden="false"
                                aria-label="Calendar month">
                            </mat-icon>
                        </div>
                        <div class="flex column option-text" >
                            <strong class="blue" style="font-size: medium;">Create your Plan</strong>
                            <p style="font-size: small; margin-bottom: 0px;">
                                Start now to ensure your assets are protected and your wishes are honored
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex x-div" style="margin-top: 20px; margin-bottom: 10px; border-top: 0.5px solid #CBCBCB; padding-top: 25px;">
                <button mat-button type="submit" class="sub-button">
                    <div style="display: flex; flex: 1; align-items: center; color: white !important;">
                        {{'Next'}}
                    </div>
                </button>

                <button mat-button type="button" class="cancel" (click)="closePopup()">
                    <div style="display: flex; flex: 1; align-items: center; color: #003764 !important;">
                        {{'Cancel'}}
                    </div>
                </button>
            </div>
        </form>
    </div>
</div>
