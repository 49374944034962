import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Constants from "../../constants/constants";
import { ApiService } from 'src/app/services/api/api.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecordData, SessionData, decodeSessionData, extractNumericValue, formatDate, getValueByIdOrDescription } from 'src/app/util/Helper';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-record-editor',
  templateUrl: './record-editor.component.html',
  styleUrls: ['./record-editor.component.scss']
})
export class RecordEditorComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  @Input() recordType: 'Settlor' | 'Guardian' | 'Beneficiary' | 'Info' | null = null;
  @Input() recordIndex: number = 0;
  @Input()
  record!: RecordData;


  // Url Params
  session: string = ""; // used to resume session
  sessionData: SessionData | string = "error";

  BranchPlaceHolders: any = [];

  title: string = `${this.recordType === 'Info' ? 'Edit Trust Information' : this.recordType === 'Guardian' ? 'Edit Guardian Information' : this.recordType === 'Beneficiary' ? 'Edit Beneficiary Information' : 'Edit Settlor Information'}`;

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';

  // Beneficiary specific
  dobLabel: string = 'Date of birth';
  dobHint: string = '01/01/1990';
  shareAllocationLabel: string = 'Share Allocation (%)';
  birthCertLabel: string = 'Upload Birth Certificate of Child';
  shareAllocationSubtext: string = 'Share allocation for all beneficiaries should total 100%';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload KRA PIN';
  regCertFileLabel: string = 'Upload Registration Certificate';

  nationalityLabel: string = 'Nationality';
  nationalityHint: string = 'Select nationality';
  countryLabel: string = 'Country of Residence';
  countryHint: string = 'Select country';
  countyLabel: string = 'County';
  countyHint: string = 'Select county';

  institutionNameLabel: string = 'Name of Institution';
  businessNatureLabel: string = 'Nature of Business';
  orgRegNumLabel: string = 'Organization Registration Number';
  orgAddressLabel: string = 'Organization Physical Address';

  institutionNameHint: string = 'Enter name';
  businessNatureHint: string = 'Enter nature of business';
  orgRegNumHint: string = 'Enter registration Number';
  orgAddressHint: string = 'Enter physical address';
  
  // Settlor specific
  occupationLabel: string = 'Occupation';
  occupationHint: string = 'Enter Occupation';
  // Guardian specific
  guardianTypeLabel: string = 'Guardian Type';
  guardianTypeHint: string = 'Select guardian type';

  benTypeLabel: string = 'Select Beneficiary Type';
  benTypeHint: string = 'Beneficiary type';

  // Trust Info
  trustNameLabel: string = 'Proposed Name of Trust';
  trustNameHint: string = 'Enter the trust name';
  trustNameSubtext: string = 'You can change the name of the Trust';

  purposeLabel: string = 'Purpose of Trust';
  purposeHint: string = 'Select purpose of trust';
  purposeSubtext: string = 'You can select more than one option';

  fundSourceLabel: string = 'Source of Funds';
  fundSourceHint: string = 'Select source of funds';

  upkeepText: string = 'Upkeep Details';
  additionalInfoText: string = 'Additional Information';

  otherPurposeLabel: string = 'Other Purpose';
  otherPurposeHint: string = 'Please specify other purpose';
  // These 2 are dependent on the upper 2
  otherSourceLabel: string = 'Other Source';
  otherSourceHint: string = 'Please specify other source';

  upkeepAmountLabel: string = 'Amount of Upkeep';
  upkeepAmountHint: string = 'Enter amount of upkeep';

  frequencyUpkeepLabel: string = 'Frequency of Upkeep';
  frequencyUpkeepHint: string = 'Select the frequency of upkeep';

  bankLabel: string = 'Bank Name';
  bankHint: string = 'Select Bank';

  bankBranchLabel: string = 'Bank Branch';
  bankBranchHint: string = 'Select Bank Branch';

  accNameLabel: string = 'Account Name';
  accNameHint: string = 'Enter Account Name';

  accLabel: string = 'Account Number';
  accHint: string = 'Enter Account Number';

  instructionsLabel: string = 'Who should give us instructions?';
  instructionsSubtext: string = 'All instructions to ICEA LION Trust Co. regarding this account must be given by'
  selectText: string = 'Select one';

  signingArrangementLabel: string = 'Special signing arrangement (please specify)';
  referreLabel: string = 'Who Introduced you to Milele Trust';
  referreeOtherHint: string = 'Please tell us more';
  referreeOtherLabel: string = 'Other source';
  agentLabel: string = 'Name of Agent';
  agentHint: string = 'Enter Name';

  bensLabel: string = 'Select Beneficiaries';
  bensHint: string = 'Select all beneficiaries applicable';

  removeContactText: string = 'Remove Contact';

  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  loading: boolean = false;
  loadingText: string = '';

  loadingBankBranches: boolean = false;
  bankBranchesEmpty: boolean = true;

  hideReferree: boolean = false;

  formErrors: { [key: string]: string } = {
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',

    BirthCertImage: '',
    DOB: '',
    PercShare: '',

    IDNo: '',
    KraPin: '',
    IDNoImage: '',
    KraPinImage: '',

    Category: '',

    // Trust info
    TrustPurpose: '',
    SourceOfFunds: '',

    OtherPurpose: '',
    OtherSource: '',
    SigningArrangement: '',
    OtherHear: '',

    UpkeepAmount: '',
    UpkeepFrequency: '',
    Bank: '',
    BankBranch: '',
    AccountName: '',
    AccountNo: '',
    AccountMandate: '',
    HowDidYouHearUS: '',
    AgentName: '',

    GuardianBens: '',

    TrustName: '',
    Title: '',
    Gender: '',
    InstitutionName: '',
    BusinessNature: '',
    OrgRegNum: '',
    OrgAddress: '',
    Nationality: '',
    Country: '',
    County: '',
    RegCertImage: '',
    
  };

  validationMessages: { [key: string]: {} } = {
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },

    DOB:  { required: this.requiredFieldString },
    PercShare:  { required: this.requiredFieldString, max: `Shares allocated cannot exceed ${this.tabControlService.finalSharesRemaining}%` },
    BirthCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },

    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN' },
    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },

    Category: { required: this.requiredFieldString },
    // Trust knfo
    TrustPurpose: { required: this.requiredFieldString },
    SourceOfFunds: { required: this.requiredFieldString },

    OtherPurpose: { required: this.requiredFieldString },
    OtherSource: { required: this.requiredFieldString },
    SigningArrangement: { required: this.requiredFieldString },
    OtherHear: { required: this.requiredFieldString },

    UpkeepAmount: { required: this.requiredFieldString },
    UpkeepFrequency: { required: this.requiredFieldString },
    Bank: { required: this.requiredFieldString },
    BankBranch: { required: this.requiredFieldString },
    AccountName: { required: this.requiredFieldString },
    AccountNo: { required: this.requiredFieldString },
    AccountMandate: { required: this.requiredFieldString },
    HowDidYouHearUS: { required: this.requiredFieldString },
    AgentName: { required: this.requiredFieldString },

    GuardianBens: { required: this.requiredFieldString },

    TrustName: { required: this.requiredFieldString },
    Title: { required: this.requiredFieldString },
    Gender: { required: this.requiredFieldString },
    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
    OrgRegNum: { required: this.requiredFieldString },
    OrgAddress: { required: this.requiredFieldString },
    Nationality: { required: this.requiredFieldString },
    Country: { required: this.requiredFieldString },
    County: { required: this.requiredFieldString },
    RegCertImage: { required: this.requiredFieldString },
  };

  editorForm = this._formBuilder.group({
    Title: [''],
    FirstName: [''],
    MiddleName: [''],
    LastName: [''],
    Phone: [''],
    Email: [''],
    Gender: [''],
    Category: [''],

    DOB: [''],
    PercShare: [{value: '', disabled: true}],

    BirthCertImage: [],

    InstitutionName: [''],
    BusinessNature: [''],
    OrgRegNum: [''],
    OrgAddress: [''],

    ContactPersons:  this._formBuilder.array([
      this.createContactsGroup(null, true)
    ]),

    Principal: this._formBuilder.group({
      FirstName: [''],
      MiddleName: [''],
      LastName: [''],
      DeathCertImage: [],
    }),

    Nationality: [''],
    Country: [''],
    County: [''],

    IDNo: [''],
    KraPin: [''],
    Occupation: [''],
    Address: [''],

    IDNoImage: [],
    KraPinImage: [],
    RegCertImage: [],

    GuardianBens: [''],

    // Trust Info
    TrustName: [''],
    TrustPurpose: [''],
    SourceOfFunds: [''],

    OtherPurpose: [''],
    OtherSource: [''],
    SigningArrangement: [''],
    OtherHear: [''],

    UpkeepAmount: [''],
    UpkeepFrequency: [''],
    Bank: [''],
    BankBranch: [''],
    AccountName: [''],
    AccountNo: [''],
    AccountMandate: [''],
    HowDidYouHearUS: [''],
    AgentName: [''],
  });
  

  constructor (
    private _formBuilder: FormBuilder,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private currencyPipe: CurrencyPipe,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // console.log ('Record ! ', this.record);

    this.populateData();

    if (this.recordType === 'Info') this.loadingText = 'Updating Trust Details';
    else if (this.recordType === 'Guardian') this.loadingText = 'Updating Guardian';
    else if (this.recordType === 'Beneficiary') this.loadingText = 'Updating Beneficiary';
    else this.loadingText = 'Updating Settlor';
    
    // update form values before editing
    if (this.editorForm) {
      if (this.recordType === 'Info') { // Trust Details
        this.title = 'Edit Trust Information';

        this.route.queryParams.subscribe((params) => {
          if (params["session"] != undefined) {
            this.session = params["session"];
            this.sessionData = decodeSessionData(this.session);
    
            if (this.sessionData !== 'error' && typeof this.sessionData != 'string') {
              // Agents
              if (this.sessionData.Referree) {
                if (this.sessionData.Referree.Type && this.sessionData.Referree.Code) {
                  this.hideReferree = true;
                  this.tabControlService.viewToast('success', 'Referral details already captured');
                }
              }
            }
          }
        });

        this.editorForm.patchValue({
          TrustName: this.record.TrustName || '',
          SourceOfFunds: this.record.SourceOfFunds || '',
          UpkeepAmount: this.record.UpkeepAmount || '',
          UpkeepFrequency: this.record.UpkeepFrequency || '',
          Bank: this.record.Bank || '',
          BankBranch: this.record.BankBranch || '',
          AccountName: this.record.AccountName || '',
          AccountNo: this.record.AccountNo || '',
          AccountMandate: this.record.AccountMandate || '',
          HowDidYouHearUS: this.record.HowDidYouHearUS || '',
          OtherHear: this.record.OtherHear || '',
          AgentName: this.record.AgentName || '',
          OtherSource: this.record.OtherSource || '',
        });
  
        this.editorForm.get('TrustName')?.addValidators(Validators.required);
        this.editorForm.get('SourceOfFunds')?.addValidators(Validators.required);
        this.editorForm.get('UpkeepFrequency')?.addValidators(Validators.required);
        this.editorForm.get('Bank')?.addValidators(Validators.required);
        this.editorForm.get('BankBranch')?.addValidators(Validators.required);
        this.editorForm.get('AccountName')?.addValidators(Validators.required);
        this.editorForm.get('AccountNo')?.addValidators(Validators.required);
        this.editorForm.get('AccountMandate')?.addValidators(Validators.required);
    
        this.editorForm.get('SourceOfFunds')?.valueChanges.subscribe((data) => {
          if (this.arrayContainsValue(data, 'Other'))
            this.validationService.addOrRemoveValidator(true, this.editorForm.get('OtherSource')!);
          else
            this.validationService.addOrRemoveValidator(false, this.editorForm.get('OtherSource')!);
        });

        this.editorForm.get('HowDidYouHearUS')?.valueChanges.subscribe((data) => {
          if (data) {
            this.checkIntermediary(data);
          }
        });

        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("AccountMandate")!,
          "SigningArrangement",
          this.editorForm,
          "Other"
        );
    
        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("HowDidYouHearUS")!,
          "AgentName",
          this.editorForm,
          "Independent Agent"
        );
        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("HowDidYouHearUS")!,
          "AgentName",
          this.editorForm,
          "Broker"
        );
        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("HowDidYouHearUS")!,
          "AgentName",
          this.editorForm,
          "ICEA LION Financial Advisor"
        );
        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("HowDidYouHearUS")!,
          "AgentName",
          this.editorForm,
          "ICEA LION Trust Consultant"
        );

        this.validationService.addOrRemoveValidationOnValue(
          this.editorForm.get("HowDidYouHearUS")!,
          "OtherHear",
          this.editorForm,
          "Other"
        );
    
        this.editorForm.valueChanges.subscribe((form) => {
          if (form.UpkeepAmount) {
            this.editorForm.patchValue({
              // First replace: remove strings, 2nd replace: leading zeros
              UpkeepAmount: this.currencyPipe.transform(form.UpkeepAmount.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
            }, {emitEvent: false});
          }
        });
  
      } else {
        if (this.settlorType == 1 && this.recordType == 'Settlor' || this.recordType !== 'Settlor' && this.record.Category !== 'Institution') {
          this.editorForm.patchValue({
            Title: this.record.Title || '',
            FirstName: this.record.FirstName,
            MiddleName: this.record.MiddleName || '',
            LastName: this.record.LastName || '',
            Phone: this.record.Phone || '',
            Email: this.record.Email || '',
          });
    
          this.editorForm.get('FirstName')?.addValidators(Validators.required);
          this.editorForm.get('LastName')?.addValidators(Validators.required);
        }
    
        if (this.recordType === 'Settlor') {
          this.title = 'Edit Settlor Information';

          if (this.settlorType == 0) {
            this.editorForm.patchValue({
              InstitutionName: this.record.InstitutionName || '',
              BusinessNature: this.record.BusinessNature,
              Phone: this.record.Phone || '',
              Email: this.record.Email || '',
              Address: this.record.Address || '',
              IDNoImage: this.record.IDNoImage ? this.record.IDNoImage.stringValue : null,
              KraPinImage: this.record.KraPinImage ? this.record.KraPinImage.stringValue : null,
              ContactPersons: this.record.Authorisers || null,
            });

            if (this.record.Authorisers && this.record.Authorisers.length > 0) {
              const authArray = this.getContacts();

              while (authArray.length !== 0) {
                authArray.removeAt(0);
              }

              this.record.Authorisers.forEach((contact: any) => {
                authArray.push(this.createContactsGroup(contact));
              });
            }

            if (this.record.Principal) {
              const newPrincipalValues = {
                FirstName: this.record.Principal.FirstName || '',
                MiddleName: this.record.Principal.MiddleName || '',
                LastName: this.record.Principal.LastName || '',
                DeathCertImage: this.record.Principal.DeathCertImage.stringValue || ''
              };

              this.editorForm.get('Principal')?.patchValue(newPrincipalValues);
            }
      
            this.editorForm.get('Email')?.addValidators([Validators.required, Validators.email]);
            this.editorForm.get('Phone')?.addValidators(Validators.required);
            this.editorForm.get('Address')?.addValidators(Validators.required);
            this.editorForm.get('InstitutionName')?.addValidators(Validators.required);
            this.editorForm.get('BusinessNature')?.addValidators(Validators.required);
            this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
            this.editorForm.get('IDNoImage')?.addValidators(Validators.required);

          } else {
            this.editorForm.patchValue({
              IDNo: this.record.IDNo || '',
              KraPin: this.record.KraPin || '',
              Occupation: this.record.Occupation || '',
              Address: this.record.Address || '',
              Nationality: this.record.Nationality || '',
              Gender: this.record.Gender || '',
              Country: this.record.Country || '',
              County: this.record.County || '',
              KraPinImage: this.record.KraPinImage ? this.record.KraPinImage.stringValue : null,
              IDNoImage: this.record.IDNoImage ? this.record.IDNoImage.stringValue : null,
            });
      
            this.editorForm.get('Email')?.addValidators([Validators.required, Validators.email]);
            this.editorForm.get('Phone')?.addValidators(Validators.required);
            this.editorForm.get('IDNo')?.addValidators([Validators.required, Validators.min(4)]);
            this.editorForm.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
            this.editorForm.get('Address')?.addValidators(Validators.required);
            this.editorForm.get('Nationality')?.addValidators(Validators.required);
            this.editorForm.get('Gender')?.addValidators(Validators.required);
            this.editorForm.get('Country')?.addValidators(Validators.required);
            this.editorForm.get('County')?.addValidators(Validators.required);
            this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
            this.editorForm.get('IDNoImage')?.addValidators(Validators.required);

          }
        }
    
        if (this.recordType === 'Beneficiary') {
          this.title = 'Edit Beneficiary Information';

          this.editorForm.patchValue({
            Category: this.record.Category || this.BeneficiaryOptions[0],
            TrustPurpose: this.record.TrustPurpose || '',
            OtherPurpose: this.record.OtherPurpose || '',
            PercShare: this.record.PercShare || '',
          });

          if (this.record.Category === 'Institution') {
            const contacts = this.record.ContactPersons;
            for (let i=0; i< contacts.length-1; i++) {
              this.addNewContact();
            };
            
            this.editorForm.patchValue({
              InstitutionName: this.record.InstitutionName || '',
              BusinessNature: this.record.BusinessNature,
              OrgRegNum: this.record.OrgRegNum || '',
              KraPin: this.record.KraPin || '',
              OrgAddress: this.record.OrgAddress || '',
              RegCertImage: this.record.RegCertImage ? this.record.RegCertImage.stringValue : null,
              KraPinImage: this.record.KraPinImage ? this.record.KraPinImage.stringValue : null,
            });

            if (this.record.ContactPersons && this.record.ContactPersons.length > 0) {
              const contactsArray = this.getContacts();

              while (contactsArray.length !== 0) {
                contactsArray.removeAt(0);
              }

              this.record.ContactPersons.forEach((contact: any) => {
                contactsArray.push(this.createContactsGroup(contact));
              });
            }

            this.editorForm.get('InstitutionName')?.addValidators(Validators.required);
            this.editorForm.get('BusinessNature')?.addValidators(Validators.required);
            this.editorForm.get('OrgRegNum')?.addValidators(Validators.required);
            this.editorForm.get('OrgAddress')?.addValidators(Validators.required);
            this.editorForm.get('RegCertImage')?.addValidators(Validators.required);
            this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
            this.editorForm.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);

          } else {
            this.editorForm.patchValue({
              Gender: this.record.Gender || '',
              DOB: this.record.DOB || '',
            });

            if (this.getDateDifference(this.record.DOB)) {
              this.editorForm.patchValue({
                IDNo: this.record.IDNo || '',
                KraPin: this.record.KraPin || '',
                KraPinImage: this.record.KraPinImage ? this.record.KraPinImage.stringValue : null,
                IDNoImage: this.record.IDNoImage ? this.record.IDNoImage.stringValue : null,
              });
      
              this.editorForm.get('IDNo')?.addValidators([Validators.required, Validators.min(4)]);
              this.editorForm.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
              this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
              this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
            } else {
              this.editorForm.patchValue({
                BirthCertImage: this.record.BirthCertImage ? this.record.BirthCertImage.stringValue : null,
              });

              this.editorForm.get('BirthCertImage')?.addValidators(Validators.required);
            }
      
            this.editorForm.get('DOB')?.addValidators(Validators.required);
            this.editorForm.get('Gender')?.addValidators(Validators.required);
      
          }
          
          this.editorForm.get('Category')?.addValidators(Validators.required);
          this.editorForm.get('PercShare')?.addValidators([Validators.required, Validators.max(this.finalRemainingShares)]);
          this.editorForm.get('TrustPurpose')?.addValidators(Validators.required);
          
          this.editorForm.get('TrustPurpose')?.valueChanges.subscribe((data) => {
            if (this.arrayContainsValue(data, 'Other'))
              this.validationService.addOrRemoveValidator(true, this.editorForm.get('OtherPurpose')!);
            else
              this.validationService.addOrRemoveValidator(false, this.editorForm.get('OtherPurpose')!);
          });
        }
    
        if (this.recordType === 'Guardian') {
          this.organizeBeneficiaries();
          this.title = 'Edit Guardian Information';
          
          if (this.record.GuardianBens && this.record.GuardianBens.length > 0) {
            
            let items = this.record.GuardianBens
            .filter((ben: any) => ben.BeneficiaryID && ben.BeneficiaryID !== '')
            .map((ben: any) => Number(ben.BeneficiaryID));
  
            this.editorForm.get('GuardianBens')?.setValue(items);
          }
          
          this.editorForm.patchValue({
            IDNo: this.record.IDNo || '',
            KraPin: this.record.KraPin || '',
            Category: this.record.Category || '',
            KraPinImage: this.record.KraPinImage ? this.record.KraPinImage.stringValue : null,
            IDNoImage: this.record.IDNoImage ? this.record.IDNoImage.stringValue : null,
          });
    
          this.editorForm.get('Email')?.addValidators([Validators.required, Validators.email]);
          this.editorForm.get('Phone')?.addValidators(Validators.required);
          this.editorForm.get('GuardianBens')?.addValidators(Validators.required);
          this.editorForm.get('IDNo')?.addValidators([Validators.required, Validators.min(4)]);
          this.editorForm.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
          this.editorForm.get('KraPinImage')?.addValidators(Validators.required);
          this.editorForm.get('IDNoImage')?.addValidators(Validators.required);
          if (this.recordIndex !== 0) {
            this.editorForm.get('Category')?.addValidators(Validators.required);
          }
        }
      }
    }

  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.editorForm,
      this.formErrors,
      this.validationMessages
    );
  }

  BeneficiaryList: any[] = [];

  organizeBeneficiaries(): void {
    try {
      const items = this.tabControlService.beneficiaries;
      let output: any[] = [];

      for (let i=0; i<items.length; i++) {
        if (items[i].BeneficiaryID && items[i].Category && items[i].FirstName || items[i].InstitutionName) {
          let name = items[i].FirstName;

          if (items[i].Category.toLowerCase() === 'institution') name = items[i].InstitutionName;

          const item = {
            Description: name,
            ID: items[i].BeneficiaryID,
          };

          output.push(item);
          
        }
      }
      // console.log('Final output : ', output);
      this.BeneficiaryList = output;
    } catch (error) {
      console.log('Error : ', error);
    }
  }

  createContactsGroup(contact: any = null, def: boolean = false): FormGroup {
    if (contact) {
      return this._formBuilder.group({
        Title: [contact.Title || ''],
        FirstName: [contact.FirstName || '', Validators.required],
        MiddleName: [contact.MiddleName || ''],
        LastName: [contact.LastName || '', Validators.required],
        Phone: [contact.Phone || '', Validators.required],
        Email: [contact.Email || '', [Validators.email, Validators.required]]
      });

    } else {
      if (def) {
        return this._formBuilder.group({
          Title: [''],
          FirstName: [''],
          MiddleName: [''],
          LastName: [''],
          Phone: [''],
          Email: ['', Validators.email]
        });
      } else {
        return this._formBuilder.group({
          Title: [''],
          FirstName: ['', Validators.required],
          MiddleName: [''],
          LastName: ['', Validators.required],
          Phone: ['', Validators.required],
          Email: ['', [Validators.email, Validators.required]]
        });
      }
    }
    
  };

  getContacts() : FormArray {
    return this.editorForm.get('ContactPersons') as FormArray;  
  }

  getContactsFormGroup(index: number): FormGroup {
    return this.getContacts().at(index) as FormGroup;
  }

  async addNewContact() {
    const fields = this.getContacts();
    fields.push(this.createContactsGroup());
    this.editorForm.setControl('ContactPersons', fields);
  }

  async removeContact(j: number) {
    const fields = this.getContacts();
    fields.removeAt(j);
  }

  idObject: any = null;
  kraObject: any = null;

  birthCertObject: any = null;
  regCertObject: any = null;

  deathObject: any = null;

  get settlorType(): 0 | 1 {
    return this.tabControlService.settlorType;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  get minDate() {
    return this.tabControlService.minDOBDate;
  }
  get maxDate() {
    return this.tabControlService.maxDOBDate;
  }

  get BanksOptions(): any {
    return this.tabControlService.BanksOptions;
  }
  get TrustPurposeOptions(): any {
    return this.tabControlService.TrustPurposeOptions;
  }
  get FundSourceOptions(): any {
    return this.tabControlService.FundSourceOptions;
  }
  get FrequencyOptions(): any {
    return this.tabControlService.UpkeepFrequencyOptions;
  }

  get InstructorOptions(): any {
    return this.tabControlService.InstructorOptions;
  }
  get HearAboutusData(): any {
    return this.tabControlService.HearAboutusData;
  }
  get GuardianOptions() {
    return this.tabControlService.GuardianOptions;
  };
  get BeneficiaryOptions():any {
    return this.tabControlService.BeneficiaryOptions;
  }

  get finalRemainingShares() {
    return this.tabControlService.finalSharesRemaining;
  }
  get GenderOptions():any {
    return this.tabControlService.GenderOptions;
  }


  get loadingCountries(): boolean {
    return this.tabControlService.loadingCountries;
  }
  get CountryOptions():any {
    return this.tabControlService.CountryOptions;
  }
  get loadingCounties(): boolean {
    return this.tabControlService.loadingCounties;
  }
  get CountyOptions():any {
    return this.tabControlService.CountyOptions;
  }
  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }

  get loadingIntermediaries(): boolean {
    return this.tabControlService.loadingIntermediaries;
  }
  get IntermediaryOptions(): any {
    return this.tabControlService.IntermediaryOptions;
  }

  get beneficiaries(): any {
    return this.tabControlService.beneficiaries;
  }

  async populateData(): Promise<void> {
    if (this.recordType === 'Settlor') {
      if (this.tabControlService.settlorType == 0) {
        if (this.BusinessNatureOptions.length === 0) {
          this.tabControlService.loadingBizNature = true;
          await this.tabControlService.getBusinessNatOptionsList();
          this.tabControlService.loadingBizNature = false;
        }
        
      } else {
        if (this.CountryOptions.length === 0) {
          this.tabControlService.loadingCountries = true;
          await this.tabControlService.getCountryList();
          this.tabControlService.loadingCountries = false;
        }
    
        if (this.CountyOptions.length === 0) {
          this.tabControlService.loadingCounties = true;
          await this.tabControlService.getCountyList();
          this.tabControlService.loadingCounties = false;
        }
      }
    }

    if (this.recordType === 'Beneficiary') {
      if (this.TrustPurposeOptions.length === 0) {
        await this.getTrustPurposeOptionsList().then(()=> {
          if (this.TrustPurposeOptions.length === 0) this.tabControlService.viewToast('error', 'Failed to load some data');
        });
      }
      
      if (this.record.Category == 'Institution') {
        if (this.BusinessNatureOptions.length === 0) {
          this.tabControlService.loadingBizNature = true;
          await this.tabControlService.getBusinessNatOptionsList();
          this.tabControlService.loadingBizNature = false;
        }
        
      }
    }

    if ( (this.recordType === 'Settlor' && this.tabControlService.settlorType == 1)
      || this.recordType === 'Beneficiary'
      || this.recordType === 'Guardian' ) {

        if (this.TitleOptions.length === 0) {
          this.tabControlService.loadingTitles = true;
          await this.tabControlService.getTitlesList();
          this.tabControlService.loadingTitles = false;
        }
    }
    
    if (this.recordType === 'Info') {

    }
  }

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updateBirthCertObject(object: any): void {
    this.birthCertObject = object;
  }
  updateRegCertObject(object: any): void {
    this.regCertObject = object;
  }
  updateDeathObject(object: any): void {
    this.deathObject = object;
  }

  async checkIntermediary(data: string): Promise<void> {
    if (data.toLowerCase() == 'icea lion trust consultant') {
      this.tabControlService.getConsultants();

    } else {
      if (data.toLowerCase() == 'independent agent') {
        this.tabControlService.getIntermediaries('agent');

      } else if (data.toLowerCase() == 'broker') {
        this.tabControlService.getIntermediaries('Fubro');
        
      } else if (data.toLowerCase() == 'icea lion financial advisor') {
        this.tabControlService.getIntermediaries('tla');
      }
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  arraysContainValue(arr: any[] | null | undefined, value: string) {
    let exists = false

    if (arr)
      arr.forEach(element => {
        if (element.TrustPurpose && element.TrustPurpose.length > 0) {
          if (this.arrayContainsValue(element.TrustPurpose, value)) exists = true;
        }
      });

      return exists;
  }

  getDateDifference(date: string | undefined | null) :boolean {
    if (!(date === null || date === undefined)) {
      const currentDate = new Date();
      const dobDate = new Date(date);
      const ageDifferenceInMilliseconds = currentDate.getTime() - dobDate.getTime();
      const ageDate = new Date(ageDifferenceInMilliseconds);

      // Calculate age based on the year.
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 18) {
        return false;
      } else {
        return true;
      } 
    } else {
      return false;
    }
  }

  closePopup(): void {
    this.close.emit();
  }

  async populateBankBranchesList() {
    this.editorForm.get('BankBranch')?.disable();
    this.loadingBankBranches = true;
    this.BranchPlaceHolders = [];
    
    try {
      const bankValue = this.editorForm.get('Bank')?.value;
  
      if (!(bankValue === null || bankValue ==='' || bankValue === undefined)) {
        
        const branchRequest = {
          BankID: `${bankValue}`,
        };
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bankBranchesURL,
          branchRequest
        );

        if (response.Status === 1) {
          this.BranchPlaceHolders = response.Data;
          this.editorForm.get('BankBranch')?.enable();
          this.bankBranchesEmpty = false;
        } else {
          console.log('>>> Error !! ', response.Message);
          this.bankBranchesEmpty = true;
        }
        this.loadingBankBranches = false;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingBankBranches = false;
  }

  loadingTrustPurposeOptions: boolean = false;
  async getTrustPurposeOptionsList() {
    this.loadingTrustPurposeOptions = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.purposeURL
      );

      if (response.Status === 1) {
        this.tabControlService.TrustPurposeOptions = response.Data;
      } else {
        console.log('>>> Error : ', response.Message);
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }

    
    this.loadingTrustPurposeOptions = false;
  }

  async onConfirmClick(): Promise<void> {
    if (this.recordType === 'Info') {
      await this.updateTrustDetails();
    } else {
      await this.updateRecordDetails();
    }
  }

  async updateRecordDetails(): Promise<void> {
    this.loading = true;

    let url = '';
    let originalPurpose;
    let data: RecordData | any;
    data = {
      Title: this.editorForm.value.Title,
      FirstName: this.editorForm.value.FirstName,
      MiddleName: this.editorForm.value.MiddleName,
      LastName: this.editorForm.value.LastName,
      Phone: this.editorForm.value.Phone,
      Email: this.editorForm.value.Email,
      TrustID: this.tabControlService.TrustID?.toString()
    };

    if (this.recordType === 'Settlor' && this.record) {
      url = environment.baseUrl + Constants.saveSettlorURL;
      
      if (this.settlorType == 0) {
        data = {
          InstitutionName: this.editorForm.value.InstitutionName,
          BusinessNature: this.editorForm.value.BusinessNature,
          Phone: this.editorForm.value.Phone,
          Email: this.editorForm.value.Email,
          Address: this.editorForm.value.Address,
          IDNoImage: this.idObject,
          KraPinImage: this.kraObject,
          TrustID: this.tabControlService.TrustID?.toString()
        };

      } else {
        let subData = {
          IDNo: this.editorForm.value.IDNo,
          KraPin: this.editorForm.value.KraPin,
          Occupation: this.editorForm.value.Occupation,
          Address: this.editorForm.value.Address,
          Nationality: this.editorForm.value.Nationality,
          Country: this.editorForm.value.Country,
          County: this.editorForm.value.County,
          Gender: this.editorForm.value.Gender,
          IDNoImage: this.idObject,
          KraPinImage: this.kraObject,
          Category: this.record.Category,
          SettlorID: this.record.SettlorID.toString(),
        }
        data = {...data, ...subData};
      }

    } else if (this.recordType === 'Guardian' && this.record) {
      url = environment.baseUrl + Constants.saveGuardianURL;

      let guardsBens = [];
      const GuardianBens = this.editorForm.value.GuardianBens;

      if (GuardianBens && GuardianBens.length > 0) {
        for (let i=0; i<GuardianBens.length; i++) {
          let id = '';

          if (this.record.GuardianBens
              && this.record.GuardianBens[i]
              && this.record.GuardianBens[i].ID
              && this.record.GuardianBens[i].ID !== ''
              && this.record.GuardianBens[i].BeneficiaryID
              && this.record.GuardianBens[i].BeneficiaryID === GuardianBens[i].toString()
            ) {
              id = this.record.GuardianBens[i].ID;
          }

          const item = {
            ID: id.toString(),
            BeneficiaryID: GuardianBens[i].toString()
          };

          guardsBens.push(item);
        }
      }
      

      let subData = {
        IDNo: this.editorForm.value.IDNo,
        KraPin: this.editorForm.value.KraPin,
        IDNoImage: this.idObject,
        KraPinImage: this.kraObject,
        GuardianBens: guardsBens,
        Category: this.editorForm.value.Category,
        GuardianID: this.record.GuardianID.toString()
      };
      data = {...data, ...subData};

    } else if (this.recordType === 'Beneficiary' && this.record) {
      url = environment.baseUrl + Constants.saveBenURL;

      originalPurpose = this.editorForm.value.TrustPurpose;
      let purpose = this.organizeValues(originalPurpose);
      let otherPurp = '-';

      if (this.editorForm.value.OtherPurpose &&
        this.editorForm.value.OtherPurpose.length > 0) {
        purpose = purpose + ': ' +this.editorForm.value.OtherPurpose;
        otherPurp = this.editorForm.value.OtherPurpose;
      }

      let subData = {
        Category: this.editorForm.value.Category,
        PercShare: this.editorForm.value.PercShare,
        TrustPurpose: purpose
      };

      if (this.editorForm.value.Category?.toLowerCase() === 'institution') {
        subData = {...subData, ...{
          InstitutionName: this.editorForm.value.InstitutionName,
          BusinessNature: this.editorForm.value.BusinessNature,
          OrgRegNum: this.editorForm.value.OrgRegNum,
          KraPin: this.editorForm.value.KraPin,
          OrgAddress: this.editorForm.value.OrgAddress
        }}

        data = {...subData, ...{BeneficiaryID: this.record.BeneficiaryID.toString()}};
        
      } else {
        subData = {...subData, ...{
          Gender: this.editorForm.value.Gender,
          DOB: formatDate(this.editorForm.value.DOB),
        }}

        if (this.getDateDifference(this.record.DOB)) {
          subData = {...subData, ...{
            IDNo: this.editorForm.value.IDNo,
            KraPin: this.editorForm.value.KraPin,
            IDNoImage: this.idObject
          }}
        } else {
          subData = {...subData, ...{BirthCertImage: this.editorForm.value.BirthCertImage}}
        }
        
        data = {...data, ...subData, ...{BeneficiaryID: this.record.BeneficiaryID.toString()}};
      }
      
    }

    try {
      const response = await this.apiService.postRequest(url, data);
      // Update local objects
      if (response.Status === 1) {
        let currentRecords;

        if (this.recordType === 'Settlor') {
          currentRecords = this.tabControlService.settlors;

          data.IDNoImage = this.idObject;
          data.KraPinImage = this.kraObject;
          Object.assign(currentRecords[this.recordIndex], data);
          this.tabControlService.updateRecord(2, currentRecords);

        } else if (this.recordType === 'Guardian') {
          currentRecords = this.tabControlService.guardians;

          data.IDNoImage = this.idObject;
          data.KraPinImage = this.kraObject;
          Object.assign(currentRecords[this.recordIndex], data);
          this.tabControlService.updateRecord(4, currentRecords);

        } else if (this.recordType === 'Beneficiary') {
          currentRecords = this.tabControlService.beneficiaries;

          if (this.editorForm.value.Category?.toLowerCase() === 'institution') {
            data.KraPinImage = this.kraObject;
            data.RegCertImage = this.regCertObject;
          } else {
            if (this.getDateDifference(data.DOB)) {
              data.IDNoImage = this.idObject;
              data.KraPinImage = this.kraObject;
            } else {
              data.BirthCertImage = this.birthCertObject;
            }
          }

          data.TrustPurpose = originalPurpose;

          Object.assign(currentRecords[this.recordIndex], data);
          this.tabControlService.updateRecord(3, currentRecords);
        }

        this.closePopup();
      }
      this.tabControlService.viewToast('success', 'Record updated');
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    this.loading = false;
  }

  async updateTrustDetails(): Promise<void> {
    this.loading = true;

    let temp = this.editorForm.value;
    let data = {...this.editorForm.value, ...{TrustID: this.tabControlService.TrustID}};

    if (data.AgentName && data.AgentName !== '') {
      data = {...data, ...{
        AgentCode: getValueByIdOrDescription(data.AgentName, this.IntermediaryOptions, 2),
        AgentPhoneNo: getValueByIdOrDescription(data.AgentName, this.IntermediaryOptions, 1)
      }};
    }

    const originalFunds = this.editorForm.value.SourceOfFunds;
    let funds = this.organizeValues(originalFunds);

    if (this.editorForm.value.OtherSource &&
      this.editorForm.value.OtherSource.length > 0) {
      funds = funds + ': ' +this.editorForm.value.OtherSource;
    }

    if (data.AccountMandate === 'Special signing arrangement') {
      data.AccountMandate = data.SigningArrangement;
    }

    if (data.HowDidYouHearUS === 'Other') {
      data.HowDidYouHearUS = data.OtherHear;
    }

    data.UpkeepAmount = extractNumericValue(this.editorForm.value.UpkeepAmount);
    data.SourceOfFunds = funds;

    temp.UpkeepAmount = data.UpkeepAmount;

    if (data.hasOwnProperty('OtherSource')) {
      delete data['OtherSource'];
    }
    if (data.hasOwnProperty('SigningArrangement')) {
      delete data['SigningArrangement'];
    }
    if (data.hasOwnProperty('OtherHear')) {
      delete data['OtherHear'];
    }

    let url = environment.baseUrl + Constants.saveTrustURL;

    try {
      const response = await this.apiService.postRequest(url, data);
      // Update local objects
      if (response.Status === 1) {
        this.tabControlService.updateRecord(5, temp);
        this.closePopup();
      }
      this.tabControlService.viewToast('success', response.Message);

    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    this.loading = false;
  }

  organizeValues(items: any): string {
    try {
      let output: string = items[0];
      for (let i=1; i<items.length; i++) {
        output = output + ', ' +items[i];
      }
      // console.log('Final output : ', output);
      return output;
    } catch (error) {
      console.log('Error', error);
      return '';
    }
  }

}
