<div class="flex container">

    <div class="content-loading" *ngIf="showResumePopup">
      <div class="flex column resume-content">
        <div class="flex resume-row bot">
          <div class="flex warn">
            <img src={{warnPath}} class="warn-img" alt="warning" />
          </div>
          <span class="resume-title">Resume Previous Session</span>
        </div>

        <div class="flex column width-100 center">
          <div class="flex resume-row" style="padding: 5%; border: 0.1px solid lightgray; box-sizing: border-box; border-radius: 5px; width: 90%;">
            <!-- <img src={{arrowPath}} class="arrow-img" alt="arrow-right" /> -->
            
            <span style="font-weight: bolder; color: #B68400;">{{'Step '}}</span>
            <div class="resume-active">
              {{trustDataHolder.activeTab }}
            </div>
  
            <div style="width: 1px; height: 40px; background-color: #B68400; margin-left: 15px; margin-right: 15px;"></div>
            <span class="resume-text">{{ resumeActiveText }}</span>
          </div>
    
          <div class="flex column stk-div">
            <div class="flex putton green" (click)="navTrustFund(true)">
              {{ 'Continue' }}
              
            </div>
    
            <div class="flex putton red" (click)="navTrustFund(false)">
              {{ 'New Session' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex one">
        <div class="flex half-1">
            <img src={{MileleLogoPath}} class="mirere-logo" alt="Milele trust" aria-label="milele trust" />
        
            <div class="heading"> {{ mileleTrustFund }} </div>

            <div class="sub-heading"> {{ secureFutureText }} </div>        
            <div class="paragraph"> {{ legacySubtext }} </div>

            <div class="flex navy-background blue button" (click)="navTrustFundInfo()"> {{ learnMoreText }} </div>
            <div class="flex white-background white button" (click)="checkMileleTrustResume()"> {{ startHereText }} </div>
        </div>

        <div class="flex half-2">
            <div class="milele-logolder" style="cursor: default;"></div>

            <div class="heading"> {{ milelePrivateTrust }} </div>
    
            <div class="sub-heading text-right"> {{ legacyProtectText }} </div>
            <div class="paragraph"> {{ secureSubtext }} </div>
    
            <div class="flex navy-background blue button" (click)="navPrivateTrustInfo()"> {{ learnMoreText }} </div>
            <div class="flex white-background white button" (click)="toggleEstatePopupClose(true)"> {{ startHereText }} </div>
        </div>

        <div class="flex half-3">
            <img
              [@fade]="toggle"
              (@fade.done)="onFade($event)"
              src={{slideshowImages[count%slideshowImages.length]}}
              class="image-random"
              alt="images" />
        </div>

        <div class="flex broch-overlay" *ngIf="showBrochures" (click)="showBrochures = false">
            <div class="flex broch-holder">
                <div class="flex column broch-r" (click)="openTrustBrochure()">
                    <mat-icon class="broch-icon broch-blue"  fontIcon="picture_as_pdf"></mat-icon>
                    <span style="text-align: center;">Trust Fund<br>brochure</span>
                </div>
                <div class="flex column broch-r" (click)="openFamilyBrochure()">
                    <mat-icon class="broch-icon broch-grey"  fontIcon="picture_as_pdf"></mat-icon>
                    <span style="text-align: center;">Estate Planning<br>brochure</span>
                </div>
            </div>
        </div>
        
    </div>

    <div class="flex two">
        <img src={{logoPath}} class="logo" alt="logo" />

        <div class="flex column" style="width: 100%; align-items: center; justify-content: center;">
            <div class="help"> {{ needHelpText }} </div>

            <div
                (click)="toggleBookPopupClose(true)"
                class="flex white-background white button width-wider"
                style="font-weight: 750; color: #003764;">
                {{ bookCallText }}
            </div>
            <div class="flex white-outline white button width-wider" (click)="viewBrochures()"> {{ brochureText }} </div>
            
            <a target="_blank" href="https://selfservice.icealion.com/login?returnUrl=%2F" class="exist-login" rel="noopener"> {{ existingLoginText }} </a>
        </div>
        
        <!-- <div class="flex white-background white button width-wider chat-us">
            <img src={{forumPath}} style="width: 24px; height: 24px; margin-right: 10px;" alt="logo" />
            {{ chatUsText }}
        </div> -->
        <div class="flex power-small"> {{ powerdByText }} </div>
    </div>
</div>

<app-book-call *ngIf="showBookCall" (close)="toggleBookPopupClose(false)" ></app-book-call>
<app-option-popup *ngIf="showEstateOptions" (close)="toggleEstatePopupClose(false)"></app-option-popup>
