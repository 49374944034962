import { Directive, HostListener, Injectable, LOCALE_ID } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatDateFormats, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const FULL_FORMAT : MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  }
};

// export const FULL_FORMAT = {
//   parse: {
//     dateInputFull: 'DD/MM/YYYY'
//   },
//   display: {
//     dateInput: 'DD/MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@Directive({
  selector: '[appDateFormat]',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_DATE_FORMATS, useValue: FULL_FORMAT },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
})
export class DateFormatDirective {

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  onInput(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const formattedValue = this.formatDate(event.value);
      this.ngControl.control?.setValue(formattedValue);
    }
  }

  private formatDate(date: Date): string {
    const day = this.addLeadingZero(date.getDate());
    const month = this.addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  private addLeadingZero(number: number): string {
    return number < 10 ? `0${number}` : `${number}`;
  }

}
