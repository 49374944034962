import { Component } from '@angular/core';
import { TabControlService } from './services/tab-control.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'milele-trust-frontend';

  constructor(private tabControlService: TabControlService) {}

  ngOnInit() {
    this.tabControlService.initializeSalesIQ();
  }
}
