import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {

  formSubtitle: string = 'Please enter the details below so we can contact you';
  contactInfoTitle: string = 'Contact Information';

  relationLabel: string = 'Specify Relationship';
  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Enter Email address';

  requiredFieldString: string = 'Required field';

  formErrors: { [key: string]: string } = {
    Phone: '',
    Email: '',
    Relationship: '',
  };

  validationMessages: { [key: string]: {} } = {
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },
    Relationship: { required: this.requiredFieldString },
  };

  contactForm = this._formBuilder.group({
    Relationship: ['', Validators.required],
    Phone: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private tabControlService: TabControlService,
    private apiService: ApiService
  ) {
  }

  loading: boolean = false;

  get RelationOptions() {
    return this.tabControlService.RelationOptions;
  }

  async submitForm(): Promise<void> {
    this.loading = true;
    this.tabControlService.isLoading(true);

    try {
      if (this.tabControlService.appointmentID === null) {
        const _data = {
          AppointmentType: '',
          Email: '',
          Date: '',
          Time: '',
          NumberOfPersons: '',
          Location: '',
          BookingID: '',
        };

        const _response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bookAppointmentEstateURL, _data, false);

        if (_response.Status === 1 && _response.BookingID) {
          this.tabControlService.appointmentID = _response.BookingID;
          
        } else {
          console.error(_response);
          this.tabControlService.viewToast('error', 'Could not save your details', _response.Message);
          return;
        }
      }
      
      let data = {
        ...this.contactForm.value,
        ...{
          BookAppoitmentID: this.tabControlService.appointmentID,
          TrustType: 'Settler'
        }
      };
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const contactInf = {
          ...this.contactForm.value,
          ...{
            SettlorID: response.SettlorID
          }
        };

        await this.tabControlService.updateRecordEstate(1, contactInf);
        console.log('>> New Record !! \n', this.tabControlService.contactInfoEstate);

        this.tabControlService.viewToast('success', 'Your details have been saved');
        this.navigateFunction();

      } else {
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    this.tabControlService.isLoading(false);
    this.loading = false;
  }

  async navigateFunction(): Promise<void> {
    this.tabControlService.page1DoneEstate = true;
    await this.tabControlService.changeTabEstate(2);
  }
}
