<div class = "flex editor-container">
    <div class="flex editor-overlay">
        <form [formGroup]="linkForm" (ngSubmit)="submitRequest()" class="flex editor-content" autocomplete="off" novalidate>
            <div class="flex x-div">
                <div (click)="closePopup()" class="flex x-button" *ngIf="!loading">
                    Go Back
                    <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
                </div>
            </div>

            <div class="flex column mid-div">
                <div>
                    <h1 class="title">
                        Assign Remaining Beneficiaries To A Guardian
                    </h1>
                </div>
                <div class="flex blue-box" style="position: relative;">
                    <p style="margin-top: 0px; font-size: 0.9rem; line-height: 1.5;">
                        You have one or more <b>beneneficiaries</b> that do not have assigned guardians.<br>
                        Please select to assign them against one or more guardians:
                    </p>
                    <div class="line-2"></div>

                    <div formArrayName="Guardians">
                        <div *ngFor="let ben of getGuardians().controls; let i=index" [formGroupName]="i">
                            <div class="flex align g-name">
                                <div class="flex center" style="width: 35px; height: 35px; border-radius: 35px; color: white; background-color: #003764; font-size: 0.8rem;">
                                    {{ (i+1) }}
                                </div>
                                <b>{{ guardians[i].Description }}</b>
                            </div>
                            <div class="line-2"></div>
                            <p style="margin-top: 0px; font-size: 0.9rem; line-height: 1.5;">
                                Select all beneficiaries to assign under this guardian
                            </p>
                            <mat-chip-listbox formControlName="Beneficiaries" multiple aria-label="Beneficiary selection">
                                <mat-chip-option *ngFor="let beneficiary of beneficiaries" [value]="beneficiary.ID">
                                    {{ beneficiary.Description }}
                                </mat-chip-option>
                            </mat-chip-listbox>
                            <div class="line-2" style="background-color: #00B6ED; margin-top: 25px;" *ngIf="i !== getGuardians().length-1"></div>
                        </div>
                    </div>

                    <div class="loading" *ngIf="loading">
                        <div class="flex center loading-spinner" style="width: 100%; height: 100%;">
                            <mat-progress-spinner
                              mode="indeterminate"
                              color="warn"
                              diameter="100"
                              strokeWidth="12">
                           </mat-progress-spinner>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex x-div" style="margin-top: 20px; margin-bottom: 20px;">
                <button [disabled]="!linkForm.valid || loading" mat-button type="submit" class="sub-button">
                    <div style="display: flex; flex: 1; align-items: center; color: white !important;">
                        {{buttonText}}

                        <span class="spinning" *ngIf="loading" style="margin-left: 15px;">
                            <mat-progress-spinner
                                mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
                        </span>
                    </div>
                </button>
            </div>

        </form>
    </div>
</div>
