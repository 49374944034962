<div class="tab-form" >
    <div class="form-80" style="align-items: center; padding: 5%;">
        <div class="flex g-round">
            <img src={{checkerPath}} class="check-mate" alt="logo" />
        </div>
    </div>
    <div class="title">
        {{title}}
    </div>

    <div class="p">
        {{ text1 }}<br>
    </div>
    <div class="p">
        {{ text2 }}
    </div>

    <div class="done"  (click)="goHome()">
        {{ buttonText }}
    </div>
</div>
