import { trigger, transition, style, animate } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';
import { SessionData, decodeSessionData, hideEmailAddress } from 'src/app/util/Helper';

@Component({
  selector: 'app-main',
  templateUrl: './trust.component.html',
  styleUrls: ['./trust.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class TrustComponent implements AfterViewInit, OnInit {
  // Url Params
  session: string = ""; // used to resume session

  @ViewChild('scroller', { static: false }) scroller?: ElementRef;
  tabsAnimationState: boolean[] = [false, false, false, false, false, false, false];

  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/logo_w.png';
  forumPath: string  = '../../../assets/forum-g.png';
  mPesaPath: string = '../../../assets/m_pesa.png';

  loginText: string = 'Login';
  existingCustomerText: string = 'Already an existing Milele Customer?';

  chatUsText: string = 'Chat with us. How may we help?';

  personalData: string = 'Personal Data';
  addSettlor: string = 'Settlor';
  addBeneficiary: string = 'Beneficiary';
  addGuardian: string = 'Guardian';
  trustInfo: string = 'Trust Info';
  preview: string = 'Preview';
  payment: string = 'Payment';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  retryPaymentText: string = 'Sorry, we could not verify your M-Pesa payment.';
  retryQuestionText: string = 'Did you complete it successfully?';

  otpForm = this.formBuilder.group({
    otp: ['']
  });

  ngAfterViewInit() {
    // Trigger the animation for each tab after a slight delay
    this.animateTabs();
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private tabControlService: TabControlService
  ) {
    tabControlService.mainJourney = 0;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params

      if (params["session"] == undefined) {
        const data = await this.tabControlService.getData(this.tabControlService.storageKey);

        if (data) {
          this.tabControlService.updateDataFromStorage();
        }
      } else {
        this.session = params["session"];
        this.tabControlService.session = this.session;
        this.initResume(this.session);
      }
    });
    
  }

  // Type guard function to check if an object matches the SessionData type
  isSessionData(obj: any): obj is SessionData {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      typeof obj.SessionId === 'string'
    );
  }

  async initResume(session: string): Promise<void> {
    const sessionData = decodeSessionData(session);
    if (this.isSessionData(sessionData) && sessionData.SessionId && sessionData.SessionId !== '') {
      await this.tabControlService.checkProgress(session);
      if (this.onlineStorageObject) {
        this.otpEmail = hideEmailAddress(this.onlineStorageObject.basicData.Email);
      }

    } else {
      const data = await this.tabControlService.getData(this.tabControlService.storageKey);
      if (data) {
        this.tabControlService.updateDataFromStorage();
      }
    }
  }

  animateTabs() {
    for (let i = 0; i < this.tabsAnimationState.length; i++) {
      setTimeout(() => {
        this.tabsAnimationState[i] = true;
      }, 400 * i); // Adjust the delay as needed
    }
  }

  otpReady: boolean = false;
  otpValue: number = 0;
  otpLoading: boolean = false;
  otpEmail:string = 'that was used to set up the trust';

  get activeTab(): number {
    return this.tabControlService.activeTab;
  }
  get page1done(): boolean {
    return this.tabControlService.page1Done;
  };
  get page2done(): boolean {
    return this.tabControlService.page2Done;
  };
  get page3done(): boolean {
    return this.tabControlService.page3Done;
  };
  get page4done(): boolean {
    return this.tabControlService.page4Done;
  };
  get page5done(): boolean {
    return this.tabControlService.page5Done;
  };
  get page6done(): boolean {
    return this.tabControlService.page6Done;
  };

  get loading(): boolean {
    return this.tabControlService.loading;
  };

  get stkLoading(): boolean {
    return this.tabControlService.stkLoading;
  };

  get stkTimeout(): boolean {
    return this.tabControlService.stkTimeout;
  };

  get requestingSTK(): boolean {
    return this.tabControlService.requestingSTK;
  }
  set requestingSTK(value: boolean) {
    this.tabControlService.requestingSTK = value;
  }

  get enterPinText(): string {
    return this.tabControlService.stkPinText;
  };

  get stkMessage(): string {
    return this.tabControlService.stkMessage;
  }

  get onlineStorageObject(): any {
    return this.tabControlService.onlineStorageObject;
  }

  get showResumePopup(): boolean {
    return this.tabControlService.showResumePopup;
  };

  get resetSTK(): ()=> void {
    return this.tabControlService.resetSTK;
  };

  mpesaPayment(mode: 0 | 1): void{
    this.tabControlService.stkMode = mode;
    this.tabControlService.mpesaPayment();
  };

  closeSTKPopup(): void {
    this.tabControlService.stkLoading = false;
    this.resetSTK();
  }
  

  changeTab(tabNumber: number) {
    this.tabControlService.changeTab(tabNumber);
  }

  navHome(): void {
    const nextPage = ``;
    this.router.navigate([nextPage]);
  }

  scroll(childDivHeight: number) {
    if (this.scroller) this.scroller.nativeElement.scrollTop = childDivHeight;
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otpReady = true;
      this.otpValue = parseInt(otp);
    } else {
      this.otpReady = false;
    }
  }

  async verifyOTP(): Promise<void> {
    if (!this.otpLoading) {
      try {
        this.otpForm.disable();
        if (this.otpValue === this.onlineStorageObject.OTP) {
          // update data
          await this.tabControlService.updateDataFromStorage(this.onlineStorageObject);
          this.tabControlService.viewToast('success', 'Session resumed');
        } else {
          this.tabControlService.viewToast('error', 'Invalid OTP provided');
        }
      } catch (error) {
        console.log('errorrr !!! ', error);
        this.tabControlService.viewToast('error', 'An error occurred');
      }
      this.closeOTPPopup();
    }
  }

  closeOTPPopup(): void {
    if (!this.otpLoading) {
      this.tabControlService.showResumePopup = false;
    }
  }
}
