import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-estate',
  templateUrl: './estate.component.html',
  styleUrls: ['./estate.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class EstateComponent implements AfterViewInit {
  tabsAnimationState: boolean[] = [false, false, false, false];

  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/logo_w.png';
  warnPath: string = '../../../assets/warn.png';

  disclaimer: string = 'Please note that we will be saving your information as you proceed along the steps';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  showSteps:boolean = false;
  isRelated:boolean = false;

  iSwear = new FormControl(false);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private tabControlService: TabControlService
  ) {
    this.tabControlService.estateType = 0;
    tabControlService.mainJourney = 1;
  }

  estateDataHolder: any = null;
  resumeActiveTextEstate: string = '';
  showResumePopup: boolean = false;

  ngAfterViewInit() {
    // Trigger the animation for each tab after a slight delay
    this.animateTabs();
  }

  async ngOnInit(): Promise<void> {
    const data = await this.tabControlService.getData(this.tabControlService.storageKeyEstate);
    if (data) {
      console.log(data);
      this.estateDataHolder = data;
      if (data.activeTabEstate === 1) this.resumeActiveTextEstate = 'Settlor Information';
      if (data.activeTabEstate === 2) this.resumeActiveTextEstate = 'Assets';
      if (data.activeTabEstate === 3) this.resumeActiveTextEstate = 'Preview';
      if (data.activeTabEstate === 4) this.resumeActiveTextEstate = 'Schedule';

      this.showResumePopup = true;
    }

    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params
    });
    
  }

  animateTabs() {
    for (let i = 0; i < this.tabsAnimationState.length; i++) {
      setTimeout(() => {
        this.tabsAnimationState[i] = true;
      }, 400 * i); // Adjust the delay as needed
    }
  }

  get loading(): boolean {
    return this.tabControlService.loading;
  };
  get activeTabEstate(): number {
    return this.tabControlService.activeTabEstate;
  }
  get estateType(): number {
    return this.tabControlService.estateType;
  }

  get page1DoneEstate(): boolean {
    return this.tabControlService.page1DoneEstate;
  }
  get page2DoneEstate(): boolean {
    return this.tabControlService.page2DoneEstate;
  }
  get page3DoneEstate(): boolean {
    return this.tabControlService.page3DoneEstate;
  }

  onOptionChange(value: boolean): void {
    this.isRelated = value;

    if (this.isRelated) {
      this.tabControlService.estateType = 0;
    } else {
      this.tabControlService.estateType = 1;
    }
  }

  navHome(): void {
    const nextPage = ``;
    this.router.navigate([nextPage]);
  }

  async resumeEstate(resume: boolean = true): Promise<void> {
    if (resume) {
      await this.tabControlService.updateDataFromStorage();
    
      if (this.tabControlService.settlorsEstate.length > 0 && this.tabControlService.settlorsEstate[0].verified) {
        this.showEstateSteps();
      }
    } else {
      this.tabControlService.resetLocalEstateData();
    }
    this.showResumePopup = false;
  }

  showEstateSteps(): void {
    this.showSteps = true;
  }
}
