import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../constants/constants";
import { BehaviorSubject } from 'rxjs';
import { Intermediaries, delay, generateRandomNumber, getCurrentTime, getCurrentTimePlus24HoursInMilliseconds, truncateString } from '../util/Helper';
import { Router } from '@angular/router';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class EstateControlService {

  journeyType: 0 | 1 = 0;     // 0 - Book appointment | 1- Onboard
  activeTabEstate: number = 1;

  // Estate Planning Book Appointment
  appointmentID: string | null = null;
  appointmentType: 0 | 1 = 0;   // 0 - Settlor | 1 - Related Individual/ Corporate [Formerly Estate Type]

  settlorsEstate: any = [];
  contactInfoEstate: any = {};
  assetDistribution: any = {};
  estateSummary: any = {};

  constructor() { }
}
