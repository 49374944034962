<div class = "editor-container">
    <div class="editor-overlay">
        <div class="editor-content">
            <div class="top-part">
                <div class="close-button" (click)="closePopup()">
                    <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
                </div>
                <h3 class="blue left-15">{{title}}</h3>
            </div>
            <!-- Content -->
            <!-- SETTLORS / BENEFICIARIES / GUARDIANS -->
            <form [formGroup]="editorForm" (ngSubmit)="onConfirmClick()"
                *ngIf="recordType !== 'Info'"
                class="editor-form" autocomplete="off" novalidate>

                <div class="row half" *ngIf="recordType === 'Guardian' && recordIndex !== 0">
                    <app-input [formGroup]="editorForm" controlName="Category"
                        inputType="select"
                        [ArrayItems]="GuardianOptions"
                        label={{guardianTypeLabel}} hint={{guardianTypeHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div *ngIf="recordType === 'Beneficiary' ">
                    <div class="row half" *ngIf="recordType === 'Beneficiary' ">    
                        <app-input [formGroup]="editorForm" controlName="Category"
                            inputType="select"
                            [ArrayItems]="BeneficiaryOptions"
                            label={{benTypeLabel}} hint={{benTypeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row" [ngClass]="{'half': !arrayContainsValue(editorForm.controls['TrustPurpose'].value, 'Other') }">
                        <app-input [formGroup]="editorForm" controlName="TrustPurpose"
                            label={{purposeLabel}} hint={{purposeHint}}
                            inputType="multi-select"
                            [ArrayItems]="TrustPurposeOptions"
                            [loadingOptions]="loadingTrustPurposeOptions"
                            [subtext]="purposeSubtext"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                        <app-input [formGroup]="editorForm" controlName="OtherPurpose"
                            *ngIf="arrayContainsValue(editorForm.controls['TrustPurpose'].value, 'Other')"
                            label={{otherPurposeLabel}} hint={{otherPurposeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="row-alt" *ngIf="settlorType == 1 && recordType == 'Settlor' || recordType != 'Settlor' && editorForm.get('Category')?.value !== 'Institution'">
                    <div class="row-mini">
                        <app-input [formGroup]="editorForm" controlName="Title"
                            label={{titleLabel}} hint={{titleHint}}
                            inputType="select"
                            [loadingOptions]="loadingTitles"
                            [ArrayItems]="TitleOptions"
                            required="false"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
    
                        <app-input [formGroup]="editorForm" controlName="FirstName"
                            label={{fNameLabel}} hint={{fNameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        
                    </div>
                    <div class="row-mini">
                        <app-input [formGroup]="editorForm" controlName="MiddleName"
                            label={{mNameLabel}} hint={{mNameHint}} required='false'
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        <app-input [formGroup]="editorForm" controlName="LastName"
                            label={{surnameLabel}} hint={{surnameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    </div>
                    
                </div>

                <div *ngIf="settlorType == 0 && recordType == 'Settlor' || editorForm.get('Category')?.value === 'Institution'">
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="InstitutionName"
                            class="grid-item" label={{institutionNameLabel}} hint={{institutionNameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="editorForm" controlName="BusinessNature"
                            class="grid-item" label={{businessNatureLabel}} hint={{businessNatureHint}}
                            inputType="select"
                            [ArrayItems]="BusinessNatureOptions"
                            [loadingOptions]="loadingBizNature"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row" *ngIf="settlorType == 1">
                        <app-input
                            [formGroup]="editorForm" controlName="OrgRegNum"
                            class="grid-item" label={{orgRegNumLabel}} hint={{orgRegNumHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="editorForm" controlName="KraPin"
                            class="grid-item" label={{kraPinLabel}} hint={{kraPinHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="row" *ngIf="editorForm.get('Category')?.value !== 'Institution'">
                    <app-input [formGroup]="editorForm" controlName="Phone"
                        label={{phoneLabel}} inputType="phone" [required]="recordType === 'Beneficiary' ? 'false' : 'true'"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input [formGroup]="editorForm" controlName="Email"
                        label={{emailLabel}} hint={{emailHint}} type="email" [required]="recordType === 'Beneficiary' ? 'false' : 'true'"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div *ngIf="settlorType == 1 && recordType === 'Settlor' || recordType === 'Beneficiary' && editorForm.get('Category')?.value !== 'Institution'">
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="Nationality"
                            *ngIf="recordType === 'Settlor' "
                            label={{nationalityLabel}} hint={{nationalityHint}}
                            inputType="select"
                            [ArrayItems]="CountryOptions"
                            [loadingOptions]="loadingCountries"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="editorForm" controlName="Gender"
                            label={{genderLabel}} hint={{genderHint}}
                            inputType="select"
                            [ArrayItems]="GenderOptions"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="editorForm" controlName="DOB"
                            *ngIf="recordType === 'Beneficiary' "
                            class="grid-item"
                            label={{dobLabel}}
                            hint={{dobHint}}
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            inputType="date"
                            [validationMessages]="validationMessages"
                            [formErrors]="formErrors"></app-input>
                    </div>
                </div>
                
                <div class="row" *ngIf="settlorType == 1 && recordType === 'Settlor' "
                    [ngClass]="{'half': (editorForm.get('Country')?.value)?.toLowerCase() !== 'kenya'}">
                    <app-input [formGroup]="editorForm" controlName="Country"
                        label={{countryLabel}} hint={{countryHint}}
                        inputType="select"
                        [ArrayItems]="CountryOptions"
                        [loadingOptions]="loadingCountries"
                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                    </app-input>
                    <app-input
                        *ngIf="editorForm.get('Country')?.value?.toLowerCase() === 'kenya'"
                        [formGroup]="editorForm" controlName="County"
                        label={{countyLabel}} hint={{countyHint}}
                        inputType="select"
                        [ArrayItems]="CountyOptions"
                        [loadingOptions]="loadingCounties"
                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                    </app-input>
                </div>

                <div *ngIf="recordType === 'Beneficiary' "
                    class="row" [ngClass]="{'half': editorForm.get('Category')?.value === 'Individual' && editorForm.get('DOB')?.value === ''}" >
                    <app-input
                        *ngIf="editorForm.get('Category')?.value === 'Institution'"
                        [formGroup]="editorForm" controlName="OrgAddress"
                        class="grid-item" label={{orgAddressLabel}} hint={{orgAddressHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    <app-input [formGroup]="editorForm" controlName="PercShare"
                        class="grid-item" label={{shareAllocationLabel}}
                        [hint]="finalRemainingShares.toString() +'% of shares available'"
                        [subtext]="shareAllocationSubtext"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    <app-input [formGroup]="editorForm" controlName="BirthCertImage"
                        *ngIf="editorForm.get('Category')?.value === 'Individual'
                            && editorForm.get('DOB')?.value !== ''
                            && getDateDifference(editorForm.get('DOB')?.value) === false"

                        class="grid-item" label={{birthCertLabel}} inputType="file"
                        [fileFunction]="updateBirthCertObject.bind(this)"
                        [existingFileName]="record.BirthCertImage ? record.BirthCertImage.name: ''"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    <app-input [formGroup]="editorForm" controlName="IDNoImage"
                        *ngIf="editorForm.get('Category')?.value === 'Individual'
                            && editorForm.get('DOB')?.value !== ''
                            && getDateDifference(editorForm.get('DOB')?.value) === true"
                        class="grid-item" label={{idFileLabel}} inputType="file"
                        [existingFileName]="record.IDNoImage ? record.IDNoImage.name : ''"
                        [fileFunction]="updateIDObject.bind(this)"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div class="row" *ngIf="editorForm.get('Category')?.value === 'Institution'">
                    <app-input [formGroup]="editorForm" controlName="RegCertImage"
                        class="grid-item" label={{regCertFileLabel}} inputType="file"
                        [existingFileName]="record.RegCertImage ? record.RegCertImage.name: ''"
                        [fileFunction]="updateRegCertObject.bind(this)"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input
                        [formGroup]="editorForm" class="grid-item"
                        controlName="KraPinImage" label={{kraFileLabel}} inputType="file"
                        [existingFileName]="record.KraPinImage ? record.KraPinImage.name: ''"
                        [fileFunction]="updateKraObject.bind(this)"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div class="row" *ngIf="recordType === 'Settlor'"  [ngClass]="{'width-50' : settlorType == 0}">
                    <app-input [formGroup]="editorForm" class="grid-item"
                        controlName="Address" label={{addressLabel}} hint={{addressHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input [formGroup]="editorForm" class="grid-item"
                        *ngIf="settlorType == 1"
                        controlName="Occupation" label={{occupationLabel}} hint={{occupationHint}} required="false"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div class="row" *ngIf="recordType === 'Settlor' && settlorType == 1 || recordType === 'Guardian' ||
                    (editorForm.get('Category')?.value === 'Individual' && editorForm.get('DOB')?.value !== '' && getDateDifference(editorForm.get('DOB')?.value) === true)">

                    <app-input [formGroup]="editorForm" class="grid-item"
                        controlName="IDNo" label={{idLabel}} hint={{idHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input [formGroup]="editorForm" class="grid-item"
                        controlName="KraPin" label={{kraPinLabel}} hint={{kraPinHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div class="row" *ngIf="recordType === 'Settlor' || recordType === 'Guardian'">
                    <app-input [formGroup]="editorForm" class="grid-item"
                        controlName="IDNoImage"
                        [label]="settlorType == 0 && recordType == 'Settlor' ? regCertFileLabel : idFileLabel"
                        [existingFileName]="record.IDNoImage ? record.IDNoImage.name: ''"
                        [fileFunction]="updateIDObject.bind(this)"
                        inputType="file"
                        
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    <app-input
                        [formGroup]="editorForm" class="grid-item"
                        controlName="KraPinImage" label={{kraFileLabel}} inputType="file"
                        [existingFileName]="record.KraPinImage ? record.KraPinImage.name : ''"
                        [fileFunction]="updateKraObject.bind(this)"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>
                
                <div formArrayName="ContactPersons" *ngIf="settlorType == 0 && recordType === 'Settlor' || editorForm.get('Category')?.value === 'Institution'"
                    class="flex column contacts" >
                    <span class="blue lead" style="font-size: 1.3rem; margin-left: 1.5%; margin-bottom: 15px;">
                        {{ recordType == 'Settlor' ? 'Authorisers' : 'Contact Persons' }}
                    </span>
                    <div class="line-2" style="background-color: #00518a;"></div>
                    <div *ngFor="let contact of getContacts().controls; let j=index" [formGroupName]="j" style="position: relative;">
                        <div style="height: 10px;" *ngIf="j > 1"></div>
        
                        <p style="font-weight: 600; font-size: 1rem; margin-left: 1.5%;" class="lead">
                            {{ recordType == 'Settlor' ? 'Authoriser ' + (j+1) : 'Contact Person ' + (j+1)}}
                        </p>

                        <div class="row-alt">
                            <div class="row-mini">
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="Title"
                                    label={{titleLabel}} hint={{titleHint}}
                                    inputType="select"
                                    [loadingOptions]="loadingTitles"
                                    [ArrayItems]="TitleOptions"
                                    required="false"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
            
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="FirstName"
                                    label={{fNameLabel}} hint={{fNameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                
                            </div>
                            
                            <div class="row-mini">
                                <app-input [formGroup]="getContactsFormGroup(j)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getContactsFormGroup(j)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            </div>
                            
                        </div>
                        
                        <div class="row">
                            <app-input [formGroup]="getContactsFormGroup(j)" controlName="Phone"
                                class="grid-item" label={{phoneLabel}}  inputType="phone"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getContactsFormGroup(j)" controlName="Email"
                                class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="line-2" ></div>
                        
                        <div class="remove-button" *ngIf="j > 0">    
                            <button mat-stroked-button type="button" (click)="removeContact(j)">
                                <div style="display: flex; flex: 1; align-items: center;">
                                    <span class="button-text">
                                        <u>{{ recordType == 'Settlor' ? 'Remove Authoriser' : removeContactText }}</u>
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>

                    <div class="blueStroke row" >
                        <button mat-stroked-button
                            [disabled]="!editorForm.valid"
                            type="button" (click)="addNewContact()">
                            <span class="button-text">
                                {{ recordType === 'Settlor' ? 'Add Authoriser' : '+ Add Contact Person'}}
                            </span>
                        </button>
                    </div>
                    
                </div>

                <!--  Principal Member -->
                <div formGroupName="Principal" *ngIf="settlorType == 0 && recordType == 'Settlor'" class="flex column contacts">
                    <p class="blue lead" style="font-size: 1.3rem; margin-left: 1.5%; margin-bottom: 15px;" >
                        {{'Principal Member'}}
                    </p>

                    <p class="principal-context">
                        To be filled <b>ONLY</b> if the Trust is being set up to hold Death Benefits.
                    </p>
                    <div class="line-2" style="background-color: #00518a;"></div>

                    <div class="row-mini">
                        <app-input [formGroup]="editorForm.controls['Principal']" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="editorForm.controls['Principal']" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        <app-input [formGroup]="editorForm.controls['Principal']" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    </div>

                    <div class="row half">
                        <app-input [formGroup]="editorForm.controls['Principal']" class="grid-item"
                            controlName="DeathCertImage"
                            label="Upload Death Certificate"
                            [fileFunction]="updateDeathObject.bind(this)"
                            inputType="file"
                            required="false"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                    
                </div>

                <div *ngIf="recordType == 'Guardian'">
                    <div class="line" style="margin-bottom: 5px;"></div>
                    <div class="flex">
                        <p class="brue margin">
                            {{'Beneficiaries'}}
                        </p>
                    </div>

                    <p style="color: #646464; line-height: 1.5;">
                        A Guardian <b>MUST</b> have beneficiaries assigned under them. The same beneficiary can also be assigned to multiple guardians
                    </p>
                    
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="GuardianBens"
                            label={{bensLabel}} hint={{bensHint}}
                            inputType="multi-select"
                            [ArrayItems]="BeneficiaryList"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>
            
                <!-- BOTTOM OF FORM -->
                <div class="editor-buttons">
                    <button mat-button class="confirm"
                        (click)="onConfirmClick()"
                        [ngClass]="{'loadingButton': loading}"
                        [disabled]="loading || !editorForm.valid">

                        <div style="display: flex; width: 100%; align-items: center;">
                            {{loading ? loadingText : 'Done'}}
                        
                            <span class="spinning" *ngIf="loading">
                                <mat-progress-spinner
                                    mode="indeterminate"
                                    diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>

                    <button class = "cancel" mat-button type="button" (click)="closePopup()">Cancel</button>
                </div>
            </form>

            <!-- TRUST INFORMATION -->
            <form [formGroup]="editorForm" (ngSubmit)="onConfirmClick()" *ngIf="recordType === 'Info'"
                class="editor-form" autocomplete="off" novalidate>

                <div class="row half">
                    <app-input [formGroup]="editorForm" controlName="TrustName"
                        class="grid-item" label={{trustNameLabel}} hint={{trustNameHint}}
                        [subtext]="trustNameSubtext"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div class="row" [ngClass]="{'half': !arrayContainsValue(editorForm.controls['SourceOfFunds'].value, 'Other') }">
                    <app-input [formGroup]="editorForm" controlName="SourceOfFunds"
                        label={{fundSourceLabel}} hint={{fundSourceHint}}
                        inputType="multi-select"
                        [ArrayItems]="FundSourceOptions"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        
                    <app-input [formGroup]="editorForm" controlName="OtherSource"
                        *ngIf="arrayContainsValue(editorForm.controls['SourceOfFunds'].value, 'Other')"
                        label={{otherSourceLabel}} hint={{otherSourceHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <div *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                    <p class="lead blue" style="margin-bottom: -5px; font-weight: 700; margin-top: 20px; margin-left: 15px;">
                        {{upkeepText}}
                    </p>
    
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="UpkeepAmount"
                            label={{upkeepAmountLabel}} hint={{upkeepAmountHint}} required="false"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            
                        <app-input [formGroup]="editorForm" controlName="UpkeepFrequency"
                            label={{frequencyUpkeepLabel}} hint={{frequencyUpkeepHint}}
                            inputType="select"
                            [ArrayItems]="FrequencyOptions"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
            
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="Bank"
                            [selectFunction]="populateBankBranchesList.bind(this)"
                            inputType="select"
                            [ArrayItems]="BanksOptions"
                            label={{bankLabel}} hint={{bankHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            
                        <app-input [formGroup]="editorForm" controlName="BankBranch"
                            label={{bankBranchLabel}} hint={{bankBranchHint}}
                            inputType="select"
                            [ArrayItems]="BranchPlaceHolders"
                            [loadingOptions]="loadingBankBranches"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
            
                    <div class="row">
                        <app-input [formGroup]="editorForm" controlName="AccountName"
                            label={{accNameLabel}} hint={{accNameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="editorForm" controlName="AccountNo"
                            label={{accLabel}} hint={{accHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>
                
                <p class="lead blue" style="font-weight: 700; margin-top: 20px; margin-bottom: 10px; margin-left: 15px;">
                    {{additionalInfoText}}
                </p>

                <div class="row" [ngClass]="{'width-50' : editorForm.controls['AccountMandate'].value !== 'Special signing arrangement'}">
                    <app-input [formGroup]="editorForm" controlName="AccountMandate"
                        label={{instructionsLabel}} hint={{selectText}}
                        inputType="select"
                        [ArrayItems]="InstructorOptions"
                        [subtext]="instructionsSubtext"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    <app-input [formGroup]="editorForm" controlName="SigningArrangement"
                        *ngIf="editorForm.controls['AccountMandate'].value === 'Special signing arrangement'"
                        label={{signingArrangementLabel}} hint="Specify your signing arrangement" inputType="area"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>
        
                <div class="row"
                    *ngIf="!hideReferree"
                    [ngClass]="{'width-50' :
                        !(
                            editorForm.get('HowDidYouHearUS')?.value === 'Other' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'Independent Agent' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'Broker' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Financial Advisor' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Trust Consultant'
                        )}">
                    <app-input [formGroup]="editorForm" controlName="HowDidYouHearUS"
                        label={{referreLabel}} hint={{selectText}}
                        inputType="select"
                        [ArrayItems]="HearAboutusData"
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
        
                    <app-input [formGroup]="editorForm" controlName="OtherHear"
                        *ngIf="editorForm.get('HowDidYouHearUS')?.value === 'Other'"
                        label={{referreeOtherLabel}} hint={{referreeOtherHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    <app-input [formGroup]="editorForm" controlName="AgentName"
                        *ngIf="
                            editorForm.get('HowDidYouHearUS')?.value === 'Independent Agent' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'Broker' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Financial Advisor' ||
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Trust Consultant' "
    
                        [label]="
                            editorForm.get('HowDidYouHearUS')?.value === 'Broker' ? 'Name of Broker' :
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Financial Advisor' ? 'Name of Financial Advisor' :
                            editorForm.get('HowDidYouHearUS')?.value === 'ICEA LION Trust Consultant' ? 'Name of Consultant'
                            : agentLabel"

                        inputType="select"
                        [ArrayItems]="IntermediaryOptions"
                        [loadingOptions]="loadingIntermediaries"
                        hint={{agentHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>

                <!-- BOTTOM OF FORM -->
                <div class="editor-buttons">
                    <button mat-button class="confirm"
                        (click)="onConfirmClick()"
                        [ngClass]="{'loadingButton': loading}"
                        [disabled]="loading || !editorForm.valid">

                        <div style="display: flex; width: 100%; align-items: center;">
                            {{loading ? loadingText : 'Done'}}
                        
                            <span class="spinning" *ngIf="loading">
                                <mat-progress-spinner
                                    mode="indeterminate"
                                    diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>

                    <button class = "cancel" mat-button type="button" (click)="closePopup()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
