<div [formGroup]="previewForm" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80" #pdfContent>
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">{{subtitle}}</p>

        <!-- <div class="box-wrap">
            <div class="box">
                <div class="row" style="align-items: center; margin-bottom: -20px;">
                    <p class="blue lead" style=" margin-left: 0px; font-weight: 600; font-size: larger;">
                    {{personalDataTitle}}
                    </p>
                    <div class="goldStroke">
                        <button class="edit-text" mat-stroked-button>{{editText}}</button>
                    </div>
                </div>
                <div class="line"></div>
                <div class="items">
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'First Name'.toUpperCase()}}</span>
                            <span class="value"><b>{{basicData.FirstName}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="!(basicData.MiddleName === undefined || basicData.MiddleName === '')">
                        <div class="item">
                            <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                            <span class="value"><b>{{basicData.MiddleName}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                            <span class="value"><b>{{basicData.LastName}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                            <span class="value"><b>{{basicData.Phone}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Email'.toUpperCase()}}</span>
                            <span class="value"><b>{{basicData.Email}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="box-wrap">
            <div class="box">
                <p class="blue lead section-title">
                    {{settlorsTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let settlor of settlors; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{settlorType == 0 ? 'Institution Settlor' :  'Settlor ' + (i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Settlor', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    
                    <div *ngIf="settlorType == 0">
                        <div class="items">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Name of Institution'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.InstitutionName || '-'}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Nature of Business'.toUpperCase()}}</span>
                                    <span class="value"><b>
                                        {{settlor.BusinessNature || '-'}}
                                    </b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Organisation Phone Number'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Phone || '-'}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Organisation Email Address'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Email || '-'}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Organisation Physical Address'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Address || '-'}}</b></span>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Authorisers -->
                        <div style="width: 100%;">
                            <div class="line"></div>
                            
                            <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let contact of settlor.Authorisers; let j=index">
                                <p class="lead">{{'Authoriser ' +(j+1) }}</p>
    
                                <div class="items">
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'First Name'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{contact.FirstName === undefined || contact.FirstName === '' ? '-' : contact.FirstName}}</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{contact.MiddleName === undefined || contact.MiddleName === '' ? '-' : contact.MiddleName}}
                                            </b></span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{contact.LastName === undefined || contact.LastName === '' ? '-' : contact.LastName}}
                                            </b></span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{contact.Phone === undefined || contact.Phone === '' ? '-' : contact.Phone}}</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Email'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{contact.Email === undefined || contact.Email === '' ? '-' : contact.Email}}
                                            </b></span>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="line-c" *ngIf="j !== settlor.Authorisers.length-1"></div> 
                            </div>
                        </div>

                        <!-- Principal -->
                        <div style="width: 100%;" *ngIf="settlor.Principal && settlor.Principal.FirstName && settlor.Principal.FirstName !== '' ">
                            <div class="line"></div>

                            <div style="display: flex; flex-direction: column; width: 100%;">
                                <p class="lead">{{'Principal Member' }}</p>
    
                                <div class="items">
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'First Name'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{settlor.Principal.FirstName === undefined || settlor.Principal.FirstName === '' ? '-' : settlor.Principal.FirstName}}</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{settlor.Principal.MiddleName === undefined || settlor.Principal.MiddleName === '' ? '-' : settlor.Principal.MiddleName}}
                                            </b></span>
                                        </div>
                                    </div>
                                    <div class="item-wrap">
                                        <div class="item">
                                            <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                            <span class="value"><b>
                                                {{settlor.Principal.LastName === undefined || settlor.Principal.LastName === '' ? '-' : settlor.Principal.LastName}}
                                            </b></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="items" *ngIf="settlorType == 1">  
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{settlor.MiddleName === undefined || settlor.MiddleName === '' ? '-' : settlor.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Gender'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Gender}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Nationality'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Nationality}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Country'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Country}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="!(settlor.County === undefined || settlor.County === '')">
                            <div class="item">
                                <span class="key">{{'County'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.County}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.KraPin}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Address}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Occupation'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{settlor.Occupation === undefined || settlor.Occupation === '' ? '-' : settlor.Occupation}}
                                </b></span>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <!-- Breaks used by jsPDF when generating PDF -->
        <div class="break"></div>   
        <div class="box-wrap">
            <div class="box">
                <p class="blue lead section-title" >
                    {{bensTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let ben of beneficiaries; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Beneficiary ' +(i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Beneficiary', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Beneficiary Type'.toUpperCase()}}</span>
                                <span class="value"><b>{{ben.Category}}</b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.FirstName}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.MiddleName === undefined || ben.MiddleName === '' ? '-' : ben.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.LastName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.Phone === undefined || ben.Phone === '' ? '-' : ben.Phone}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.Email === undefined || ben.Email === '' ? '-' : ben.Email}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Date of Birth'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.DOB}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Gender'.toUpperCase()}}</span>
                                <span class="value"><b>{{ben.Gender}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="!(ben.IDNo === undefined || ben.IDNo === '') ">   <!-- Currently off !  -->
                            <div class="item">
                                <span class="key">{{'ID/Passport Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.IDNo}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Institution Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.InstitutionName}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Nature of Business'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.BusinessNature}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Registration Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.OrgRegNum}}
                                </b></span>
                            </div>
                        </div>


                        <div class="item-wrap" *ngIf="!(ben.KraPin === undefined || ben.KraPin === '')">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.KraPin}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.OrgAddress === undefined || ben.OrgAddress === '' ? '-' : ben.OrgAddress}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Share Allocation (%)'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.PercShare === undefined || ben.PercShare === '' ? '-' : ben.PercShare}}
                                </b></span>
                            </div>
                        </div>
                        
                    </div>

                    <div style="width: 100%;" *ngIf="ben.Category === 'Institution' ">
                        <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let contact of ben.ContactPersons; let j=index">
                            <p class="lead">{{'Contact Person ' +(j+1) }}</p>

                            <div class="items">
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'First Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.FirstName === undefined || contact.FirstName === '' ? '-' : contact.FirstName}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.MiddleName === undefined || contact.MiddleName === '' ? '-' : contact.MiddleName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.LastName === undefined || contact.LastName === '' ? '-' : contact.LastName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Phone === undefined || contact.Phone === '' ? '-' : contact.Phone}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Email'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Email === undefined || contact.Email === '' ? '-' : contact.Email}}
                                        </b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="line-c" *ngIf="j !== ben.ContactPersons.length-1"></div> 
                        </div>
                    </div>
                    
                    <div class="line"></div>           
                </div>
            </div>
        </div>
        <div class="break"></div>
        <div class="box-wrap">
            <div class="box">
                <p class="blue lead section-title">
                    {{guardiansTitle}}
                </p>
                <div class="line-3"></div>
                
                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let guardian of guardians; let i=index">
                    
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Guardian ' +(i+1)}}</p>
                        
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Guardian', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>

                    <div class="items">
                        <div class="item-wrap" *ngIf="i > 0">
                            <div class="item">
                                <span class="key">{{'Guardian Type'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.Category}}</b></span>
                            </div>
                        </div>

                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ guardian.MiddleName === undefined || guardian.MiddleName === '' ? '-' : guardian.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" >
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID/Passport Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>{{guardian.KraPin}}</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="break"></div>
        <div class="box-wrap">
            <div class="box">
                <div class="row" style="align-items: center; margin-bottom: -20px;">
                    <p class="blue lead section-title" >
                    {{trustInfoTtile}}
                    </p>
                    <div class="goldStroke">
                        <button class="edit-text" (click)="editRecord('Info', 0)" mat-stroked-button>{{editText}}</button>
                    </div>
                </div>
                <div class="line-3" style="margin-top: 10px; margin-bottom: 10px;"></div>
                <div class="items">
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Trust Name'.toUpperCase()}}</span>
                            <span class="value" ><b>{{trustInfo.TrustName}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Source Of Funds'.toUpperCase()}}</span>
                            <span class="value" ><b>
                                {{trustInfo.SourceOfFunds}}
                            </b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="trustInfo.OtherSource && trustInfo.OtherSource !== ''">
                        <div class="item">
                            <span class="key">{{'Other Source of Funds'.toUpperCase()}}</span>
                            <span class="value"><b>
                                {{ trustInfo.OtherSource }}
                            </b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Amount of Keep'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.UpkeepAmount === undefined || trustInfo.UpkeepAmount === '' ? '-' : trustInfo.UpkeepAmount}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Frequency of UpKeep'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.UpkeepFrequency === undefined || trustInfo.UpkeepFrequency === '' ? '-' : trustInfo.UpkeepFrequency}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Bank Name'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.Bank === undefined || trustInfo.Bank === '' ? '-' : trustInfo.Bank}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Bank Branch'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.BankBranch === undefined || trustInfo.BankBranch === '' ? '-' : trustInfo.BankBranch}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Account Name'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.AccountName === undefined || trustInfo.AccountName === '' ? '-' : trustInfo.AccountName}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="arraysContainValue(beneficiaries, 'Upkeep')">
                        <div class="item">
                            <span class="key">{{'Account Number'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.AccountNo === undefined || trustInfo.AccountNo === '' ? '-' : trustInfo.AccountNo}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Source of Information'.toUpperCase()}}</span>
                            <span class="value">
                                <b>{{trustInfo.HowDidYouHearUS === 'Other' ? 'Other source' : trustInfo.HowDidYouHearUS }}</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="trustInfo.HowDidYouHearUS === 'Other'">
                        <div class="item">
                            <span class="key">{{'Other Source'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.OtherHear === undefined || trustInfo.OtherHear === '' ? '-' : trustInfo.OtherHear }}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="trustInfo.AgentName && trustInfo.AgentName !== ''">
                        <div class="item">
                            <span class="key">{{'Referred By'.toUpperCase()}}</span>
                            <span class="value"><b>{{ trustInfo.AgentName }}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap">
                        <div class="item">
                            <span class="key">{{'Account Instructions'.toUpperCase()}}</span>
                            <span class="value"><b>{{trustInfo.AccountMandate}}</b></span>
                        </div>
                    </div>
                    <div class="item-wrap full-col">
                        <div class="item">
                            <span class="key signing">{{'Special Signing Arrangement'.toUpperCase()}}</span>
                            <span class="value"><b>
                                {{trustInfo.SigningArrangement === undefined || trustInfo.SigningArrangement === '' ? '-' : trustInfo.SigningArrangement}}
                            </b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- 
        <div class="row-bar" style="align-items: center; justify-content: center; margin-top: 20px;">
            <mat-checkbox formControlName="iSwear"  style="margin-right: 20px;">
                <p style="font-size: larger;">I have read and accepted ICEA LION's <span style="color: #003764;"><u>Data Collection Consent and Privacy Policy</u></span></p>
            </mat-checkbox>
             
        </div> -->
        
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [formValid]="!loading"
        [showContinue]="loading ? 'false' : 'true'"
        [saveFunction]="updateProgress.bind(this)"
        [text]="loading ? 'Please Wait' : 'Next'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</div>

<app-record-editor *ngIf="isPopupVisible" (close)="onPopupClose()"
    [record]="record"
    [recordType]="recordType"
    [recordIndex]="recordIndex">
</app-record-editor>
