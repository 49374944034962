import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-step8-complete',
  templateUrl: './step8-complete.component.html',
  styleUrls: ['./step8-complete.component.scss']
})
export class Step8CompleteComponent {

  MileleLogoPath: string  = '../../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../../assets/logo_w.png';
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Your Bank Slip was successfully received.';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  textH1: string = 'We have received your payment confirmation slip of ';
  textH2: string = ' A copy of the payment confirmation slip has also been sent to your email address, ';
  text2: string = 'We are now processing your funds, and will send a notification within 48 hours.';
  
  buttonText: string = 'Done';

  email: string = 'Test@gmail.com';
  amount: string = 'KSH 20,000T';

  constructor(
    private tabControlService: TabControlService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.basicData && this.basicData.Email) {
      this.email = this.basicData.Email;
    }

    // console.log('Brrr !! ', this.tabControlService.paymentData)

    if (this.paymentData && this.paymentData.amount) {
      if (this.paymentData.PaymentMode === 'M-Pesa') {
        this.title = 'Your M-Pesa payment was successfully received.';
      }
      this.amount = this.paymentData.amount;
    }

    this.tabControlService.clearData(this.tabControlService.storageKey);
  }

  get basicData(): any {
    return this.tabControlService.basicData;
  }
  get paymentData(): any {
    return this.tabControlService.paymentData;
  }

  async goHome(): Promise<void> {
    await this.tabControlService.clearServiceRecords();
    this.tabControlService.clearData(this.tabControlService.storageKey);
    
    const nextPage = ``;
    this.router.navigate([nextPage]);

  }
}
