<form [formGroup]="guardianForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal" style="margin-bottom: 0px;">
            {{subtitle}}
        </p>

        <div formArrayName="guardians">
            <div *ngFor="let settlor of getGuardians().controls; let i=index"
                [formGroupName]="i"
                style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>

                <div class="flex" style="width: 100%; align-items: center; justify-content: center;">
                    <div class="flex brief" *ngIf="i === getGuardians().length-1 && suggestions === null && showSuggestHint"[@bounceIn]>
                        If the Guardian is an existing Settlor, use the ID or First Name fields to search and autofill their details
                    </div>
                </div>

                <div class="flex godolkin">
                    <p class="flex brue margin" style="font-weight: 600; font-size: 1.3em;">
                        {{'Guardian ' + (i+1)}}
                    </p>

                    <div class="flex suggestions" *ngIf="i === getGuardians().length-1 && suggestions !== null" [@bounceIn] #suggestBox>
                        <div class="flex suggest">
                            Autofill
                        </div>

                        <div class="flex sugg-holder">
                            <div [@slideInRight] *ngFor="let suggestion of suggestions; let s=index"
                                (click)="patchSuggestedValues(i, suggestion)"
                                class="flex suggestion">
                                {{ suggestion.FirstName +' ' +suggestion.LastName }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="guardians[i] && !guardians[i].verified || autofilled === true && guardians[i] && !guardians[i].done ? true : false">
                    <div class="flex">
                        <p class="brue margin">
                            {{'Personal Information'}}
                        </p>
                    </div>
                    
                    <div class="line-2"></div>

                    <div class="row half" *ngIf="i > 0">
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="Category"
                            inputType="select"
                            [ArrayItems]="GuardianOptions"
                            label={{guardianTypeLabel}} hint={{guardianTypeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row-alt">
                        <div class="row-mini">
                            <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="Title"
                                label={{titleLabel}} hint={{titleHint}}
                                inputType="select"
                                [loadingOptions]="loadingTitles"
                                [ArrayItems]="TitleOptions"
                                required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
        
                            <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [runSuggestions]="true"
                                [suggestFunction]="suggestSettlors.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            
                        </div>
                        
                        <div class="row-mini">
                            <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="MiddleName"
                            label={{mNameLabel}} hint={{mNameHint}} required='false'
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="LastName"
                            label={{surnameLabel}} hint={{surnameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                        
                    </div>
                    
                    <div class="row">
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="Phone"
                            class="grid-item" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="Email"
                            class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row">
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="IDNo"
                            class="grid-item" label={{idLabel}} hint={{idHint}}
                            [runSuggestions]="true"
                            [suggestFunction]="suggestSettlors.bind(this)"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="KraPin"
                            class="grid-item" label={{kraPinLabel}} hint={{kraPinHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        
                    </div>

                    <div class="line" style="margin-bottom: 5px;"></div>
                    <div class="flex">
                        <p class="brue margin">
                            {{'Beneficiaries'}}
                        </p>
                    </div>

                    <p style="color: #646464; line-height: 1.5;">
                        A Guardian <b>MUST</b> have beneficiaries assigned under them. The same beneficiary can also be assigned to multiple guardians
                    </p>
                    
                    <div class="row">
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="GuardianBens"
                            label={{bensLabel}} hint={{bensHint}}
                            inputType="multi-select"
                            [ArrayItems]="BeneficiaryList"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                </div>

                <div class="flex done-box" *ngIf="(guardians[i] && guardians[i].verified && !autofilled) || (autofilled && guardians[i] && guardians[i].done)">
                    <p class="light-blue lead" >
                        {{'Personal Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="(autofilled && i === getGuardians().length-1 && guardians[i] && !guardians[i].done) || (guardians[i] && guardians[i].verified && !guardians[i].done)">
                    <p class="blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="IDNoImage"
                            [fileFunction]="updateIDObject.bind(this)"
                            [existingFileName]="idObject && idObject.name ? idObject.name : ''"
                            class="grid-item" label={{idFileLabel}} inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getGuardiansFormGroup(i)" controlName="KraPinImage"
                            [fileFunction]="updateKraObject.bind(this)"
                            [existingFileName]="kraObject && kraObject.name ? kraObject.name : ''"
                            class="grid-item" label={{kraFileLabel}} inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                </div>

                <div class="flex done-box" *ngIf="guardians[i] && guardians[i].verified && guardians[i].done">
                    <p class="light-blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeGuardian(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ loadingRemove ? 'Removing guardian' : removeGuardianText }}</u>
                            </span>
                            <span class="spinning-global" *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    
        <!-- BUTTTON Add Guardian -->
        <div class="blueStroke row" [ngClass]="{'min-55' : getGuardians().length > 1}">
            <button mat-stroked-button
                *ngIf="guardians[guardians.length-1] && guardians[guardians.length-1].done === true "
                [disabled]="!guardianForm.valid || loading || loadingRemove"
                type="button" (click)="addNewGuardian()">
                <span class="button-text">
                    {{ addButtonText }}
                </span>
            </button>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? loadingText : guardians[guardians.length-1].done ? 'Next' : buttonText "
        [formValid]="guardianForm.valid || guardians[guardians.length-1].done === true"
        [showContinue]="guardianForm.valid ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [buttonClickHandler]="submitForm.bind(this)"></app-form-bottom>
</form>

<app-assign-guardian
    *ngIf="showAssignPopup"
    (close)="toggleAssignPopup(false)"
    (closeAndProceed)="closeAssignPopup()"
    [beneficiaries]="BeneficiaryList"
    [guardians]="GuardianList">
</app-assign-guardian>

