<form [formGroup]="settlorForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="formal" *ngIf="!settlorFormVisible">
        <h1 class="blue">{{title}}</h1>
        <p class="lead local">{{subtitle}}</p>
        <div class="row settlor" style="display: flex; align-items: center; justify-content: center;">
            <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                labelOptionA={{organizationText}}
                labelOptionB={{individualText}}
                (valueChange)="onOptionChange($event)" >
            </app-radios>
        </div>

        <div class="badge-wrapper">
            <div class="badge">
                <p>{{sentence}}</p>
                <ul>
                    <li>{{ settlorType === 0 ? point1Institution : point1Settlor }}</li>
                    <li>{{point2}}</li>
                    <li>{{point3}}</li>
                </ul>
                </div>
        </div>

        <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
            <mat-checkbox [formControl]="iSwear" >
                <p style="font-size: larger; display: flex;">
                    <span style="color: red; margin-right: 8px;">*</span>
                    I have read and accepted ICEA LION's Online&nbsp;<a href="https://icealion.co.ke/icea-lion-group-privacy-policy/" target="_blank" rel="noopener" style="color: #003764;"><u>Privacy Policy</u></a>
                </p>
            </mat-checkbox>
             
        </div>
    </div>

    
    <div class="form-80" *ngIf="settlorFormVisible">
        <h1 class="blue margin">{{settlorType == 0 ? formTitleAlt : formTitle}}</h1>
        <p class="lead rocal">{{settlorType == 0 ? formSubtitleAlt : formSubtitle}}</p>

        <!-- Insitution Settlor Journey -->
        <div formArrayName="settlors" *ngIf="settlorType == 0">
            <div *ngFor="let settlor of getSettlors().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>
                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin">{{'Settlor ' + (i+1)}} </p>

                <!-- Institution Information -->
                <div class="flex blue-box" *ngIf="settlors[i] && !settlors[i].infoDone">
                    <p class="blue lead" >
                        {{'Institution Information'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="InstitutionName"
                            label="Name of Institution" hint="Enter name"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="BusinessNature"
                            label="Nature of Business" hint="Select nature of business"
                            inputType="select"
                            [ArrayItems]="BusinessNatureOptions"
                            [loadingOptions]="loadingBizNature"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                    
                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Phone" label="Organisation Phone Number"  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Email" label="Organisation Email Address" hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row half">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Address" label="Organisation Physical Address" hint={{addressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].infoDone">
                    <p class="light-blue lead" >
                        {{'Institution Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- +++++++++++++++++++++++ -->

                <!--  Institution Authoriser -->
                <div class="flex blue-box" *ngIf="settlors[i] && settlors[i].infoDone && !settlors[i].authDone">
                    <p class="blue lead" >
                        {{'Institution Authoriser(s)'}}
                    </p>
                    <div class="line-2"></div>

                    <div formArrayName="Authorisers">
                        <div *ngFor="let contact of getAuthorisers(i).controls; let j=index" [formGroupName]="j" style="position: relative;">
                            <div style="height: 20px;" *ngIf="j > 1"></div>
    
                            <div *ngIf="getAuthorisers(i) && j == getAuthorisers(i).length-1">
                                <p class="blue lead" *ngIf="j > 0">
                                    {{'Authoriser ' + (j+1)}}
                                </p>

                                <div class="row-alt">
                                    <div class="row-mini">
                                        <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="Title"
                                            label={{titleLabel}} hint={{titleHint}}
                                            inputType="select"
                                            [loadingOptions]="loadingTitles"
                                            [ArrayItems]="TitleOptions"
                                            required="false"
                                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                                        </app-input>
                    
                                        <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="FirstName"
                                            label={{fNameLabel}} hint={{fNameHint}}
                                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                        
                                    </div>
                                    
                                    <div class="row-mini">
                                        <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="MiddleName"
                                        label={{mNameLabel}} hint={{mNameHint}} required='false'
                                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                    <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="LastName"
                                        label={{surnameLabel}} hint={{surnameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                    </div>
                                    
                                </div>
                                
                                <div class="row">
                                    <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="Phone"
                                        class="grid-item" label={{phoneLabel}}  inputType="phone"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="getAuthorisersFormGroup(i, j)" controlName="Email"
                                        class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="line-2" style="margin-top: 20px;"></div>
                            </div>

                            <div class="flex done-box" *ngIf="getAuthorisers(i) && getAuthorisers(i).length > 1 && j != getAuthorisers(i).length-1">
                                <p class="light-blue lead" >
                                    {{'Authoriser ' + (j+1)}}
                                </p>
                                <div class="flex checker-circle">
                                    <img src={{checkerPath}} class="checker" alt="logo" />
                                </div>
                            </div>
    
                            <div class="remove-auth-button" style="margin-top: 10px;" *ngIf="j > 0">
                                <button mat-stroked-button type="button" (click)="removeAuthoriser(i, j)">
                                    <div style="display: flex; flex: 1; align-items: center;">
                                        <span class="button-text">
                                            <u>{{ 'Remove Authoriser' }}</u>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- BUTTTON Add Authoriser-->
                    <div class="blueStroke row"
                        style="margin-top: 10px;"
                        [ngClass]="{'min-55' : getAuthorisers(i).length > 1}"
                        *ngIf="settlorVerified == true">
                        <button mat-stroked-button
                            [disabled]="!(settlorForm.valid && settlorVerified)"
                            type="button" (click)="addNewAuthoriser(i)">
                            <span class="button-text">
                                {{ '+ Add Authoriser' }}
                            </span>
                        </button>
                    </div>
                    
                </div>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].infoDone && settlors[i].authDone">
                    <p class="light-blue lead" >
                        {{'Institution Authoriser'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- +++++++++++++++++ -->

                <!--  Upload Documents -->
                <div class="flex blue-box" *ngIf="settlors[i] && settlors[i].authDone && !settlors[i].docsDone">
                    <p class="blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="IDNoImage"
                            label="Upload Registration Certificate"
                            [fileFunction]="updateIDObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="KraPinImage"
                            label="Upload Organisation KRA PIN"
                            [fileFunction]="updateKraObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].authDone && settlors[i].docsDone">
                    <p class="light-blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- +++++++++++++++++ -->

                <!--  Principal Member -->
                <section class="flex blue-box" formGroupName="Principal" *ngIf="settlors[i] && settlors[i].docsDone && !settlors[i].done">
                    <p class="blue lead" >
                        {{'Principal Member'}}
                    </p>

                    <p class="principal-context">
                        To be filled <b>ONLY</b> if the Trust is being set up to hold Death Benefits.
                    </p>
                    
                    <div class="line-2"></div>

                    <div class="blueStroke row" *ngIf="!addDesceased">
                        <button mat-stroked-button class="general-stroked-button" type="button"
                            (click)="ondesceasedChange(true)">
                            <span class="button-text">
                                + Add Deceased
                            </span>
                        </button>
                    </div>

                    <div *ngIf="addDesceased">
                        <p class="principal-context">
                            Enter the deceased person's details below:
                        </p>
    
                        <div class="row-mini">
                            <app-input [formGroup]="getPrincipalFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                            <app-input [formGroup]="getPrincipalFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getPrincipalFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
    
                        <div class="row half">
                            <app-input [formGroup]="getPrincipalFormGroup(i)" class="grid-item"
                                controlName="DeathCertImage"
                                label="Upload Death Certificate"
                                [fileFunction]="updateDeathObject.bind(this)"
                                inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="line-2"></div>

                        <div class="remove-button">
                            <button mat-stroked-button type="button" (click)="ondesceasedChange(false)">
                                <div style="display: flex; flex: 1; align-items: center;">
                                    <span class="button-text">
                                        <u>Remove</u>
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </section>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].docsDone && settlors[i].done">
                    <p class="light-blue lead" >
                        {{'Principal Member'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- +++++++++++++++++ -->

                <!-- Not currently in use -->
                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeSettlor(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ getSettlors().controls.length-1 === i ? removeButtonText : removeSettlorText }}</u>
                            </span>
                            <span class="spinning-global"
                                *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        
        <!-- Individual Settlor Journey -->
        <div formArrayName="settlors" *ngIf="settlorType == 1">
            <div *ngFor="let settlor of getSettlors().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>
                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin">{{'Settlor ' + (i+1)}} </p>

                <div class="flex blue-box" *ngIf="i === 0 ? !personalDone : settlors[i] && !settlors[i].verified ? true : false">
                    <p class="blue lead" >
                        {{'Personal Information'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row-alt">
                        <div class="row-mini">
                            <app-input [formGroup]="getSettlorFormGroup(i)" controlName="Title"
                                label={{titleLabel}} hint={{titleHint}}
                                inputType="select"
                                [loadingOptions]="loadingTitles"
                                [ArrayItems]="TitleOptions"
                                required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
        
                            <app-input [formGroup]="getSettlorFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            
                        </div>
                        
                        <div class="row-mini">
                            <app-input [formGroup]="getSettlorFormGroup(i)" controlName="MiddleName"
                            label={{mNameLabel}} hint={{mNameHint}} required='false'
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="LastName"
                            label={{surnameLabel}} hint={{surnameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                        
                    </div>
                    
                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Phone" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="Nationality"
                            label={{nationalityLabel}} hint={{nationalityHint}}
                            inputType="select"
                            [ArrayItems]="CountryOptions"
                            [loadingOptions]="loadingCountries"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>

                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="Gender"
                            label={{genderLabel}} hint={{genderHint}}
                            inputType="select"
                            [ArrayItems]="GenderOptions"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>

                    <div class="row"[ngClass]="{'half': getSettlorFormGroup(i).get('Country')?.value.toLowerCase() !== 'kenya'}">
                        <app-input [formGroup]="getSettlorFormGroup(i)" controlName="Country"
                            label={{countryLabel}} hint={{countryHint}}
                            inputType="select"
                            [ArrayItems]="CountryOptions"
                            [loadingOptions]="loadingCountries"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                        <app-input
                            *ngIf="getSettlorFormGroup(i).get('Country')?.value.toLowerCase() === 'kenya'"
                            [formGroup]="getSettlorFormGroup(i)" controlName="County"
                            label={{countyLabel}} hint={{countyHint}}
                            inputType="select"
                            [ArrayItems]="CountyOptions"
                            [loadingOptions]="loadingCounties"
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>
    
                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="IDNo" label={{idLabel}} hint={{idHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="KraPin" label={{kraPinLabel}} hint={{kraPinHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Address" label={{addressLabel}} hint={{addressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="Occupation" label={{occupationLabel}} hint={{occupationHint}} required="false"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].verified ? true : false">
                    <p class="light-blue lead" >
                        {{'Personal Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="settlors[i] && settlors[i].verified && !settlors[i].done">
                    <p class="blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="IDNoImage"
                            label={{idFileLabel}}
                            [fileFunction]="updateIDObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getSettlorFormGroup(i)" class="grid-item"
                            controlName="KraPinImage"
                            label={{kraFileLabel}}
                            [fileFunction]="updateKraObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="settlors[i] && settlors[i].verified && settlors[i].done">
                    <p class="light-blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                 <!-- <div class="line"></div> -->

                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeSettlor(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ getSettlors().controls.length-1 === i ? removeButtonText : removeSettlorText }}</u>
                            </span>
                            <span class="spinning-global"
                                *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>

            <!-- BUTTTON Add Settlor -->
            <div class="blueStroke row" [ngClass]="{'min-55' : getSettlors().length > 1}">
                <button mat-stroked-button
                    *ngIf="settlors[settlors.length-1] && settlors[settlors.length-1].done === true"
                    [disabled]="!currentRecordVerified || loading || loadingRemove"
                    class="general-stroked-button" type="button" (click)="addNewSettlor()">
                    <span class="button-text">
                        {{getSettlors().length === 1 || settlorForm.valid && currentRecordVerified ? addSettlorText : addButtonText }}
                    </span>
                </button>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="
            loading ? loadingText :
            !settlorFormVisible || currentRecordVerified ? 'Next' :
            pushDocs ? 'Upload documents' :
            !settlorVerified && (
                (settlorType == 0 && settlors[settlors.length-1] && settlors[settlors.length-1].infoDone == true && settlors[settlors.length-1].authDone == false) ||
                (settlorType == 1 && getSettlors().length > 1 )
            )
            ? 'Verify via OTP' :
            (settlorType == 0 && settlors[settlors.length-1] && settlors[settlors.length-1].docsDone == true && !addDesceased ) ?
            'Skip' :
            'Save Details'"

        [formValid]="
            !settlorFormVisible && iSwear.value ? true :
            (settlors[settlors.length-1] && settlors[settlors.length-1].done && settlors[settlors.length-1].done == true) ? true :
            settlorForm.valid ? true : false
        "

        [showContinue]="settlorFormVisible ? 'false' : settlorForm.valid ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"

        [buttonClickHandler]="
            settlorFormVisible ?
            (
                ( settlorType == 0 && !addDesceased && settlors[settlors.length-1].docsDone && !settlors[settlors.length-1].done ) ?
                skipAddPrincipal.bind(this) :
                submitForm.bind(this)
            )
            : showSettlorForm.bind(this)
        ">
    </app-form-bottom>
</form>
