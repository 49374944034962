import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TabControlService } from 'src/app/services/tab-control.service';
import { generatePDF } from 'src/app/util/Helper';

@Component({
  selector: 'app-step3-preview',
  templateUrl: './step3-preview.component.html',
  styleUrls: ['./step3-preview.component.scss']
})
export class Step3PreviewComponent {
  @ViewChild('pdfContent', { static: false }) pdfContent?: ElementRef;

  title: string = 'Preview your Information.';
  subtitle: string = 'Please review the information you have entered and make any changes by clicking the edit information button';

  settlorsTitle: string = 'Settlors';
  assetDistroTitle: string = 'Asset Distribution';
  editText: string = 'Edit Information';
  
  record: any;
  recordType: 'Settlor' | 'Asset' = 'Settlor';
  recordIndex: number = 0;
  isPopupVisible = false;
  
  loading: boolean = false;

  constructor(private tabControlService: TabControlService,private _formBuilder: FormBuilder,) {}

  get settlors(): any {
    return this.tabControlService.settlorsEstate;
  }

  get assetDistribution(): any {
    return this.tabControlService.assetDistribution;
  }


  showPopup(): void {
    this.isPopupVisible = true;
  }

  editRecord(recordType: 'Settlor' | 'Asset' , recordIndex: number): void {
    this.record = this.settlors[recordIndex];
    if (recordType === 'Asset') this.record = this.assetDistribution;

    this.recordType = recordType;
    this.recordIndex = recordIndex;

    this.showPopup();
  }

  async captureSummary(): Promise<void> {
    this.tabControlService.isLoading(true);
    this.loading = true;
    try {
      const element = this.pdfContent?.nativeElement;
      // const trustName = this.trustInfo.TrustName;
      const summary = await generatePDF(element, `Estate-${this.tabControlService.TrustID}`);

      if (summary === 'error') {
        console.log('Summary not captured');
      } else {
        await this.tabControlService.updateRecordEstate(3, summary);
        this.tabControlService.page3DoneEstate = true;
        await this.tabControlService.changeTabEstate(4);
      }
    } catch (error) {
      console.log('Error !! ', error);
    }
    this.loading = false;
    this.tabControlService.isLoading(false);
  }

  submitForm(): void {
    this.captureSummary();
  }
}
