import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { getTodayDayOfWeek } from 'src/app/util/Helper';

@Component({
  selector: 'app-step4-schedule',
  templateUrl: './step4-schedule.component.html',
  styleUrls: ['./step4-schedule.component.scss']
})
export class Step4ScheduleComponent {

  title: string = 'Book an Appointment';
  subtitle: string = 'To complete the process, we need to make an appointment with you. Please select the option for you to meet with one of our representatives.';

  appointmentDetails: string = 'Appointment Details';

  option1: string = 'Virtual (Online Meeting)';
  option2: string = 'Physical at our office in Riverside Park';
  option3: string = 'Physical at Your Location (limited to applicants in Nairobi County)';

  dateLabel:string = 'Date';
  dateHint:string = '01/01/1990';
  timeLabel:string = 'Time';
  timeHint:string = 'Enter Time';

  emailLabel:string = 'Email Address';
  emailHint:string = 'Enter Email Address';
  personsLabel:string = 'Number of Persons Expected';
  personsHint:string = 'Enter Number';
  locationLabel:string = 'Location';
  locationHint:string = 'Enter Location';

  requiredFieldString: string = 'Required field';
  
  appointmentOptions: string[] = [this.option1, this.option2, this.option3];
  appointmentOption: string = '';

  virtualOfficeText: string = 'Available meeting hours are 8.00 AM - 6.00 PM';
  selectedVirtualMeetinDay: string = '';


  formErrors: { [key: string]: string } = {
    Date: '',
    Time: '',
    PersonsNumber: '',
    Email: '',
    Location: '',
  };
  validationMessages: { [key: string]: {} } = {
    Date: { required: this.requiredFieldString },
    Time: { required: this.requiredFieldString },
    PersonsNumber: { required: this.requiredFieldString },
    Email: { required: this.requiredFieldString, email: 'Invalid email' },
    Location: { required: this.requiredFieldString },
  };

  // Forms
  virtualForm = this._formBuilder.group({
    Email: ['', [Validators.email, Validators.required]],
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  });

  officeForm = this._formBuilder.group({
    PersonsNumber: ['', Validators.required],
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  });

  locationForm = this._formBuilder.group({
    Location: ['', Validators.required],
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  });
  

  maxDate: Date;
  ampm: 'AM' | 'PM' = 'AM';

  constructor(
    private tabControlService: TabControlService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    const currentDate = new Date();
    const tempDate = new Date(currentDate.getTime());
    tempDate.setFullYear(currentDate.getFullYear() + 1);

    this.maxDate = tempDate;
  }

  ngOnInit(): void {
    console.log(this.settlors);
    if (getTodayDayOfWeek() === 'Saturday') {
      this.virtualOfficeText = 'Available meeting hours are between 9.00 AM - 1.00 PM';
    }

    if (this.virtualForm) {
      this.virtualForm.valueChanges.subscribe((form) => {
        if (form.Date) {
          this.selectedVirtualMeetinDay = this.getDayOfWeek(new Date(form.Date));
          
          if (this.selectedVirtualMeetinDay === 'Saturday') {
            this.virtualOfficeText = 'Available meeting hours are between 9.00 AM - 1.00 PM';
            
          } else {
            this.virtualOfficeText = 'Available meeting hours are between 8.00 AM - 6.00 PM';
          }
        }
      });
    }
  }

  getDayOfWeek(date: Date): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
  }


  get settlors(): any {
    return this.tabControlService.settlorsEstate;
  }
  get contactInfo(): any {
    return this.tabControlService.contactInfoEstate;
  }
  get loading(): boolean {
    return this.tabControlService.loading;
  }
  get minDate() {
    return this.tabControlService.maxDOBDate;
  }

  isFormValid(): boolean {
    let result = false;

    if (this.appointmentOption === this.option1) result = this.virtualForm.valid;
    else if (this.appointmentOption === this.option2) result = this.officeForm.valid;
    else if (this.appointmentOption === this.option3) result = this.locationForm.valid;

    return result;
  }

  handleForm() {
    switch (this.appointmentOption) {
      case this.option1:
        this.virtualForm.get('Email')?.addValidators([Validators.email, Validators.required]);
        this.virtualForm.get('Date')?.addValidators(Validators.required);
        this.virtualForm.get('Time')?.addValidators(Validators.required);

        this.officeForm.reset();
        this.locationForm.reset();

        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
      case this.option2:
        this.officeForm.get('PersonsNumber')?.addValidators(Validators.required);
        this.officeForm.get('Date')?.addValidators(Validators.required);
        this.officeForm.get('Time')?.addValidators(Validators.required);

        this.virtualForm.reset();
        this.locationForm.reset();

        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
      case this.option3:
        this.locationForm.get('Location')?.addValidators(Validators.required);
        this.locationForm.get('Date')?.addValidators(Validators.required);
        this.locationForm.get('Time')?.addValidators(Validators.required);

        this.officeForm.reset();
        this.virtualForm.reset();

        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        break;
      default:
        this.virtualForm.reset();
        this.officeForm.reset();
        this.locationForm.reset();

        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
    }
  }

  timeFunction(value: 'AM' | 'PM'): void {
    this.ampm = value;
  }

  async submitForm(): Promise<void> {
    this.tabControlService.isLoading(true);
    
    try {
      let data;

      switch (this.appointmentOption) {
        case this.option1:
          data = {
            Date: this.virtualForm.get('Date')?.value,
            Time: `${this.virtualForm.get('Time')?.value} ${this.ampm}`,
            Email: this.virtualForm.get('Email')?.value
          };
  
          break;
        case this.option2:
          data = {
            Date: this.officeForm.get('Date')?.value,
            Time: `${this.officeForm.get('Time')?.value} ${this.ampm}`,
            NumberOfPersons: this.officeForm.get('PersonsNumber')?.value
          };

          break;
        case this.option3:
          data = {
            Date: this.locationForm.get('Date')?.value,
            Time: `${this.locationForm.get('Time')?.value} ${this.ampm}`,
            Location: this.locationForm.get('Location')?.value
          };

          break;
        default:
          data = null;
          this.tabControlService.viewToast('error', 'Please select an option and fill in the form');
          break;
      }
      
      if (data == null) {
        return;
      }
      let email;

      if (this.tabControlService.estateType == 0) {
        email = this.settlors[0].Email;
      }
      else email = this.contactInfo.Email;

      data = {...data, ...{
        EmailAddress: email,
        AppointmentType: this.appointmentOption,
        BookingID: this.tabControlService.appointmentID
      }};

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.bookAppointmentEstateURL, data, false);

      if (response.Status === 1) {
        this.tabControlService.viewToast('success', 'Your Appointment has been scheduled', 'We will contact you via your email for more information');
        this.navigateFunction();
        
      } else {
        console.error(response);
        this.tabControlService.viewToast('error', 'Could not save your details', response.Message);
        return;
      }

    } catch (error) {
      console.error (error);
      this.tabControlService.viewToast('error', 'An error Occurred', 'Please try again later');
    }
    this.tabControlService.isLoading(false);
  }

  async navigateFunction(): Promise<void> {
    this.tabControlService.resetLocalEstateData();
    await this.tabControlService.changeTabEstate(5);
  }
}
