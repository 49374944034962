import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-step-summary',
  templateUrl: './step-summary.component.html',
  styleUrls: ['./step-summary.component.scss']
})
export class StepSummaryComponent {
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Your appointment request has been received.';

  text1: string = 'Our representative will contact you within 48 hours in order to confirm your appointment.';
  text2: string = 'Please note that the requested time may be adjusted according to availability and office hours.';
  
  buttonText: string = 'Done';

  email: string = 'Test@gmail.com';


  constructor(
    private tabControlService: TabControlService,
    private router: Router,
  ) {
    
  }

  async goHome(): Promise<void> {
    this.tabControlService.clearServiceRecordsEstate();
    this.tabControlService.clearData(this.tabControlService.storageKeyEstate);
    const nextPage = ``;
    this.router.navigate([nextPage]);
  }
}
