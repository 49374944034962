
<div class="flex container">
    <div class="flex one">
        <div class="flex half-1" style="position: relative;">
          <img src={{MileleLogoPath}} class="milele-logo" alt="logo" (click)="navHome()" />
            
            <div class="flex power-small"> {{ powerdByText }} </div>
        </div>
  
        <div class="flex half-2">
        </div>
    </div>
  
    <div class="flex two">
        <img src={{logoPath}} class="logo" alt="logo" />
    </div>

    <!-- CONTENT -->
    <div class="loading-loading" *ngIf="loading">
        <div class="flex loading-spinner">
        <mat-progress-spinner
            mode="indeterminate"
            color="warn"
            diameter="100"
            strokeWidth="12">
        </mat-progress-spinner>
        </div>
    </div>

    <div class="content-loading" *ngIf="showResumePopup">
        <div class="flex column resume-content">
          <div class="flex resume-row bot">
            <div class="flex warn">
              <img src={{warnPath}} class="warn-img" alt="warning" />
            </div>
            <span class="resume-title">Resume Previous Session</span>
          </div>

          <div class="flex column width-100 center">
            <div class="flex resume-row" style="padding: 5%; border: 0.1px solid lightgray; box-sizing: border-box; border-radius: 5px; width: 90%;">
              <!-- <img src={{arrowPath}} class="arrow-img" alt="arrow-right" /> -->
                
              <span style="font-weight: bolder; color: #B68400;">{{'Step '}}</span>
                <div class="resume-active">
                    {{estateDataHolder.activeTabEstate }}
                </div>
        
                <div style="width: 1px; height: 40px; background-color: #B68400; margin-left: 15px; margin-right: 15px;"></div>
                <span class="resume-text">{{ resumeActiveTextEstate }}</span>
                </div>
        
                <div class="flex column stk-div">
                <div class="flex putton green" (click)="resumeEstate()">
                    {{ 'Continue' }}
                    
                </div>
        
                <div class="flex putton red" (click)="resumeEstate(false)">
                    {{ 'New Session' }}
                </div>
                </div>
          </div>
        </div>
    </div>

    <div class="flex content-holder">
        <div class="flex content-wrapper">
            <div class="tabs-wrapper" *ngIf="showSteps && activeTabEstate !== 5">
                <div class="tabs">
                  <div [@slideIn]="tabsAnimationState[0]" class="tab" [class.active]="activeTabEstate === 1" [ngClass]="{'done' : page1DoneEstate}">
                    <div class="round">
                      1
                    </div>
                    <span style="text-wrap: nowrap; text-overflow: ellipsis; word-break: break-all; overflow: hidden;">
                        {{estateType === 0 ? 'Settlor' : 'Contact Information'}}
                    </span>
                  </div>
                  <div [@slideIn]="tabsAnimationState[1]" class="tab" [class.active]="activeTabEstate === 2" [ngClass]="{'done' : page2DoneEstate}">
                    <div class="round">
                      2
                    </div>
                    {{estateType === 0 ? 'Assets' : 'Schedule'}}
                  </div>

                  <div [@slideIn]="tabsAnimationState[2]" class="tab" [class.active]="activeTabEstate === 3" [ngClass]="{'done' : page3DoneEstate}" *ngIf="estateType == 0">
                    <div class="round">
                      3
                    </div>
                    Preview
                  </div>
                  <div [@slideIn]="tabsAnimationState[3]" class="tab" [class.active]="activeTabEstate === 4" *ngIf="estateType == 0">
                    <div class="round">
                      4
                    </div>
                    Schedule
                  </div>
                  
                </div>
            </div>

            <div class="content" *ngIf="!showSteps">
                <div class="subcontent">
                    <div class="formal">
                        <h1 class="blue" style="margin-top: 40px;">Get Started</h1>
                        <p class="lead local">
                            A settlor is the person or entity that intends to set up the trust and who
                            prescribes the terms and conditions of the Trust. Please select whether you
                            are creating the trust as a settlor or related individual or corporate.
                        </p>
                        <div class="row settlor" style="display: flex; align-items: center; justify-content: center; margin-top: 10px;">
                            <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                                labelOptionA="Settlor"
                                labelOptionB="Related Individual/Corporate"
                                (valueChange)="onOptionChange($event)" >
                            </app-radios>
                        </div>

                        <div style="display: flex; width: 100%; align-items: center; justify-content: center; margin-top: 30px;">
                            <div class="disclaimer">
                                {{disclaimer}}
                            </div>
                        </div>
                
                        <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
                            <mat-checkbox [formControl]="iSwear" >
                                <p style="font-size: larger; display: flex;">
                                    <span style="color: red; margin-right: 8px;">*</span>
                                    I have read and accepted ICEA LION's Online&nbsp;<a href="https://icealion.co.ke/icea-lion-group-privacy-policy/" target="_blank" rel="noopener" style="color: #003764;"><u>Privacy Policy</u></a>
                                </p>
                            </mat-checkbox> 
                        </div>

                        
                    </div>

                    <!-- BOTTOM OF FORM -->
                    <app-form-bottom style="width: 100%;"
                        [text]="'Next'"
                        [formValid]="iSwear.value === true"
                        [showContinue]="'false'"
                        [buttonClickHandler]="showEstateSteps.bind(this)">
                    </app-form-bottom>
                </div>
            </div>
            
            <div class="content" *ngIf="showSteps && estateType == 0 && activeTabEstate !== 5">
                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 1}" [hidden]="activeTabEstate !== 1">
                    <app-step1-settlor class="subcontent"></app-step1-settlor>
                </div>
                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 2}" [hidden]="activeTabEstate !== 2" *ngIf="page1DoneEstate">
                    <app-step2-assets class="subcontent"></app-step2-assets>
                </div>
                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 3}" [hidden]="activeTabEstate !== 3" *ngIf="page2DoneEstate">
                    <app-step3-preview class="subcontent"></app-step3-preview>
                </div>
                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 4}" [hidden]="activeTabEstate !== 4" *ngIf="page3DoneEstate">
                    <app-step4-schedule class="subcontent" ></app-step4-schedule>
                </div>
            </div>

            <div class="content" *ngIf="showSteps && estateType == 1 && activeTabEstate !== 5">
                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 1}" [hidden]="activeTabEstate !== 1">
                    <app-contact-info class="subcontent" ></app-contact-info>
                </div>

                <div class="subcontent" [ngClass]="{'hide-me' : activeTabEstate !== 2}" [hidden]="activeTabEstate !== 2" *ngIf="page1DoneEstate">
                    <app-step4-schedule class="subcontent" ></app-step4-schedule>
                </div>
            </div>
            
            <div class="content" *ngIf="showSteps && activeTabEstate === 5">
                <app-step-summary class="subcontent" ></app-step-summary>
            </div>
        </div>
    </div>

    
</div>