<div class="flex container">
  <div class="flex one">
      <div class="flex half-1" style="position: relative;">
        <img src={{MileleLogoPath}} class="milele-logo" alt="logo" (click)="navHome()" />
          
          <div class="flex power-small"> {{ powerdByText }} </div>
      </div>

      <div class="flex half-2">
      </div>
  </div>

  <div class="flex two">
      <img src={{logoPath}} class="logo" alt="logo" />
  </div>

  <!-- CONTENT -->
  <div class="loading-loading" *ngIf="loading">
    <div class="flex loading-spinner">
      <mat-progress-spinner
        mode="indeterminate"
        color="warn"
        diameter="100"
        strokeWidth="12">
     </mat-progress-spinner>
    </div>
  </div>

  <div class="content-loading" *ngIf="stkLoading">
    <div class="flex popup-content">
      <div class="flex x-div">
        <div *ngIf="stkTimeout" (click)="closeSTKPopup()" style="cursor: pointer;">
            <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
        </div>
      </div>

      <div class="spinn">
        <mat-progress-spinner
          *ngIf="!stkTimeout"
          mode="indeterminate"
          diameter="80"
          strokeWidth="7">
        </mat-progress-spinner>

        <img *ngIf="stkTimeout"src={{mPesaPath}} class="m-pesa" alt="M-Pesa" />
      </div>
      
      <span class="titl" *ngIf="!stkTimeout" >
        {{ 'M-PESA PAYMENT' }}
      </span>

      <span  *ngIf="!stkTimeout" class="stk-text">
        {{ enterPinText }}
      </span>

      <span *ngIf="!requestingSTK && stkTimeout" class="stk-text">
        {{ retryPaymentText }}<br>{{ retryQuestionText }}
      </span>

      <span *ngIf="requestingSTK || (stkMessage !== '' && stkTimeout)" class="stk-text">
        {{ stkMessage }}
      </span>

      <div *ngIf="stkTimeout" class="flex column stk-div">
        <div class="flex putton green" (click)="mpesaPayment(1)">
          {{ 'Yes, Re-confirm the payment' }}
        </div>

        <div class="flex putton red" (click)="mpesaPayment(0)">
          {{ 'No, Request new payment' }}
        </div>
      </div>
      
    </div>
  </div>

  <div class="content-loading" *ngIf="showResumePopup">
    <div class="flex column resume-content">
      <div class="flex resume-row bot">
        <span class="resume-title">Enter OTP</span>
      </div>
      <div class="flex resume-row">
        <span class="resume-text">
          {{ 'Use the OTP provided in your email address (' +otpEmail +' ) to retrieve your saved progress:' }}
        </span>
      </div>

      <form [formGroup]="otpForm">
        <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 30px; margin-top: 30px;">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
        </div>
      </form>

      <div class="flex resume-row buttons">
        <div class="flex putton rend" (click)="closeOTPPopup()">
          {{ 'Close' }}
        </div>
        
        <div class="flex putton ngreen" [ngClass]="{'ngreen-loading': otpLoading}" (click)="verifyOTP()" *ngIf="otpReady">
          {{ otpLoading ? 'Verifying' : 'Verify' }}
          <span class="spin-otp" *ngIf="otpLoading">
            <mat-progress-spinner
              mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
          </span>
        </div>
      </div>
    </div>
  </div>
  
  <div class="flex content-holder" #scroller>
    <div class="flex content-wrapper">
      <div class="tabs-wrapper" *ngIf="activeTab !== 8">
        <div class="tabs">
          <div [@slideIn]="tabsAnimationState[0]" class="tab" [class.active]="activeTab === 1" [ngClass]="{'done' : page1done}">
            <div class="round">
              1
            </div>
            <span style="text-wrap: nowrap; text-overflow: ellipsis; word-break: break-all; overflow: hidden;">{{personalData}}</span>
          </div>
          <div [@slideIn]="tabsAnimationState[1]" class="tab" [class.active]="activeTab === 2" [ngClass]="{'done' : page2done}">
            <div class="round">
              2
            </div>
            {{addSettlor}}
          </div>
          <div [@slideIn]="tabsAnimationState[2]" class="tab" [class.active]="activeTab === 3" [ngClass]="{'done' : page3done}" >
            <div class="round">
              3
            </div>
            {{addBeneficiary}}
          </div>
          <div [@slideIn]="tabsAnimationState[3]" class="tab" [class.active]="activeTab === 4" [ngClass]="{'done' : page4done}" >
            <div class="round">
              4
            </div>
            {{addGuardian}}
          </div>
          <div [@slideIn]="tabsAnimationState[4]" class="tab" [class.active]="activeTab === 5" [ngClass]="{'done' : page5done}" >
            <div class="round">
              5
            </div>
            {{trustInfo}}
          </div>
          <div [@slideIn]="tabsAnimationState[5]" class="tab" [class.active]="activeTab === 6" [ngClass]="{'done' : page6done}" >
            <div class="round">
              6
            </div>
            {{preview}}
          </div>
          <div [@slideIn]="tabsAnimationState[6]" class="tab" [class.active]="activeTab === 7" >
            <div class="round">
              7
            </div>
            {{payment}}
          </div>
        </div>
      </div>

      <div class="content" >
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 1}" [hidden]="activeTab !== 1">
          <app-step1-basic class="subcontent"></app-step1-basic>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 2}" [hidden]="activeTab !== 2" *ngIf="page1done">
          <app-step2-settlor class="subcontent"></app-step2-settlor>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 3}" [hidden]="activeTab !== 3" *ngIf="page2done">
          <app-step3-beneficiary class="subcontent"></app-step3-beneficiary>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 4}" [hidden]="activeTab !== 4" *ngIf="page3done">
          <app-step4-guardian class="subcontent" (scrollUp)="scroll($event)" ></app-step4-guardian>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 5}" [hidden]="activeTab !== 5" *ngIf="page4done">
          <app-step5-trust-info class="subcontent"></app-step5-trust-info>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 6}" [hidden]="activeTab !== 6" *ngIf="page5done">
          <app-step6-preview class="subcontent"></app-step6-preview>
        </div>
        <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 7}" [hidden]="activeTab !== 7" *ngIf="page6done">
          <app-step7-payment class="subcontent"></app-step7-payment>
        </div>
        <!-- <div class="subcontent" *ngIf="activeTab === 8">
          <app-step8-complete class="subcontent"></app-step8-complete>
        </div> -->
      </div>

    </div>
    
<!--     
    <div class="bottom-chat">
      <div class="flex button chat-us">
        <img src={{forumPath}} style="width: 24px; height: 24px; margin-right: 10px;" alt="logo" />
        {{ chatUsText }}
      </div>
    </div> -->

    
  </div>

</div>
  