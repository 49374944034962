import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../constants/constants";
import { ApiService } from 'src/app/services/api/api.service';
import { CurrencyPipe } from '@angular/common';
import { SessionData, decodeSessionData, extractNumericValue, getValueByIdOrDescription } from 'src/app/util/Helper';
import { ActivatedRoute } from '@angular/router';
import { error } from 'console';

@Component({
  selector: 'app-step5-trust-info',
  templateUrl: './step5-trust-info.component.html',
  styleUrls: ['./step5-trust-info.component.scss']
})
export class Step5TrustInfoComponent {

  // Url Params
  session: string = ""; // used to resume session
  sessionData: SessionData | string = "error";

  checkerPath: string = '../../../../assets/fi_check.png';
  BranchPlaceHolders: any = [];

  title: string = 'Trust Information.';
  subtitle: string = 'Enter additional information below';

  upkeepText: string = 'Upkeep Details';
  upkeepSubtext: string = 'Enter details of the upkeep of beneficiaries including the amount, frequency, and which bank account the money should be paid out to';
  additionalInfoText: string = 'Additional Information';

  trustNameLabel: string = 'Proposed Name of Trust';
  trustNameHint: string = 'Enter the trust name';
  trustNameSubtext: string = 'You can change the name of the Trust';

  fundSourceLabel: string = 'Source of Funds';
  fundSourceHint: string = 'Select source of funds';

  // These 2 are dependent on the upper 2
  otherSourceLabel: string = 'Other Source';
  otherSourceHint: string = 'Please specify other source';

  upkeepAmountLabel: string = 'Amount of Upkeep';
  upkeepAmountHint: string = 'Enter amount of upkeep';

  frequencyUpkeepLabel: string = 'Frequency of Upkeep';
  frequencyUpkeepHint: string = 'Select the frequency of upkeep';

  bankLabel: string = 'Bank Name';
  bankHint: string = 'Select Bank';

  bankBranchLabel: string = 'Bank Branch';
  bankBranchHint: string = 'Select Bank Branch';

  accNameLabel: string = 'Account Name';
  accNameHint: string = 'Enter Account Name';

  accLabel: string = 'Account Number';
  accHint: string = 'Enter Account Number';

  instructionsLabel: string = 'Who should give us instructions?';
  instructionsSubtext: string = 'All instructions to ICEA LION Trust Co. regarding this account must be given by'
  selectText: string = 'Select one';

  signingArrangementLabel: string = 'Special signing arrangement (please specify)';
  referreLabel: string = 'Who Introduced you to Milele Trust';
  referreeOtherHint: string = 'Please tell us more';
  referreeOtherLabel: string = 'Other source';
  agentLabel: string = 'Name of Agent';
  agentHint: string = 'Enter Name';

  requiredFieldString: string = 'Required field';

  addText: string = 'Trust details saved';
  updateText: string = 'Trust details updated';

  // Dropdown Select Flags
  loadingBanks: boolean = false;
  loadingBankBranches: boolean = false;
  loadingFundSourceOptions: boolean = false;
  loadingFrequencyOptions: boolean = false;
  loadingInstructorOptions: boolean = false;
  loadingPreferredOptions: boolean = false;

  banksEmpty: boolean = true;
  bankBranchesEmpty: boolean = true;
  fundSourceOptionsEmpty: boolean = true;
  frequencyOptionsEmpty: boolean = true;
  instructorOptionsEmpty: boolean = true;
  preferredOptionsEmpty: boolean = true;

  refValue: string = '';

  loading: boolean = false;
  loadingText: string = 'Updating details';

  infoDone: boolean = false;
  upkeepDone: boolean = false;


  formErrors: { [key: string]: string } = {
    trustName: '',
    fundSource: '',
    otherSource: '',
    upkeepAmount: '',
    upkeepFrequency: '',
    bank: '',
    bankBranch: '',
    accName: '',
    acc: '',
    instructor: '',
    signingArrangement: '',
    contactMode: '',
    referree: '',
    agentName: '',
  };
  validationMessages: { [key: string]: {} } = {
    trustName: { required: this.requiredFieldString },
    fundSource: { required: this.requiredFieldString },
    otherSource: { required: this.requiredFieldString },
    upkeepAmount: { required: this.requiredFieldString },
    upkeepFrequency: { required: this.requiredFieldString },
    bank: { required: this.requiredFieldString },
    bankBranch: { required: this.requiredFieldString },
    accName: { required: this.requiredFieldString },
    acc: { required: this.requiredFieldString },
    instructor: { required: this.requiredFieldString },
    signingArrangement: { required: this.requiredFieldString },
    contactMode: { required: this.requiredFieldString },
    referree: { required: this.requiredFieldString },
    agentName: { required: this.requiredFieldString },
  };

  trustForm = this._formBuilder.group({
    trustName: ['', Validators.required],
    fundSource: ['', Validators.required],
    otherSource: [''],
    
    upkeepAmount: [''],
    upkeepFrequency: [''],
    bank: [this.BanksOptions.length === 0 ? '' : this.BanksOptions[0].Description],
    bankBranch: [{value: '', disabled: true}],
    accName: [''],
    acc: [''],
    instructor: [''],
    signingArrangement: [''],
    
    referree: [''],
    agentName: [''],
    other: ['']
  });

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private currencyPipe: CurrencyPipe,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    if (this.tabControlService.activeTab == 5) {
      this.populateData();
    }
    
    // Patch Existing data
    if (Object.keys(this.trustInfo).length !== 0) {
      const trustInfo = this.trustInfo;

      let fSource = trustInfo.SourceOfFunds;

      if (fSource && typeof fSource === 'string') {
        fSource = [];
        fSource.push(trustInfo.SourceOfFunds);
      }

      this.trustForm = this._formBuilder.group({
        trustName: [trustInfo.TrustName || '', Validators.required],
        fundSource: [fSource || '', Validators.required],
        otherSource: [trustInfo.OtherSource || ''],
        
        upkeepAmount: [trustInfo.UpkeepAmount || ''],
        upkeepFrequency: [trustInfo.UpkeepFrequency || ''],
        bank: [trustInfo.Bank || (this.BanksOptions.length === 0 ? '' : this.BanksOptions[0].Description)],
        bankBranch: [trustInfo.BankBranch || {value: '', disabled: true}],
        accName: [trustInfo.AccountName || ''],
        acc: [trustInfo.AccountNo || ''],
        instructor: [trustInfo.AccountMandate || ''],
        signingArrangement: [trustInfo.SigningArrangement || ''],
        
        referree: [trustInfo.HowDidYouHearUS || ''],
        agentName: [trustInfo.AgentName || ''],
        other: [trustInfo.OtherHear || '']
      });

      if (this.trustForm.value.referree && this.trustForm.value.referree !== '') {
        this.checkIntermediary();
      }

      if (trustInfo.TrustName && trustInfo.TrustName != '' && trustInfo.SourceOfFunds && trustInfo.SourceOfFunds.length > 0) {
        this.infoDone = true;

        if (this.arraysContainValue(this.beneficiaries, 'Upkeep')) {
          if (trustInfo.AccountNo && trustInfo.AccountNo !== '') {
            this.upkeepDone = true;
          } else {
            this.upkeepDone = false;

            this.validationService.addOrRemoveValidator(true, this.trustForm.get('upkeepFrequency')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('bank')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('bankBranch')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('accName')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('acc')!);
          }
        } else {
          this.upkeepDone = true;
        }

        if (this.upkeepDone) {
          this.validationService.addOrRemoveValidator(true, this.trustForm.get('instructor')!);
          this.validationService.addOrRemoveValidator(true, this.trustForm.get('referree')!);
        }
      }

    }

    this.route.queryParams.subscribe((params) => {
      // console.log('Params---> ', params); // log route params

      if (params["session"] != undefined) {
        this.session = params["session"];
        const sessionData = decodeSessionData(this.session);

        if (this.isSessionData(sessionData) && sessionData.Referree) {
          this.sessionData = sessionData;
          if (sessionData.Referree.Type && sessionData.Referree.Code) {
            if (this.trustForm) {
              if (sessionData.Referree.Type == 'agent') {
                this.refValue = this.HearAboutusData[2];

              } else if (sessionData.Referree.Type == 'fubro') {
                  this.refValue = this.HearAboutusData[3];

              } else if (sessionData.Referree.Type == 'tla') {
                this.refValue = this.HearAboutusData[1];

              } else if (sessionData.Referree.Type == 'con') {
                this.refValue = this.HearAboutusData[0];
              }

              this.trustForm.get('referree')?.setValue(this.refValue);
              this.checkIntermediary();
            }
            this.tabControlService.viewToast('info', 'Referral details updated'); 
          }
        }
      }
    });

    this.trustForm.get('fundSource')?.valueChanges.subscribe((data) => {
      if (this.arrayContainsValue(data, 'Other'))
        this.validationService.addOrRemoveValidator(true, this.trustForm.get('otherSource')!);
      else
        this.validationService.addOrRemoveValidator(false, this.trustForm.get('otherSource')!);
    });

    this.trustForm.get('referree')?.valueChanges.subscribe((data) => {

      if (this.session !== "") {
        try {
          const sessionData = decodeSessionData(this.session);
  
          if (this.isSessionData(sessionData) && sessionData.Referree && sessionData.Referree.Type && sessionData.Referree.Code
              && this.trustForm.get('referree')?.value !== this.refValue) {
  
            this.trustForm.get('referree')?.setValue(this.refValue);
            this.tabControlService.viewToast('info', 'This session contains a referral from the agent')
          }
        } catch (ex) {console.log('error ? ', ex)};
      }
      
      this.checkIntermediary();
    });

    this.validationService.addOrRemoveValidationOnValue(
      this.trustForm.get("instructor")!,
      "signingArrangement",
      this.trustForm,
      "Special signing arrangement"
    );

    this.validationService.addOrRemoveValidationOnValue(
      this.trustForm.get("referree")!,
      "agentName",
      this.trustForm,
      "Independent Agent"
    );
    this.validationService.addOrRemoveValidationOnValue(
      this.trustForm.get("referree")!,
      "agentName",
      this.trustForm,
      "Broker"
    );
    this.validationService.addOrRemoveValidationOnValue(
      this.trustForm.get("referree")!,
      "agentName",
      this.trustForm,
      "ICEA LION Financial Advisor"
    );
    this.validationService.addOrRemoveValidationOnValue(
      this.trustForm.get("referree")!,
      "agentName",
      this.trustForm,
      "ICEA LION Trust Consultant"
    );

    this.trustForm.valueChanges.subscribe((form) => {
      if (form.upkeepAmount) {
        this.trustForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          upkeepAmount: this.currencyPipe.transform(form.upkeepAmount.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
    
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.trustForm,
      this.formErrors,
      this.validationMessages
    );
  }

  async populateData(): Promise<void> {
    if (this.FundSourceOptions.length === 0) {
      await this.getFundSourceList().then(()=> {
        if (this.FundSourceOptions.length === 0) this.tabControlService.viewToast('error', 'Failed to load some data');
      });
    }
    if (this.FrequencyOptions.length === 0) {
      await this.getUpkeepFrequencyList().then(()=> {
        if (this.FrequencyOptions.length === 0) this.tabControlService.viewToast('error', 'Failed to load some data');
      });
    }
    if (this.BanksOptions.length === 0) {
      await this.getBanksList().then(()=> {
        if (this.BanksOptions.length === 0) this.tabControlService.viewToast('error', 'Failed to load some data');
      });
    }
    if (this.InstructorOptions.length === 0) {
      await this.getInstructorList().then(()=> {
        if (this.InstructorOptions.length === 0) this.tabControlService.viewToast('error', 'Failed to load some data');
      });
    }
  }

  isSessionData(obj: any): obj is SessionData {
    return (
      typeof obj === 'object'
    );
  }

  async checkIntermediary(): Promise<void> {
    const data = this.trustForm.get('referree')?.value;

    if (data) {
      if (data.toLowerCase() == 'icea lion trust consultant') {
        await this.tabControlService.getConsultants();

      } else {
        if (data.toLowerCase() == 'independent agent') {
          await this.tabControlService.getIntermediaries('agent');

        } else if (data.toLowerCase() == 'broker') {
          await this.tabControlService.getIntermediaries('Fubro');
          
        } else if (data.toLowerCase() == 'icea lion financial advisor') {
          await this.tabControlService.getIntermediaries('tla');
        }
      }

      if (this.isSessionData(this.sessionData)) {
  
        if (this.sessionData.Referree) {
          if (this.sessionData.Referree.Type && this.sessionData.Referree.Code) {
  
            if (this.IntermediaryOptions.length > 0) {
              // this.trustForm.get('referree')?.disable();
              this.trustForm.get('agentName')?.setValue(
                getValueByIdOrDescription(this.sessionData.Referree.Code, this.IntermediaryOptions, 0)
              );
              this.trustForm.get('agentName')?.disable();
            }
          }
        }
      }
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  arraysContainValue(arr: any[] | null | undefined, value: string) {
    let exists = false

    if (arr)
      arr.forEach(element => {
        if (element.TrustPurpose && element.TrustPurpose.length > 0) {
          if (this.arrayContainsValue(element.TrustPurpose, value)) exists = true;
        }
      });

      return exists;
  }

  get trustInfo(): any {
    return this.tabControlService.trustInfo;
  }

  get BanksOptions(): any {
    return this.tabControlService.BanksOptions;
  }
  get FundSourceOptions(): any {
    return this.tabControlService.FundSourceOptions;
  }
  get FrequencyOptions(): any {
    return this.tabControlService.UpkeepFrequencyOptions;
  }

  get InstructorOptions(): any {
    return this.tabControlService.InstructorOptions;
  }
  get HearAboutusData(): any {
    return this.tabControlService.HearAboutusData;
  }

  get beneficiaries(): any {
    return this.tabControlService.beneficiaries;
  }

  get loadingIntermediaries(): boolean {
    return this.tabControlService.loadingIntermediaries;
  }
  get IntermediaryOptions(): any {
    return this.tabControlService.IntermediaryOptions;
  }

  get page5Done(): boolean {
    return this.tabControlService.page5Done;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  async getBanksList() {
    this.loadingBanks = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.banksURL
      );
  
      if (response.Status === 1) {
        this.tabControlService.BanksOptions = response.Data;
        // this.trustForm.get('bank')?.enable();
        this.banksEmpty = false;
      } else {
        console.log('>>> Error !! ', response.Message);
        this.banksEmpty = true;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingBanks = false;
  }
  async populateBankBranchesList() {
    this.loadingBankBranches = true;
    this.BranchPlaceHolders = [];
    
    try {
      const bankValue = this.trustForm.get('bank')?.value;
  
      if (!(bankValue === null || bankValue ==='' || bankValue === undefined)) {
        
        const branchRequest = {
          BankID: `${bankValue}`,
        };
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bankBranchesURL,
          branchRequest
        );

        if (response.Status === 1) {
          this.BranchPlaceHolders = response.Data;
          this.trustForm.get('bankBranch')?.enable();
          this.bankBranchesEmpty = false;
        } else {
          console.log('>>> Error !! ', response.Message);
          this.bankBranchesEmpty = true;
        }
        this.loadingBankBranches = false;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingBankBranches = false;
  }

  async getUpkeepFrequencyList() {
    this.loadingFrequencyOptions = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.upkeepURL
      );

      if (response.Status === 1) {
        this.tabControlService.UpkeepFrequencyOptions = response.Data;
        this.trustForm.get('upkeepFrequency')?.enable();
        this.frequencyOptionsEmpty = false;
      } else {
        console.log('>>> Error !! ', response.Message);
        this.frequencyOptionsEmpty = true;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingFrequencyOptions = false;
  }
  
  async getFundSourceList() {
    this.loadingFundSourceOptions = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.fundSourceURL
      );

      if (response.Status === 1) {
        const arr = [...response.Data, {Description: 'Other', ID: 'X'}];;
        this.tabControlService.FundSourceOptions = arr;
        this.trustForm.get('fundSource')?.enable();
        this.fundSourceOptionsEmpty = false;
      } else {
        console.log('>>> Error !! ', response.Message);
        this.fundSourceOptionsEmpty = true;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }

    
    this.loadingFundSourceOptions = false;
  }
  async getInstructorList() {
    this.loadingInstructorOptions = true;
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.instructorOptURL
      );
  
      if (response.Status === 1) {
        const arr = [...response.Data, {Description: 'Special signing arrangement', ID: 'X'}];;
        this.tabControlService.InstructorOptions = arr;
        this.trustForm.get('instructor')?.enable();
        this.instructorOptionsEmpty = false;
      } else {
        console.log('>>> Error !! ', response.Message);
        this.instructorOptionsEmpty = true;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    
    this.loadingInstructorOptions = false;
  }
  

  organizeValues(items: any): string {
    try {
      let output: string = items[0];
      for (let i=1; i<items.length; i++) {
        output = output + ', ' +items[i];
      }
      // console.log('Final output : ', output);
      return output;
    } catch (error) {
      console.log('Error', error);
      return '';
    }
  }

  async submitForm(): Promise<void> {
    this.loading = true;
    this.tabControlService.isLoading(true);

    try {
      
      let pushAgent: boolean = false;

      if (this.trustForm.get('referree')?.value === 'Independent Agent' ||
        this.trustForm.get('referree')?.value === 'Broker' ||
        this.trustForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
        this.trustForm.get('referree')?.value === 'ICEA LION Trust Consultant') {

        pushAgent = true;
      }

      const originalFunds = this.trustForm.controls['fundSource'].value;
      let funds = this.organizeValues(originalFunds);
      let otherSource = '';

      if (this.trustForm.value.otherSource &&
        this.trustForm.value.otherSource.length > 0) {
        funds = funds + ': ' +this.trustForm.value.otherSource;
        otherSource = this.trustForm.value.otherSource;
      }

      let data = {
        TrustName: this.trustForm.controls['trustName'].value,
        SourceOfFunds: funds,
        UpkeepAmount: this.trustForm.controls['upkeepAmount'].value === '' ? '0' : extractNumericValue(this.trustForm.controls['upkeepAmount'].value),
        UpkeepFrequency: this.trustForm.controls['upkeepFrequency'].value,
        Bank: this.trustForm.get('bankBranch')?.touched ? this.trustForm.controls['bank'].value : '',
        BankBranch: this.trustForm.controls['bankBranch'].value,
        AccountName: this.trustForm.controls['accName'].value,
        AccountNo: this.trustForm.controls['acc'].value,
        AccountMandate: this.trustForm.controls['instructor'].value === 'Special signing arrangement' ? this.trustForm.controls['signingArrangement'].value : this.trustForm.controls['instructor'].value,
        HowDidYouHearUS: this.trustForm.controls['referree'].value === 'Other' ? this.trustForm.controls['other'].value : this.trustForm.controls['referree'].value,
      };

      if (pushAgent && this.trustForm.controls['agentName'].value && this.trustForm.controls['agentName'].value !== '') {
        data = {...data, ...{
          AgentCode: getValueByIdOrDescription(this.trustForm.controls['agentName'].value, this.IntermediaryOptions, 2),
          AgentName: this.trustForm.controls['agentName'].value,
          AgentPhoneNo: getValueByIdOrDescription(this.trustForm.controls['agentName'].value, this.IntermediaryOptions, 1),
        }}
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveTrustURL, {...data, ...{TrustID: this.tabControlService.TrustID?.toString()}});

      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        let tsMessage = this.addText;
        this.tabControlService.updateRecord(5, data);


        if (!this.infoDone) {
          this.infoDone = true;

          if (this.arraysContainValue(this.beneficiaries, 'Upkeep')) {
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('upkeepFrequency')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('bank')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('bankBranch')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('accName')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('acc')!);

          } else {
            this.upkeepDone = true;
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('instructor')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('referree')!);
          }

        } else {
          tsMessage = this.updateText;
          if (!this.upkeepDone) {
            this.upkeepDone = true;
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('instructor')!);
            this.validationService.addOrRemoveValidator(true, this.trustForm.get('referree')!);

          } else {
            let currentInfo = this.tabControlService.trustInfo;

            currentInfo.SourceOfFunds = originalFunds;
            currentInfo.AccountMandate = this.trustForm.value.instructor;
            currentInfo.HowDidYouHearUS = this.trustForm.get('referree')?.value;

            let signArrngement = '-';

            if (this.trustForm.value.signingArrangement !== null
                && this.trustForm.value.signingArrangement !== undefined
                && this.trustForm.value.signingArrangement.length > 1) {
                  
              signArrngement = this.trustForm.value.signingArrangement;
            }

            currentInfo = {...currentInfo, OtherSource: otherSource, OtherHear: this.trustForm.controls['other'].value, SigningArrangement: signArrngement}
            this.tabControlService.updateRecord(5, currentInfo);

            // console.log('Navigating...', data);
            this.tabControlService.page5Done = true;
            await this.tabControlService.changeTab(6);
          }
        }

        this.tabControlService.viewToast('success', tsMessage);
        
      } else {
        this.tabControlService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    this.tabControlService.isLoading(false);
    this.loading = false;
  }

  async updateProgress(): Promise<void> {
    await this.submitForm();
    this.saveProgress();
  }
}
