<div [formGroup]="formGroup" class="input-form" >
    <div class="label" [@bounceIn]>
        <p>{{label}}</p>&nbsp;
        <span *ngIf="required === 'true'" style="color: red;">*</span>

        <span class="required-error-upper" *ngIf="subtext !== '' && required === 'true' && !inputTouched && inputType !== 'time' && formControl?.value === '' " >
            Required field
        </span>

        <span class="required-error-upper" *ngIf="inputType == 'time'&& formControl?.value === '' " >
            Required field
        </span>

        <span class="file-types" *ngIf="inputType === 'file'">
            &#8729; {{ 'PDF | JPG' }}
        </span>
    </div>
    
    <mat-form-field *ngIf="inputType === 'normal'"
        appearance="outline" [ngClass]="{'input': inputFocused}" [@fadeIn]>

        <input matInput
            formControlName={{controlName}}
            [placeholder]="hint"
            [type]="type"
            maxlength="{{controlName === 'PercShare' ? '3' : '50'}}"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()">
        <mat-hint *ngIf="subtext !== ''" class="hints">
            {{subtext}}
        </mat-hint>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="subtext === '' && required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'area'"
        appearance="outline" [ngClass]="{'input': inputFocused}" [@fadeIn]>

        <textarea matInput formControlName={{controlName}} [placeholder]="hint"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()">
        </textarea>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'select'"
        appearance="outline" [ngClass]="{'input': inputFocused, 'select-loading' : loadingOptions, 'max-160' : isTitle()}" [@fadeIn]>

        <mat-select formControlName={{controlName}}  panelClass="form-select-popup"
            [placeholder]="hint"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            (selectionChange)="onSelectionChange()" >
            
            <mat-option class="form-option"
                *ngFor="let arrayItem of ArrayItems"
                [value]="isLocalInput() ? arrayItem :
                    isTitle() ? arrayItem.Title :
                    isCountry() ? arrayItem.CountryName :
                    isCounty() ? arrayItem.CountyName :
                    isNationality() ? arrayItem.Nationality :
                    isBank() || isBankBranch() ? arrayItem.ID :
                    arrayItem.Description "
                >

                {{ isLocalInput() ? arrayItem :
                    isTitle() ? arrayItem.Title :
                    isCountry() ? arrayItem.CountryName :
                    isCounty() ? arrayItem.CountyName :
                    isNationality() ? arrayItem.Nationality :
                    arrayItem.Description
                }}
            </mat-option>
        </mat-select>
        <span matSuffix *ngIf="loadingOptions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
        </span>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>
 
    <mat-form-field *ngIf="inputType === 'multi-select'"
        appearance="outline" [ngClass]="{'input': inputFocused, 'select-loading' : loadingOptions}" [@fadeIn]>

        <mat-select multiple formControlName={{controlName}} style="resize: none; text-overflow: ellipsis;" panelClass="form-select-popup"
            [placeholder]="hint"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            (selectionChange)="onSelectionChange()" >
            
            <mat-option class="form-option"
                [value]="isLocalInput() ? arrayItem  : controlName === 'GuardianBens' ? arrayItem.ID : arrayItem.Description "
                *ngFor="let arrayItem of ArrayItems">
                
                {{isLocalInput() ? arrayItem : arrayItem.Description}}
            </mat-option>
        </mat-select>
        <span matSuffix *ngIf="loadingOptions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="22" strokeWidth="2"></mat-progress-spinner>
        </span>
        <mat-hint *ngIf="subtext !== ''" class="hints">
            {{subtext}}
        </mat-hint>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'date'"
        class="date-input"
        appearance="outline"
        [ngClass]="{'input': inputFocused}"
        [@fadeIn]
      >
        <input
            *ngIf="!weekdaysOnly"
            matInput
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="picker"
            readonly="true"
            (click)="picker.open()"
            (focus)="onInputFocus()"
            (blur)="onInputBlur()"
            [placeholder]="hint"
            formControlName={{controlName}} 
            />
        <input
         *ngIf="weekdaysOnly"
          matInput
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="picker"
          [matDatepickerFilter]="dateFilter"
          readonly="true"
          (click)="picker.open()"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
          [placeholder]="hint"
          formControlName={{controlName}} 
        />
        <mat-datepicker-toggle matIconPrefix [for]="picker">
            <mat-icon matDatepickerToggleIcon style="margin-bottom: 2px !important;">event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker> </mat-datepicker>
        <mat-error *ngIf="formErrors[controlName]" class="errors">
            {{formErrors[controlName]}}
        </mat-error>

        <span class="required-error" *ngIf="required === 'true' && !inputTouched && formControl?.value === '' " >
            Required field
        </span>
    </mat-form-field>

    <mat-form-field *ngIf="inputType === 'time'"
        class="date-input"
        appearance="outline"
        [ngClass]="{'input': inputFocused}"
        [@fadeIn]
      >
      <div class="time-input-wrapper">
        <input
          matInput
          [value]="time"
          formControlName={{controlName}}
          placeholder="09:00"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
          maxlength="5"
        />
        <mat-select [(value)]="ampm" class="ampm-selector" (selectionChange)="onTimeChange($event)">
          <mat-option value="AM">AM</mat-option>
          <mat-option value="PM">PM</mat-option>
        </mat-select>
      </div>
      <mat-hint *ngIf="subtext !== ''" class="hints">
        {{subtext}}
      </mat-hint>
    </mat-form-field>

    <div *ngIf="inputType === 'file'" class="file-box" [@fadeIn]>
        <div class="hori file" *ngIf="useFileURL" style="justify-content: space-between;">
            <div class="flex saveFileHolder" (click)="openSavedDoc()">
                <div class="flex savedFile">
                    <mat-icon fontIcon="file_open" aria-hidden="false" aria-label="File button"></mat-icon>
                </div>
                <span class="savedFileText"><u>View Saved Document</u></span>
            </div>
            
            <div class="flex changeImage" (click)="enableSelectImage()">
                <mat-icon fontIcon="change_circle" aria-hidden="false" aria-label="File button"></mat-icon>
                <span style="margin-left: 5px;">Change</span>
            </div>
        </div>

        <div class="hori file" *ngIf="!useFileURL">
            <button type="button" mat-raised-button (click)="pdf.click()" class="file-button">
                Browse <span class="file-required" *ngIf="required === 'true' && !fileValid">(required)</span>
                <input type="file" formControlName={{controlName}} (change)="openImageDialog($event)" style="display: none;" #pdf>
            </button>
        
            <p style=" max-width: 150px; margin-left: 20px; margin-right: 20px; width: fit-content; font-weight: 900; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: small; box-sizing: border-box;">
                {{fileName}}
            </p>
        </div>

        <div class="file-hint">
            <b style="color: #8a0000;">2MB</b> size limit
        </div>

    </div>
    
    <form #f="ngForm" *ngIf="inputType === 'phone'" [formGroup]="formGroup" [@fadeIn]>
        <mat-form-field appearance="outline" [ngClass]="{'input': inputFocused}" style="width: 100%;" >
            <ngx-mat-intl-tel-input class="country-selector"
                [preferredCountries]="['ke']"
                [enablePlaceholder]="false"
                [enableSearch]="true"
                name="phone"
                describedBy="phone"
                formControlName={{controlName}}
                (focus)="onInputFocus()"
                (blur)="onInputBlur()"
            ></ngx-mat-intl-tel-input>
            <mat-error *ngIf="f.form.controls[controlName]?.errors?.['required']" class="errors">
                Required field
            </mat-error>
            <mat-error *ngIf="f.form.controls[controlName]?.errors?.['validatePhoneNumber']" class="errors">
                Invalid phone number
            </mat-error>
            <mat-error *ngIf="f.form.controls[controlName]?.errors?.['exist']" class="errors">
                {{
                    activeTab === 3 ? 'This number is tied to an existing beneficiary' :
                    activeTab === 4 ? 'This number is tied to an existing guardian' :
                    activeTab === 2 && settlorType === 0 ? 'This number is tied to an existing authoriser' :
                    activeTab === 2 && settlorType === 1 ? 'This number is tied to an existing settlor' :
                    'This number is tied to an existing individual' 
                }}
            </mat-error>

            <span class="required-error" *ngIf="required === 'true' && !f.form.controls[controlName]?.touched && f.form.controls[controlName]?.value && f.form.controls[controlName]?.value.length <5 " >
                Required field
            </span>
        </mat-form-field>
    </form>
    
</div>

