<form [formGroup]="basicForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue titles-left margin">{{title}}</h1>
        <p class="lead titles-left rocal">{{subtitle}}</p>

        <div class="flex blue-box">
            <p class="blue lead" >
                {{'Contact Person Information'}}
            </p>
            <div class="line-2"></div>
            <div class="row-alt">
                <div class="row-mini">
                    <app-input [formGroup]="basicForm" controlName="Title"
                        label={{titleLabel}} hint={{titleHint}}
                        inputType="select"
                        [loadingOptions]="loadingTitles"
                        [ArrayItems]="TitleOptions"
                        required="false"
                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                    </app-input>

                    <app-input [formGroup]="basicForm" controlName="firstName"
                        label={{fNameLabel}} hint={{fNameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    
                </div>
                
                <div class="row-mini">
                    <app-input [formGroup]="basicForm" controlName="middleName"
                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                <app-input [formGroup]="basicForm" controlName="surname"
                    label={{surnameLabel}} hint={{surnameHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
                
            </div>
            
            <div class="row">
                <app-input [formGroup]="basicForm" controlName="phone"
                    label={{phoneLabel}} inputType="phone"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="basicForm" controlName="email"
                    label={{emailLabel}} hint={{emailHint}} type="email"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>

        <div style="display: flex; width: 100%; align-items: center; justify-content: center;">
            <div class="disclaimer">
                {{disclaimer}}
            </div>
        </div>
        
        
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? loadingText : basicVerified && confirmValidationStatus() ? 'Next' :'Verify via OTP' "
        [formValid]="basicForm.valid"
        showContinue="false"
        [buttonClickHandler]="basicVerified && confirmValidationStatus() ? submitForm.bind(this) : openOtpModal.bind(this)">
    </app-form-bottom>
</form>
