<div class="tab-form">
    <div class="form-80" #pdfContent>
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">{{subtitle}}</p>

        <div class="box-wrap">
            <div class="box">
                <p class="blue lead section-title">
                    {{settlorsTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let settlor of settlors; let i=index">
                    <div *ngIf="settlor.done && settlor.done == true">
                        <div class="row" style="align-items: center; margin-bottom: 10px;">
                            <p class="blue lead">{{ 'Settlor ' + (i+1) }}</p>
                            <!-- <div class="goldStroke">
                                <button class="edit-text" (click)="editRecord('Settlor', i)" mat-stroked-button>{{editText}}</button>
                            </div> -->
                        </div>
    
                        <div class="items" >  
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Mobile Number'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Phone}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Email'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Email}}</b></span>
                                </div>
                            </div>
    
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Title'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Title}}</b></span>
                                </div>
                            </div>
    
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'First Name'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.FirstName}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                    <span class="value"><b>
                                        {{settlor.MiddleName === undefined || settlor.MiddleName === '' ? '-' : settlor.MiddleName}}
                                    </b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.LastName}}</b></span>
                                </div>
                            </div>
    
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.IDNo}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.KraPin}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                    <span class="value"><b>
                                        {{settlor.PostalAddress === undefined || settlor.PostalAddress === '' ? '-' : settlor.PostalAddress}}
                                    </b></span>
                                </div>
                            </div>
                            
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                    <span class="value"><b>{{settlor.Address}}</b></span>
                                </div>
                            </div>
                            
                        </div>
    
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breaks used by jsPDF when generating PDF -->
        <div class="break"></div>

        <div class="box-wrap">
            <div class="box">
                <div class="row" style="align-items: center; margin-bottom: -20px;">
                    <p class="blue lead section-title" >
                    {{assetDistroTitle}}
                    </p>
                    <!-- <div class="goldStroke">
                        <button class="edit-text" (click)="editRecord('Asset', 0)" mat-stroked-button>{{editText}}</button>
                    </div> -->
                </div>
                <div class="line-3" style="margin-top: 10px; margin-bottom: 10px;"></div>

                <div class="items">
                    <div class="item-wrap" *ngIf="assetDistribution.option1 && assetDistribution.option1 == true">
                        <div class="item">
                            <span class="key">{{'Option 1'.toUpperCase()}}</span>
                            <span class="value" ><b>
                                {{ 'Creating a Lifetime Trust' }}
                            </b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="assetDistribution.option2 && assetDistribution.option2 == true">
                        <div class="item">
                            <span class="key">
                                {{ assetDistribution.option1 == true ?
                                'Option 2'.toUpperCase() :
                                'Option 1'.toUpperCase()
                                }}
                            </span>
                            <span class="value" ><b>
                                {{ 'Through the Will' }}
                            </b></span>
                        </div>
                    </div>
                    <div class="item-wrap" *ngIf="assetDistribution.option3 && assetDistribution.option3 == true">
                        <div class="item">
                            <span class="key">{{'Option 2'.toUpperCase()}}</span>
                            <span class="value"><b>
                                {{ 'Creating a Trust Through a Will' }}
                            </b></span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [formValid]="!loading"
        [showContinue]="'false'"
        [text]="loading ? 'Please Wait' : 'Next'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</div>
