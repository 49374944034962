<form [formGroup]="contactForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ contactInfoTitle }}</h1>
        <p class="lead rocal">{{ formSubtitle }}</p>

        <div class="flex blue-box">
            <p class="blue lead" >
                {{ contactInfoTitle }}
            </p>
            <div class="line-2"></div>

            <div class="row half">
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Relationship"
                    inputType="select"
                    [ArrayItems]="RelationOptions"
                    label={{relationLabel}} hint={{relationLabel}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row">
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Phone" label={{phoneLabel}}  inputType="phone"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
    [text]="loading ? 'Saving Details' : 'Save Details'"
        [formValid]="contactForm.valid ? true : false"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
