import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radios',
  templateUrl: './radios.component.html',
  styleUrls: ['./radios.component.scss']
})
export class RadiosComponent {
  @Input()
  labelOptionA!: string;
  @Input()
  labelOptionB!: string;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedA: boolean = true;
  constructor() { }

  onRadioClick(value: boolean): void {
    this.selectedA = value;
    this.valueChange.emit(value);
  }
}
