import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createIDsValidator(checkType: 'ID' | 'PIN' | 'TEL' | 'EMAIL', checkList: AbstractControl, altList: any[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        if (!control || !checkList || !Array.isArray(checkList.value) || !Array.isArray(altList) || altList.length < 1 ) {
            return null;
        }
        const value = control.value;

        if (!value || value.length < 1) {
            return null;
        }

        const index = (checkList as FormArray).controls.indexOf(control.parent!);

        if (index === -1) {
            return null;
        }

        let valueExists: boolean = false;

        for (let i=0; i<altList.length; i++) {
            if (index === altList.length-1) {
                const altListStatus: boolean = altList[i].verified;
                // console.log('index : ', i, ' verified : ', altListStatus);

                if (altListStatus===false) {
                    if (checkType === 'ID') {
                        valueExists = checkList.value.some((val: any) => val.IDNo === value);
                    } else if (checkType === 'PIN') {
                        valueExists = checkList.value.some((val: any) => val.KraPin === value);
                    } else if (checkType === 'TEL') {
                        valueExists = checkList.value.some((val: any) => val.Phone === value);
                    } else if (checkType === 'EMAIL') {
                        valueExists = checkList.value.some((val: any) => val.Email === value);
                    }
                }
            }
        }
        
        return altList[altList.length-1].verified === false && valueExists && index === checkList.value.length-1 ? {exist: true}: null;
    }
}

export function createIDsValidatorEstate(checkType: 'ID' | 'PIN' | 'TEL' | 'EMAIL', checkList: AbstractControl, altList: any[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        if (!control || !checkList || !Array.isArray(checkList.value) || !Array.isArray(altList) || altList.length < 1 ) {
            return null;
        }
        const value = control.value;

        if (!value || value.length < 1) {
            return null;
        }

        const index = (checkList as FormArray).controls.indexOf(control.parent!);

        if (index === -1) {
            return null;
        }

        let valueExists: boolean = false;

        for (let i=0; i<altList.length; i++) {
            if (index === altList.length-1) {

                if (checkType === 'ID') {
                    valueExists = checkList.value.some((val: any) => val.IDNo === value);
                } else if (checkType === 'PIN') {
                    valueExists = checkList.value.some((val: any) => val.KraPin === value);
                } else if (checkType === 'TEL') {
                    valueExists = checkList.value.some((val: any) => val.Phone === value);
                } else if (checkType === 'EMAIL') {
                    valueExists = checkList.value.some((val: any) => val.Email === value);
                }
            }
        }
        
        return valueExists ? {exist: true}: null;
    }
}

export function createAmountAbove5KValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value || value.length < 6) {
            return null;
        }

        const numericValue = parseInt(value.replace(/\D/g,''));

        const isBelow5K: boolean = numericValue < 5000;

        const valueValid = !isBelow5K;

        return !valueValid ? {cMin: true}: null;
    }
}
