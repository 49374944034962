<div class="flex window">
    <div class="flex tall column seg-blue">
        <div class="flex up-bar">
            <!-- <div>
                <div class="mi-trust">&hairsp;milele<br/>{{''}} trust</div>
                <div class="mini-mi-trust">Powered by ICEA LION</div>
            </div> -->
            
            <div class="tr-title">
                Estate Planning
            </div>
            
            <img src={{MileleLogoPath}} class="milele-logo-alt" alt="logo" (click)="navHome()" />

            <!-- <img src={{logoPath}} class="logo web" alt="logo" /> -->
        </div>

        <div class="flex go-back" (click)="goBack()">
            <mat-icon fontIcon="arrow_back" aria-hidden="false" aria-label="Back button"></mat-icon>
            <span style="margin-left: 10px;">Go Back</span>
        </div>

        <div class="flex seg-content in-row">
            <div class="flex column seg-text">
                <span class="wanna">
                    I want to
                </span>
                <span class="ensure-text">
                    "Preserve my assets for distribution
                    to my heirs and secure some assets
                    for gifting my future generation"
                </span>

                <div class="flex ensure-p block" (click)="toggleDescription(1)" *ngIf="!toggleText1">
                    Read More
                    <span class="chevron-down"></span>
                </div>

                <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(1)" *ngIf="toggleText1">
                    <div class="flex less-button">
                        <span class="chevron-up chev-blue"></span>
                        <span style="margin-left: 10px;">Read Less</span>
                    </div>
                </div>
                <span class="ensure-p" *ngIf="toggleText1">
                    A Trust is the most efficient way to preserve assets and administer their orderly
                    transition to your future generations. A trust offers multigenerational protection of
                    assets thereby preserving the founder’s legacy.<br><br>
                    A trust is a secure vehicle that ensures assets are
                    Invested to give competitive returns (in case of cash assets); and
                    protected and effectively managed (in case of non-cash assets) to ensure longevity and
                    sustainability. Such assets can be gifted to selected beneficiaries as per the founders
                    wishes at stipulated future time-lines.
                </span>
                <div class="flex inf-buttons">
                    <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                    <div class="flex white-outline button" (click)="navEstatePlanning()"> {{ startHere }} </div>
                </div>
            </div>
            <div class="flex column seg-image">
                <div class="flex ch-holder" >
                    <img src={{daBabyPath}} class="children" alt="children" />
                </div>
            </div>
        </div>
    </div>

    <div class="flex tall row seg-gray in-row">
        <div class="flex column seg-image">
            <div class="flex ch-holder" style="flex-direction: column; justify-content: flex-end;">
                <div>
                    <img src={{familyPath}} class="children" alt="children" />
                </div>
            </div>
        </div>

        <div class="flex column seg-text" style="margin-bottom: 30px; margin-top: 30px;">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Ensure my family business is
                protected and preserved for
                continued growth while it
                continues to provide benefit to all
                my loved ones. "
            </span>

            <div class="flex ensure-p block border-blue" (click)="toggleDescription(2)" *ngIf="!toggleText2">
                Read More
                <span class="chevron-down chev-blue"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(2)" *ngIf="toggleText2">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText2">
                A family business my be the single largest asset one may have in their estate. The owner
                may wish to see that the family business continues to thrive for may generations after
                them without subdivision while at the same time ensuring that the revenue generated
                continues to benefit the family.<br><br>
                While the distribution of the shareholding my achieve this, the transfer of shares from
                the founder to their heirs will attract some transfer / inheritance taxation, furthermore,
                this may expose the business to external parties should part of the inheritors decide
                dispose off their part of the shareholding.<br><br>
                A trust is the most effective instrument to ensure that a valuable family asset is
                protected, preserved and managed for growth within the intended family environment
                while continuing to provide benefit to multi-generational family members.
            </span>
            <div class="flex inf-buttons">
                <div class="flex blue button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex blue-outline button" (click)="navEstatePlanning()"> {{ startHere }} </div>
            </div>
        </div>
    </div>

    <div class="flex short row seg-blue in-row">
        <div class="flex column seg-text">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                Have a structured way to support
                and ensure continuity
                to my charitable interests
            </span>

            <div class="flex ensure-p block" (click)="toggleDescription(3)" *ngIf="!toggleText3">
                Read More
                <span class="chevron-down"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(3)" *ngIf="toggleText3">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText3">
                You can set up a Charitable Trust for various initiatives such as supporting the community
                development projects, for educational sponsorships, supporting children’s homes among
                others. Periodic statements will be provided to you as the founder to track growth of the
                funds and progress of such projects.
            </span>
            <div class="flex inf-buttons">
                <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex white-outline button" (click)="navEstatePlanning()"> {{ startHere }} </div>
                
            </div>
        </div>

        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{auntPath}} class="children" alt="children" />
            </div>
        </div>
    </div>

    <div class="flex short row seg-gray in-row">
        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{specPath}} class="children" alt="children" />
            </div>
        </div>

        <div class="flex column seg-text">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Ensure that my beneficiaries with
                special needs or/and elderly
                parents are taken care of "
            </span>

            <div class="flex ensure-p block border-blue" (click)="toggleDescription(4)" *ngIf="!toggleText4">
                Read More
                <span class="chevron-down chev-blue"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(4)" *ngIf="toggleText4">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText4">
                Building up a fund can remove the financial strain experienced as a result of medical and
                critical care for such groups. You can set up a Trust Fund for beneficiaries with special
                needs to assist them financially throughout the existence of the trust.<br><br>
                You can also set up a trust to take care of your parents in their old age, to ensure that
                all expenses related to their well-being are taken care of.
            </span>
            <div class="flex inf-buttons">
                <div class="flex blue button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex blue-outline button" (click)="navEstatePlanning()"> {{ startHere }} </div>
            </div>
        </div>
    </div>

    <div class="flex tall row seg-blue in-row">
        <div class="flex column seg-text">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                Protect the assets from a lengthy
                probate process in the unfortunate
                event of my demise and ensure that
                my assets are administered in a
                structured manner as per my wishes
            </span>

            <div class="flex ensure-p block" (click)="toggleDescription(5)" *ngIf="!toggleText5">
                Read More
                <span class="chevron-down"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(5)" *ngIf="toggleText5">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText5">
                A Trust guarantees protection over your assets because trusts are not subjected to the
                probate process (legal process to determine if it is valid or not). All a probate court does is
                to distribute any assets you own under a Trust. This means that your wish as to how your
                assets will be managed for the benefit of your family and dependents will always prevail
                thereby saving your dependants succession wars.<br><br>
                The assets in a trust are administered as per your wishes, e.g for education, upkeep or
                medical purposes. Any instruction outside the scope as described in your trust deed
                would be contravening your (the founder) wishes contained in the maiden instruction on
                formation and operation of the trust.
            </span>
            <div class="flex inf-buttons">
                <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex white-outline button" (click)="navEstatePlanning()"> {{ startHere }} </div>
            </div>
        </div>

        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{dadPath}} class="gal" alt="gal" />
            </div>
        </div>
    </div>

    <div class="flex seg-gray last">
        {{ powererdBy }}
    </div>
</div>

<app-book-call *ngIf="showBookCall" (close)="toggleBookPopupClose(false)" ></app-book-call>
<app-option-popup *ngIf="showEstateOptions" (close)="toggleEstatePopupClose(false)"></app-option-popup>

