import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { getTodayDayOfWeek } from 'src/app/util/Helper';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('bounceIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5)' }),
        animate('300ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
  ],
})
export class InputComponent implements OnInit, OnChanges {
  @Input()
  controlName!: string;
  @Input()
  formGroup!: FormGroup;
  @Input()
  validationMessages: any;
  @Input()
  formErrors: any;

  @Input() loadingOptions: boolean = false;

  @Input()
  selectFunction: (param: any) => void = () => {};
  @Input()
  selectParam: any;

  @Input()
  fileFunction: (param: any) => void = () => {};

  @Input() runSuggestions: boolean = false;
  @Input()
  suggestFunction: (param1: 'ID' | 'NAME', param2: string) => void = () => {};

  @Input() inputType: string = 'normal';  // options : normal / select/ phone / date / area / file

  @Input() useFileURL: boolean = false;
  @Input() fileURL: string | null = null;

  @Input() type: string = 'text';
  @Input() label: string = 'label';
  @Input() hint: string = '';
  @Input() subtext: string = '';
  @Input() required: 'true' | 'false' = 'true';

  @Input() ArrayItems: any = ['Option one', 'Option two'];

  @Input() weekdaysOnly: boolean = false;
  @Input() includeSaturdays: boolean = false;

  @Input() enforceVirtualOfficeHours: boolean = false;
  @Input() enforcePhysicalOfficeHours: boolean = false;
  @Input() selectedVirtualOfficeDay: string = '';

  @Input() maxDate: any = '';
  @Input() minDate: any = '';

  @Input() existingFileName: string = '';
  @Input() timeFunction: (param: any) => void = () => {}
  

  // @Output() file: EventEmitter<any> = new EventEmitter<any>();

  inputFocused: boolean = false;
  inputTouched: boolean = false;
  fileName: string = 'No file chosen';
  fileValid: boolean = false;

  time: string = '';
  ampm: 'AM' | 'PM' = 'AM';
  minHours: number = 8;
  maxHours: number  = 6;

  constructor(
    private validationService: ValidationService,
    private tabControlService: TabControlService
    ) {}

  ngOnInit(): void {
    if (!(this.existingFileName === '' || this.existingFileName === null)) {
      this.fileName = this.existingFileName;
      this.fileValid = true;
    }

    if (this.inputType === 'time') {
      const formControl = this.formGroup.get(this.controlName);
      if (formControl) {
        formControl.valueChanges.subscribe((value) => {
          if (value) {
            const newValue = value.replace(/[^0-9:]/g, '');

            if (newValue.length >=2) {
              const [hours, minutes] = newValue.split(':');
              let formattedHours = parseInt(newValue);
              let formattedMins = minutes;

              if (newValue.length > 3) {
                formattedHours = parseInt(hours);
                
                if (parseInt(minutes) >= 60) {
                  formattedMins = '00';
                }
              }

              if (formattedHours >= 12) {
                if (formattedHours > 12) {
                  formattedHours -= 12;
                } else {
                  formattedHours = 12;
                }
                this.ampm = 'PM';
              } else if (formattedHours === 0) {
                // formattedHours = 12;
                this.ampm = 'AM';
              }
              
              // Enforce Virtual Office Hours
              if (this.enforceVirtualOfficeHours) {
                if (this.selectedVirtualOfficeDay === 'Saturday') {
                  this.minHours = 9;
                  this.maxHours = 1;
                } else {
                  this.minHours = 8;
                  this.maxHours = 6;
                }

                if (this.ampm === 'AM' && formattedHours < this.minHours) {
                  formattedHours = this.minHours;
                  this.ampm = 'AM';
                } else if (this.ampm === 'PM' && formattedHours > this.maxHours && formattedHours != 12) {
                  formattedHours = this.maxHours;
                  this.ampm = 'PM';
                }
              }
              
              // Enforce Physical Office Hours
              if (this.enforcePhysicalOfficeHours) {
                if (this.ampm === 'AM' && formattedHours < 8) {
                  formattedHours = 8;
                } else if (this.ampm === 'PM' &&
                 (
                  formattedHours > 4 ||
                  formattedHours >= 4 &&
                  newValue.length > 3 && parseInt(minutes) > 30
                 )
                ) {
                  if (formattedHours != 12) formattedHours = 4;

                  if (formattedHours == 4 && newValue.length > 3 && parseInt(minutes) > 30) formattedMins = '30';
                }
              }
              
              this.timeFunction(this.ampm);

              if (formattedMins === undefined) {
                formattedMins = '00';
              }
              
              this.time = newValue.length === 2 ?
                `${formattedHours.toString().padStart(2, '0')}:` :
                `${formattedHours.toString().padStart(2, '0')}:${formattedMins}`;
              
              formControl.patchValue(this.time, { emitEvent: false });
            }
          }
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Listen for changes in the formGroup and controlName inputs
    if ('formGroup' in changes && 'controlName' in changes) {
      const formControl = this.formGroup.get(this.controlName);

      // Subscribe to value changes of the form control
      if (formControl) {
        formControl.valueChanges.subscribe((newValue) => {
          // Capitalize the value before patching it back to the form control
          if (formControl.touched) {
            this.inputTouched = true;
          }
          
          if (typeof newValue === 'string') {
            if (this.controlName.toLowerCase() === 'krapin' || this.controlName.toLowerCase() === 'idno') {
              const newValueUpper = newValue.replace(/[a-z]/g, (match) => match.toUpperCase());
              formControl.patchValue(newValueUpper, { emitEvent: false });

              if (this.runSuggestions && this.controlName.toLowerCase() === 'idno') {
                this.suggestFunction('ID', newValue);
              }

            } else {
              if (this.controlName.toLowerCase().includes('email')) {
                const newValueUpper = newValue.toLowerCase();
                formControl.patchValue(newValueUpper, { emitEvent: false });
              } else if (!this.inputType.toLowerCase().includes('select')){
                const newValueUpper = newValue.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()); // To title case
                formControl.patchValue(newValueUpper, { emitEvent: false });
              }

              if (this.runSuggestions && this.controlName.toLowerCase() === ('firstname')) {
                  this.suggestFunction('NAME', newValue);
              }

            }
          }
        });
      }
    }
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.formGroup,
      this.formErrors,
      this.validationMessages
    );
  }

  onInputFocus(): void {
    this.inputFocused = true;
  }

  onInputBlur(): void {
    if (this.controlName === 'PercShare') {
      const percentage = parseInt(this.formControl?.value);
      this.tabControlService.updateRemainingShares(percentage);
    }
    this.inputFocused = false;
    this.inputTouched = true;
    this.logErrors();
  }

  capitalizeOption(option: string): string {
    try {
      const upper = option.toUpperCase();
      return upper;
    } catch(error) {
      console.log('Error capitalizing input');
      return option;
    }
  }

  isPdfOrJpg(fileName: string): boolean {
    const allowedExtensions = ['.pdf', '.jpg', '.jpeg'];
    const ext = fileName.toLowerCase().substr(fileName.lastIndexOf('.'));
  
    return allowedExtensions.includes(ext);
  }

  onSelectionChange() {
    if (this.inputType === 'select') {
      this.selectFunction(this.selectParam); // Trigger the parent component's function
    }
  }

  onFileValid(fileObj: any, base64: string | null): void {
    try {
      const object = {
        name: fileObj.name,
        stringValue: base64
      };
      // console.log('OBJJJ ', fileObj);
      this.fileFunction(object);

    } catch (error) {
      console.log('!! File updater error : ', error);
    }
  }

  get formControl() {
    return this.formGroup.get(this.controlName);
  }

  get activeTab(): number {
    return this.tabControlService.activeTab;
  }
  get settlorType(): number {
    return this.tabControlService.settlorType;
  }

  isLocalInput(): boolean {
    if (
      this.controlName === 'PaymentMode' ||
      this.controlName === 'referree' ||
      this.controlName === 'Category' ||
      this.controlName === 'Gender' ||
      this.controlName === 'HowDidYouHearUS' ||
      this.controlName === 'Relationship'
    ) {
      return true;
    }
    return false;
  }

  isTitle(): boolean {
    if (
      this.controlName === 'Title'
    ) {
      return true;
    }
    return false;
  }

  isCountry(): boolean {
    if (
      this.controlName === 'Country'
    ) {
      return true;
    }
    return false;
  }

  isCounty(): boolean {
    if (
      this.controlName === 'County'
    ) {
      return true;
    }
    return false;
  }

  isNationality(): boolean {
    if (
      this.controlName === 'Nationality'
    ) {
      return true;
    }
    return false;
  }

  isBank(): boolean {
    if (
      this.controlName.toLowerCase() === 'bank'
    ) {
      return true;
    }
    return false;
  }
  isBankBranch(): boolean {
    if (
      this.controlName.toLowerCase() === 'bankbranch'
    ) {
      return true;
    }
    return false;
  }

  // Filter function to disable weekends
  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.

    if (this.includeSaturdays) {
      return day !== 0;
    } else {
      return day !== 0 && day !== 6;
    }
  };

  onTimeChange(event: any): void {
    this.ampm = event.value;
    this.timeFunction(this.ampm);

    const formControl = this.formGroup.get(this.controlName);
    if (formControl) {
      formControl.setValue('');
    }
  }

  openSavedDoc(): void {
    if (this.fileURL) {
      window.open(this.fileURL, "_blank");    
    }
  }

  enableSelectImage(): void {
    this.useFileURL = false;
  }

  openImageDialog(event: any): void {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        //console.log('>>> Reader ', reader);
        const base64String = reader.result ? reader.result.toString().split(',')[1] : null;
        // console.log(base64String);
        
        // Check the file size here
        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB (you can adjust this value)
        const fileSizeInBytes = file.size;
        const fileName = file.name;
        
        if (fileSizeInBytes <= maxSizeInBytes) {
          if (this.isPdfOrJpg(fileName)) {
            this.fileName = fileName;
            this.fileValid = true;
            // Set value to formControl
            this.onFileValid(file, base64String);
            this.formControl?.setValue(base64String);
          } else {
            this.fileName = 'Invalid Format!';
            this.fileValid = false;
            this.formControl?.setValue(null);
            this.tabControlService.viewToast('error', 'Please upload only PDF or JPG files');
          }
        } else {
          // Display an error message or handle the oversized file in some way
          // console.log("Selected document exceeds the maximum size limit.");
          this.fileName = 'File too large!';
          this.fileValid = false;
          this.formControl?.setValue(null);
          this.tabControlService.viewToast('error', 'The attached file is too large', 'The file size should not exceed 2MB');
        }
      };
      reader.readAsDataURL(file);
    }
    catch (error) {
      console.log(error)
    }
  }
  
}
