<form [formGroup]="assetForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ formTitle }}</h1>
        <p class="lead rocal">
            Please select how you want the assets to be distributed:&nbsp;<span style="color: red; font-weight: 800;">(Maximum of two modes)</span>
        </p>

        <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
            <mat-checkbox formControlName="option1" >
                <p style="font-size: larger; display: flex;">
                    <strong>Creating a Trust</strong>&nbsp;(For Private Trust Creator Only)
                </p>
            </mat-checkbox>
        </div>
        <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 5px; font-size: medium; width: 100%;">
            <mat-checkbox formControlName="option2" >
                <p style="font-size: larger; display: flex;">
                    <strong>Through the Will</strong>
                </p>
            </mat-checkbox>
        </div>
        <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 5px; font-size: medium; width: 100%;">
            <mat-checkbox formControlName="option3" >
                <p style="font-size: larger; display: flex;">
                    <strong>Creating a Trust Through a Will</strong>
                </p>
            </mat-checkbox>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? 'Saving Selection' : 'Next'"
        [formValid]="assetForm.valid ? true : false"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
