import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
} from "@angular/forms";
import { Router } from '@angular/router';
import { TabControlService } from 'src/app/services/tab-control.service';
import * as Constants from "../../../constants/constants";
import { trigger, transition, style, animate, state } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  animations: [
    trigger("fade", [
      transition("false=>true",
      [animate("1500ms 1500ms", style({ opacity: 1 }))] ),

      transition("true=>false", [animate("1500ms 1500ms", style({ opacity: 0 }))] )
    ]),
  ],
})
export class StartComponent implements OnInit {

  slideshowImages: string[] = [
    '../../../assets/slideshow/image1.png',
    '../../../assets/slideshow/image2.png',
    '../../../assets/slideshow/image3.png',
    '../../../assets/slideshow/image4.png',
    '../../../assets/slideshow/image5.png',
    '../../../assets/slideshow/image6.png',
    '../../../assets/slideshow/image7.png'
    // Add more image paths as needed
  ];

  mileleTrustURL: string = 'https://icealion.co.ke/milele-trust-2/';
  estatePlanningURL: string = 'https://icealion.co.ke/estate-planning/';
  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/logo_w.png';
  forumPath: string  = '../../../assets/forum.png';

  warnPath: string = '../../../assets/warn.png';
  arrowPath: string = '../../../assets/arrow.png';
  
  // Strings
  mileleTrustFund: string = 'Trust Fund';
  milelePrivateTrust: string = 'Estate Planning';
  legacyProtectText: string = 'You’ve got a legacy to protect';
  legacySubtext: string = 'Create a cash management Trust to protect and provide for you and your loved ones during your lifetime and in the unfortunate event of your demise.';
  secureFutureText: string = 'Secure a future for you and your loved ones';
  secureSubtext: string = 'Decide what’s right for you and your loved ones by creating a customized trust-based estate plan over your property.';
  learnMoreText = 'Learn More';
  takeTestText: string = 'Not sure what’s best? Take a test';
  startHereText: string = 'Start Here';

  needHelpText: string = 'Need more information?';
  bookCallText: string = 'Book A Call';
  brochureText: string = 'Download Brochures';
  existingLoginText: string = 'Existing Client Login';
  chatUsText: string = 'Chat with us. How may we help?';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';
  
  showBookCall: boolean = false;
  showBrochures: boolean = false;

  showEstateOptions: boolean = false;
  
  // Form
  startForm = this._formBuilder.group({
  });

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private tabControlService: TabControlService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.shuffleArray(this.slideshowImages);
    const trustData = await this.tabControlService.getData(this.tabControlService.storageKey);
    if (trustData) {
      this.trustDataHolder = trustData;

      if (trustData.activeTab === 1) this.resumeActiveText = 'Verify Personal Information';
      if (trustData.activeTab === 2) this.resumeActiveText = 'Add Settlor(s)';
      if (trustData.activeTab === 3) this.resumeActiveText = 'Add Beneficiaries';
      if (trustData.activeTab === 4) this.resumeActiveText = 'Add Guardian(s)';
      if (trustData.activeTab === 5) this.resumeActiveText = 'Trust Information';
      if (trustData.activeTab === 6) this.resumeActiveText = 'Preview';
      if (trustData.activeTab === 7) this.resumeActiveText = 'Payment';
    }

    //this.startTimer();
  }

  showResumePopup: boolean = false;
  resumingEstate: boolean = false;
  trustDataHolder: any = null;
  resumeActiveText: string = '';

  count: number = 0;
  toggle: boolean = false;

  onFade(event: any) {
    if (event.fromState) { 
      this.count = (this.count + 1) % this.slideshowImages.length;
    }
    this.toggle = !this.toggle;
  }
  
  shuffleArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  get mainJourney(): 0 | 1 {
    return this.tabControlService.mainJourney;
  }

  viewBrochures(): void {
    this.showBrochures = true;
  }

  openTrustBrochure(): void {
    window.open(`assets/brochures/${Constants.trustFundBrochureName}`, "_blank");
    this.showBrochures = false;
  }

  openFamilyBrochure(): void {
    window.open(`assets/brochures/${Constants.estatePlanningBrochureName}`, "_blank");
    this.showBrochures = false;
  }


  navTrustFundInfo(): void {
    const nextPage = `/info/trust-fund`;
    this.router.navigate([nextPage]);
  }

  navPrivateTrustInfo(): void {
    const nextPage = `/info/estate-planning`;
    this.router.navigate([nextPage]);
  }
  
  checkMileleTrustResume(): void {
    if (this.trustDataHolder !== null) {
      this.resumingEstate = false;
      this.showResumePopup = true;
    } else {
      this.navTrustFund(false);
    }
  }

  async navTrustFund(resume: boolean = false): Promise<void> {
    this.showResumePopup = false;
    if (resume) {
      await this.tabControlService.updateDataFromStorage();
    } else {
      this.tabControlService.resetLocalData();
    }

    const naxPage = `/trust-fund/set-up`;
    this.router.navigate([naxPage]);
  }

  toggleBookPopupClose(value: boolean): void {
    this.showBrochures = false;
    this.showBookCall = value;
  }
  toggleEstatePopupClose(value: boolean): void {
    this.showBrochures = false;
    this.showBookCall = false;
    this.showEstateOptions = value;
  }


}
