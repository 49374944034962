<div class="flex window">
    <div class="flex tall column seg-blue">
        <div class="flex up-bar">
            <!-- <div>
                <div class="mi-trust">&hairsp;milele<br/>{{''}} trust</div>
                <div class="mini-mi-trust">Powered by ICEA LION</div>
            </div> -->

            

            <div class="tr-title">
                Trust Fund
            </div>

            <img src={{MileleLogoPath}} class="milele-logo-alt" alt="logo" (click)="navHome()" />
            
            <!-- <img src={{logoPath}} class="logo web" alt="logo" /> -->
        </div>

        <div class="flex go-back" (click)="goBack()">
            <mat-icon fontIcon="arrow_back" aria-hidden="false" aria-label="Back button"></mat-icon>
            <span style="margin-left: 10px;">Go Back</span>
        </div>
        
        <div class="flex seg-content in-row">
            <div class="flex column seg-text">
                <span class="wanna">
                    I want to
                </span>
                <span class="ensure-text">
                    "Ensure that my children’s
                    education, upkeep and Medicare will
                    be taken care of while I am still alive
                    and / or in the unfortunate event of my demise until they attain the
                    stipulated age."
                </span>

                <div class="flex ensure-p block" (click)="toggleDescription(1)" *ngIf="!toggleText1">
                    Read More
                    <span class="chevron-down"></span>
                </div>

                <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(1)" *ngIf="toggleText1">
                    <div class="flex less-button">
                        <span class="chevron-up chev-blue"></span>
                        <span style="margin-left: 10px;">Read Less</span>
                    </div>
                </div>
                <span class="ensure-p" *ngIf="toggleText1">
                    You can create a trust for the purpose of your children’s current or future education.
                    The fund you build for this purpose will cater for your children’s education while you
                    are still alive or in the unfortunate event of your demise. The structure of the trust will
                    enable you to fund the children’s education kitty either via regular deposits or by a
                    transfer in of lump sum amounts, e.g. from your life insurance maturity / sum assured,
                    sale of an asset, among other sources.<br><br>
                    The funds you place under the Trust are invested to ensure they grow over the lifetime
                    of the Trust, while facilitating your children’s education financing.
                </span>
                <div class="flex inf-buttons">
                    <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                    <div class="flex white-outline button" (click)="navMileleTrust()"> {{ startHere }} </div>
                    
                </div>
            </div>
            <div class="flex column seg-image">
                <div class="flex ch-holder" >
                    <img src={{childrenPath}} class="children" alt="children" />
                </div>
            </div>
        </div>
    </div>

    <div class="flex short seg-gray in-row">
        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{bossGalPath}} class="children" alt="children" />
            </div>
        </div>

        <div class="flex column seg-text">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Ensure proper administration of
                employee benefits (Pension,
                Group Life, Personal Accident,
                e.t.c) payable to minor beneficiaries."
            </span>

            <div class="flex ensure-p block border-blue" (click)="toggleDescription(2)" *ngIf="!toggleText2">
                Read More
                <span class="chevron-down chev-blue"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(2)" *ngIf="toggleText2">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText2">
                Setting up a Trust Fund allows the most effective management of employee benefits
                for the good of bereaved minors by providing financing for their education, upkeep and
                medical expenses. A Trust helps to avoid possible mismanagement of such payable
                benefits by guardians thus helping such minors to enjoy the quality of education and
                care that would have otherwise been provided by the deceased.
            </span>
            <div class="flex inf-buttons">
                <div class="flex blue button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex blue-outline button" (click)="navMileleTrust()"> {{ startHere }} </div>
            </div>
        </div>
    </div>

    <div class="flex tall row seg-blue in-row">
        <div class="flex column seg-text" style="margin-bottom: 20px; margin-top: 10px;">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Ensure that I and my loved ones
                upkeep and medical expenses will
                always be taken care of especially in
                the event of my incapacitation or
                untimely demise"
            </span>

            <div class="flex ensure-p block" (click)="toggleDescription(3)" *ngIf="!toggleText3">
                Read More
                <span class="chevron-down"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(3)" *ngIf="toggleText3">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText3">
                You can form a trust with the main purpose of providing upkeep and facilitating
                medical financing for yourself and your loved ones, this is especially useful in the event
                of your incapacitation or the unfortunate event of your demise. In most cases this is
                better combined with an education Trust.<br><br>
                In addition, well-wishers, may fundraise money to support the family of the deceased.
                Our Milele Trust fund provides a secure way of managing and administering such funds
                for the benefit of the minor beneficiaries. This ensures longevity and accountability of
                the usage of such funds.<br><br>
                This is especially helpful where you need to protect interest of beneficiaries with
                special needs. Building up a fund can remove the financial strain experienced as a
                result of medical and critical care for such groups. You can set up a Trust Fund for
                beneficiaries with special needs to assist them financially throughout the existence of
                the trust. You can also set up a trust to take care of your parents in their old age, to
                ensure that all expenses related to their well-being are taken care of.
            </span>
            <div class="flex inf-buttons">
                <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex white-outline button" (click)="navMileleTrust()"> {{ startHere }} </div>
                
            </div>
        </div>

        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{medicalPath}} class="medical" alt="medical" />
            </div>
        </div>
    </div>

    <div class="flex short row seg-gray in-row">
        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{charityPath}} class="children" alt="children" />
            </div>
        </div>

        <div class="flex column seg-text" style="margin-bottom: 50px; margin-top: 50px;">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Have a structured way to support
                and ensure continuity to my
                charitable interests "
            </span>

            <div class="flex ensure-p block border-blue" (click)="toggleDescription(4)" *ngIf="!toggleText4">
                Read More
                <span class="chevron-down chev-blue"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(4)" *ngIf="toggleText4">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText4">
                You can set up a Charitable Trust for various initiatives such as supporting the
                community development projects, for educational sponsorships, supporting children’s
                homes among others. Periodic statements will be provided to you as the founder to
                track growth of the funds and progress of such projects.
            </span>
            <div class="flex inf-buttons">
                <div class="flex blue button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex blue-outline button" (click)="navMileleTrust()"> {{ startHere }} </div>
            </div>
        </div>
    </div>

    <div class="flex short row seg-blue in-row">
        <div class="flex column seg-text">
            <span class="wanna">
                I want to
            </span>
            <span class="ensure-text">
                "Have a secure and accountable
                avenue of channelling my financial
                support from the Diaspora to my
                family back home"
            </span>

            <div class="flex ensure-p block" (click)="toggleDescription(5)" *ngIf="!toggleText5">
                Read More
                <span class="chevron-down"></span>
            </div>

            <div class="flex" style="flex-direction: row-reverse;" (click)="toggleDescription(5)" *ngIf="toggleText5">
                <div class="flex less-button">
                    <span class="chevron-up chev-blue"></span>
                    <span style="margin-left: 10px;">Read Less</span>
                </div>
            </div>
            <span class="ensure-p" *ngIf="toggleText5">
                Through establishing a trust, you can channel funds for investment and enable a co-
                ordinated disbursement approach to beneficiaries either for education, medical care,
                upkeep or projects. You as the founder (settlor) retains control over assets protected
                within the trust.
            </span>
            <div class="flex inf-buttons">
                <div class="flex white button" style="margin-right: 5%;" (click)="toggleBookPopupClose(true)"> {{ bookCall }} </div>
                <div class="flex white-outline button" (click)="navMileleTrust()"> {{ startHere }} </div>
            </div>
        </div>

        <div class="flex column seg-image">
            <div class="flex ch-holder" >
                <img src={{galPath}} class="gal" alt="gal" />
            </div>
        </div>
    </div>

    <div class="flex seg-gray last">
        {{ powererdBy }}
    </div>
</div>

<app-book-call *ngIf="showBookCall" (close)="toggleBookPopupClose(false)" ></app-book-call>
