<div
    [@toastTrigger]="(tabControlService.showToast | async) ? 'open' : 'close'"
    class = "flex editor-container">
    <div class="flex editor-overlay">
        <div class="flex column toaster"
            [ngClass]="{
                'info': (tabControlService.toastType | async) === 'info',
                'success': (tabControlService.toastType | async) === 'success',
                'error': (tabControlService.toastType | async) === 'error'
            }" #toast>
            <p>{{ tabControlService.toastMessage | async }}</p>
            <p *ngIf="(tabControlService.toastSubMessage | async) !== ''"
            style="margin-top: 0px;">
                {{ tabControlService.toastSubMessage | async}}
            </p>
        </div>
    </div>
</div>
