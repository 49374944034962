import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [    
    trigger('toastTrigger', [ // This refers to the @trigger we created in the template      
      state('open', style({ transform: 'translateX(0%)' })), // This is how the 'open' state is styled      
      state('close', style({ transform: 'translateX(200%)' })), // This is how the 'close' state is styled      
      transition('open <=> close', [ // This is how they're expected to transition from one to the other         
        animate('200ms ease-in-out')
      ])    
    ])  
  ]
})
export class ToastComponent implements OnInit {
  showToast: boolean = false;
  autoDismissTimeout: any;

  constructor(public tabControlService : TabControlService) { }

  ngOnInit(): void {
    this.tabControlService.showToast.subscribe((value) => {
      this.showToast = value;
      if (value) {
        this.autoDismiss();
      } else {
        this.cancelAutoDismiss();
      }
    });
  }

  autoDismiss(): void {
    this.autoDismissTimeout = setTimeout(() => {
      this.dismissToast();
    }, 3500);
  }

  dismissToast(): void {
    this.tabControlService.dismissToast();
  }

  cancelAutoDismiss(): void {
    if (this.autoDismissTimeout) {
      clearTimeout(this.autoDismissTimeout);
    }
  }
}
