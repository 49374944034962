import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TabControlService } from 'src/app/services/tab-control.service';
import { generatePDF } from 'src/app/util/Helper';

@Component({
  selector: 'app-step6-preview',
  templateUrl: './step6-preview.component.html',
  styleUrls: ['./step6-preview.component.scss']
})
export class Step6PreviewComponent {
  @ViewChild('pdfContent', { static: false }) pdfContent?: ElementRef;
  
  title: string = 'Preview your Information.';
  subtitle: string = 'Please review the information you have entered and make any changes by clicking the edit information button';

  personalDataTitle: string = 'Personal Data';
  settlorsTitle: string = 'Settlors';
  bensTitle: string = 'Beneficiaries';
  guardiansTitle: string = 'Guardians';
  trustInfoTtile: string = 'Additional Trust Information';

  editText: string = 'Edit Information';

  termAccepted: boolean = false;

  buttonEnabled: boolean = true;

  isPopupVisible = false;
  record: any;
  recordType: 'Info' | 'Settlor' | 'Guardian' | 'Beneficiary' = 'Settlor';
  recordIndex: number = 0;

  loading: boolean = false;


  // FORM !
  previewForm = this._formBuilder.group({
    iSwear: [false],
  });

  constructor(private tabControlService: TabControlService,private _formBuilder: FormBuilder,) {}

  get basicData(): any {
    return this.tabControlService.basicData;
  }
  get settlors(): any {
    return this.tabControlService.settlors;
  }
  get beneficiaries(): any {
    return this.tabControlService.beneficiaries;
  }
  get guardians(): any {
    return this.tabControlService.guardians;
  }
  get trustInfo(): any {
    return this.tabControlService.trustInfo;
  }
  get activeTab(): number {
    return this.tabControlService.activeTab;
  }

  get settlorType(): 0 | 1 {
    return this.tabControlService.settlorType;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  organizedPurpose: string = '';
  organizedSource: string = '';

  ngOnInit(): void {
    console.log(this.trustInfo)
    // this.organizedPurpose = this.organizeValues(this.trustInfo.TrustPurpose);
    // this.organizedSource = this.organizeValues(this.trustInfo.SourceOfFunds);
  }

  organizeValues(items: any): string {
    try {
      let output: string = items[0];
      for (let i=1; i<items.length; i++) {
        output = output + ', ' +items[i];
      }
      console.log('Final output : ', output);
      return output;
    } catch (error) {
      console.log('Error', error);
      return '';
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  arraysContainValue(arr: any[] | null | undefined, value: string) {
    let exists = false

    if (arr)
      arr.forEach(element => {
        if (element.TrustPurpose && element.TrustPurpose.length > 0) {
          if (this.arrayContainsValue(element.TrustPurpose, value)) exists = true;
        }
      });

      return exists;
  }

  onPopupClose(): void {
    this.isPopupVisible = false;
  }

  showPopup(): void {
    this.isPopupVisible = true;
  }

  editRecord(recordType: 'Info' | 'Settlor' | 'Guardian' | 'Beneficiary', recordIndex: number): void {
    this.record = this.tabControlService.settlors[recordIndex];
    if (recordType === 'Guardian') this.record = this.tabControlService.guardians[recordIndex];
    else if (recordType === 'Beneficiary') this.record = this.tabControlService.beneficiaries[recordIndex];
    else if (recordType === 'Info') this.record = this.tabControlService.trustInfo;

    this.recordType = recordType;
    this.recordIndex = recordIndex;

    this.showPopup();
  }

  async captureSummary(): Promise<void> {
    this.loading = true;
    try {
      const element = this.pdfContent?.nativeElement;
      // const trustName = this.trustInfo.TrustName;
      const summary = await generatePDF(element, this.trustInfo.TrustName);

      if (summary === 'error') {
        console.log('Summary not captured');
      } else {
        this.tabControlService.updateRecord(6, summary);
        console.log('Navigating...');
        this.tabControlService.page6Done = true;
        await this.tabControlService.changeTab(7);
      }
    } catch (error) {
      console.log('Error !! ', error);
    }
    this.loading = false;
  }

  async updateProgress(): Promise<void> {
    this.saveProgress();
  }

  submitForm(): void {
    this.captureSummary();
  }
}
