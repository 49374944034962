import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as Constants from "../../../../constants/constants";
import { ValidationService } from 'src/app/services/validators/validation.service';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from '@angular/common';
import { delay, extractNumericValue, generateRandomNumber, truncateString } from 'src/app/util/Helper';
import { createAmountAbove5KValidator } from 'src/app/services/validators/custom.validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step7-payment',
  templateUrl: './step7-payment.component.html',
  styleUrls: ['./step7-payment.component.scss']
})
export class Step7PaymentComponent {

  PaymentModeOptions: any = [
    'M-Pesa',
    'Cash Deposits/Bank Transfer'
  ];
  BranchPlaceHolders: any = [];

  title: string = 'Make your initial capital deposit';
  subtitle: string = 'To activate your account, please deposit a minimum of Kes 5,000 via M-Pesa or bank transfer. If amount was already deposited, enter the transaction details as below';

  paymentModeLabel: string = 'Preferred Mode of Payment';
  phoneLabel: string = 'M-Pesa Phone Number';
  investmentAmountLabel: string = 'Initial Investment Amount';
  investmentAmountHint: string = 'Enter initial investment amount';
  investmentAmountSubtext: string = 'The minimum amount should be Kes 5,000';

  bankLabel: string = 'Bank Name';
  bankHint: string = 'Select Bank';

  bankBranchLabel: string = 'Bank Branch';
  bankBranchHint: string = 'Select Bank Branch';

  accNameLabel: string = 'Account Name';
  accNameHint: string = 'Enter Account Name';

  accLabel: string = 'Account Number';
  accHint: string = 'Enter Account Number';

  uploadSlipLabel: string = 'Upload Bank Slip/Remittance Advice';

  instructionsTitle: string = 'Payment Instructions';
  instructionsTitle2: string = 'Having Issues with M-Pesa Express?';

  instructionsTitleBank: string = 'ICEA LION Trust Co. Bank Deposit/Transfer Details';
  inst1: string = 'Enter your phone number and the amount you wish to pay';
  inst2: string = 'Click on the "Submit Button" below. You should receive a prompt on your phone to enter your M-Pesa PIN';
  inst3: string = 'You will receive a notification on your email once your transaction is complete';
  
  inst4: string = 'You can deposit directly via M-Pesa Paybill Number';
  inst5: string = 'Paybill Business number: ';
  inst6: string = 'Account number: ';

  iceaFundLabel: string = 'Fund';
  iceaAcc: string = 'Account Number';
  iceaBank: string = 'Bank';
  iceaBranch: string = 'Branch';
  iceaBankCode: string = 'Bank Code';
  iceaBranchCode: string = 'Branch Code';
  iceaClearing: string = 'Clearing';

  iceaFundNameVal  : string = Constants.ICEA_FUND_NAME;
  iceaAccountVal : string = Constants.ICEA_ACCOUNT_NUM;
  iceaBankVal : string = Constants.ICEA_BANK;
  iceaBranchVal : string = Constants.ICEA_BANK_BRANCH;
  iceaBankCodeVal : string = Constants.ICEA_BANK_CODE;
  iceaBranchCodeVal : string = Constants.ICEA_BRANCH_CODE;
  iceaClearingVal : string = Constants.ICEA_CLEARING;

  requiredFieldString: string = 'Required field';

  loadingBanks: boolean = false;
  loadingBankBranches: boolean = false;

  loading: boolean = false;
  buttonText: string = 'Processing Payment';

  banksEmpty: boolean = true;
  bankBranchesEmpty: boolean = true;

  ccEmails: any = [];

  formErrors: { [key: string]: string } = {
    PaymentMode: '',
    amount: '',
    phone: '',
    bank: '',
    bankBranch: '',
    accName: '',
    acc: '',
    slipDoc: '',
    email: '',
  };
  validationMessages: { [key: string]: {} } = {
    PaymentMode: { required: this.requiredFieldString },
    amount: { required: this.requiredFieldString, cMin:'Invalid amount' },
    phone: { required: this.requiredFieldString, invalid:'Invalid phone number' },
    bank: { required: this.requiredFieldString },
    bankBranch: { required: this.requiredFieldString },
    accName: { required: this.requiredFieldString },
    acc: { required: this.requiredFieldString },
    slipDoc: { required: this.requiredFieldString },
    email: { required: this.requiredFieldString, email: "Invalid email address" },
  };

  // Form
  paymentForm = this._formBuilder.group({
    PaymentMode: [this.PaymentModeOptions[0], Validators.required],
    amount: ['', [Validators.required, createAmountAbove5KValidator()]],
    // amount: ['', Validators.required],  // UAT remove 5k limit
    phone: ['', Validators.required],
    
    bank: [{value: '', disabled: true}],
    bankBranch: [{value: '', disabled: true}],
    accName: [''],
    acc: [''],
    slipDoc: [],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    private apiService: ApiService,
    private tabControlService: TabControlService,
    private currencyPipe: CurrencyPipe,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // if (this.BanksOptions.length === 0)  {this.getBanksList()}
    // else {this.paymentForm.get('bank')?.enable()}

    if (this.settlors && this.settlors.length > 0) {
      if (this.settlorType === 0) {
        if (this.settlors.Authorisers && this.settlors.Authorisers.length > 0) {
          for (let i=0; i<this.settlors.Authorisers.length; i++) {
            const item = {
              FirstName: this.settlors.Authorisers[i].FirstName,
              Email: this.settlors.Authorisers[i].Email
            };

            this.ccEmails.push(item);
          }
        }
      
      } else {  // Remove this section if normal settlors should not receive this email
        for (let i=1; i<this.settlors.length; i++) {
          const item = {
            FirstName: this.settlors[i].FirstName,
            Email: this.settlors[i].Email
          };

          this.ccEmails.push(item);
        }
      }   
    }

    if (this.paymentData !== false && Object.keys(this.paymentData).length !== 0) {
      this.paymentForm = this._formBuilder.group({
        PaymentMode: [this.paymentData.PaymentMode || this.PaymentModeOptions[0], Validators.required],
        amount: [this.paymentData.amount || '', [Validators.required, createAmountAbove5KValidator()]],
        phone: [this.paymentData.phone || '', Validators.required],
        bank: [{value: '', disabled: true}],
        bankBranch: [{value: '', disabled: true}],
        accName: [this.paymentData.accName || ''],
        acc: [this.paymentData.acc || ''],
        slipDoc: [],
      });

    } else {
      let paymentId = `MIL-${this.tabControlService.settlors[0].Phone}-${truncateString(this.tabControlService.trustInfo.TrustName)}-${generateRandomNumber()}`.replace('+', '');

      if (this.tabControlService.settlorType == 1) {
        paymentId = `MIL-${this.tabControlService.settlors[0].IDNo}-${truncateString(this.tabControlService.trustInfo.TrustName)}-${generateRandomNumber()}`;
      }
      this.tabControlService.paymentID = paymentId;
    }

    this.paymentForm.valueChanges.subscribe((form) => {
      if (form.amount) {
        this.paymentForm.patchValue({
          // First replace: remove strings, 2nd replace: leading zeros
          amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), 'KSH  ', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });

    this.paymentForm.get('PaymentMode')?.valueChanges.subscribe((data) => {
      if (data === 'M-Pesa') {
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('phone')!);

        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('bank')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('bankBranch')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('accName')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('acc')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('slipDoc')!);
      
      } else {
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('bank')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('bankBranch')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('accName')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('acc')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('slipDoc')!);

        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('phone')!);

        if (this.BanksOptions.length === 0) this.getBanksList();
        else this.paymentForm.get('bank')?.enable()
      }
    });
  }

  get settlors(): any {
    return this.tabControlService.settlors;
  }

  get settlorType(): number {
    return this.tabControlService.settlorType;
  }

  get BanksOptions(): any {
    return this.tabControlService.BanksOptions;
  }
  get stkLoading(): boolean {
    return this.tabControlService.stkLoading;
  }

  get paymentID(): string {
    return this.tabControlService.paymentID;
  }

  get paymentData(): any {
    return this.tabControlService.paymentData;
  }

  get summary(): string {
    return this.tabControlService.summary;
  }

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  async getBanksList() {
    this.loadingBanks = true;

    const response = await this.tabControlService.getBanksList();
    if (response === 1) {
      this.paymentForm.get('bank')?.enable();
      this.banksEmpty = false;
    } else {
      this.banksEmpty = true;
    }
    this.loadingBanks = false;
  }

  async populateBankBranchesList() {
    this.loadingBankBranches = true;
    this.BranchPlaceHolders = [];
    
    try {
      const bankValue = this.paymentForm.get('bank')?.value;
  
      if (!(bankValue === null || bankValue ==='' || bankValue === undefined)) {
        
        const branchRequest = {
          BankID: `${bankValue}`,
        };
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bankBranchesURL,
          branchRequest
        );

        if (response.Status === 1) {
          this.BranchPlaceHolders = response.Data;
          this.paymentForm.get('bankBranch')?.enable();
          this.bankBranchesEmpty = false;
        } else {
          console.log('>>> Error !! ', response.Message);
          this.bankBranchesEmpty = true;
        }
        this.loadingBankBranches = false;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingBankBranches = false;
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.paymentForm,
      this.formErrors,
      this.validationMessages
    );
  }

  slipObject: any = null;
  updateSlipObject(object: any): void {
    this.slipObject = object;
  }

  async mpesaPayment(): Promise<void> {
    this.buttonText = 'Processing Payment';
    this.loading = true;
    try {
      const nakedAmount = extractNumericValue(this.paymentForm.controls['amount'].value);

      const data = {
        Amount: nakedAmount,
        Phone: this.paymentForm.value.phone,
        BillRefNo: this.paymentID,
        TrustName: this.tabControlService.trustInfo.TrustName,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.mpesaPaymentURL, data);

      if (response.Status === 1) {
        this.tabControlService.viewToast('info', 'M-Pesa Payment requested');
        this.tabControlService.updateSTK(this.paymentID, nakedAmount, this.paymentForm.value.phone as string);
        this.tabControlService.stkPinText = this.tabControlService.stkDefPinText;
        this.tabControlService.stkLoading = true;

        await delay(30000);
        await this.retryMpesaPaymentConfirmation()
        .then(async (result) => {
          // console.log('Final Result:', result);

          if (result.Status === 1) {
            this.tabControlService.viewToast('success', 'Payment received');
            this.tabControlService.stkLoading = false;
            
          } else if (result.Status === 2) {
            this.tabControlService.viewToast('error', 'Could not confirm your payment');
            this.tabControlService.stkTimeout = true;
            
          } else {
            // Error occurred!!
            this.tabControlService.viewToast('error', result.Message);

            this.tabControlService.stkTimeout = true;
          }
          
        })
        .catch((error) => {
          console.error('Error:', error);
          this.tabControlService.viewToast('error', 'An unexpected error occurred');
        }); 
      } else {
        console.log('>>> Error !!', response.Message)
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
    }
    this.loading = false;
  }

  async retryMpesaPaymentConfirmation(maxAttempts: number = 5): Promise<any> {
    let attempts = 0;

    const confirmMpesaPayment = async (): Promise<any> => {
      try {
        const excelFile = this.generateExcelFileJSON();
        if (excelFile == 'error') {
          this.tabControlService.viewToast('error', 'A critical error occurred');
          return;
        }
        const data = {
          BillRefNo: this.paymentID,
          TrustID: this.tabControlService.TrustID,
          TrustName: this.tabControlService.trustInfo.TrustName.replace(' ', '-'),
          Amount: extractNumericValue(this.paymentForm.controls['amount'].value),
          Name: this.tabControlService.basicData.FirstName,
          Email: this.tabControlService.basicData.Email,
          CcEmails: this.ccEmails,
          Phone: this.paymentForm.value.phone,
          Summary: this.summary,
          Docs: this.tabControlService.docSummary,
          ExcelData: excelFile
        };
  
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.mpesaConfirmURL, data);
  
        console.log(':::: RESPONSE !!!!\n', response);
  
        if (response && response.Status) {
          return response;
        } else {
          return {
            Status: 0,
            Message: 'Error contacting server'
          };
  
        }
        
      } catch (error) {
        let resp = {
          Status: 0,
          Message: 'An unexpected error occurred'
        }
        console.log(':: Error !! ', error);
  
        return resp;
      }
    }
  
    async function tryConfirmation() {
      attempts++;
      console.log(`Attempt ${attempts}`);
  
      const response = await confirmMpesaPayment();
  
      if (response.Status === 1) {
        // console.log('Payment confirmed successfully');
        return response;
        
      } else if (response.Status === 2) {
        if (attempts < maxAttempts) {
          await delay(15000); // Wait for 15 seconds
          return tryConfirmation();
        } else {
          // console.log('Max retry attempts reached');
          return {
            Status: 2,
            Message: 'Max retry attempts reached'
          };
        }
        
      } else {
        // console.log('Max retry attempts reached');
        return {
          Status: 0,
          Message: response.Message
        };
      }
    }
  
    return tryConfirmation();
  }

  async rtgsPayment(): Promise<void> {
    this.buttonText = 'Processing Payment';
    this.loading = true;
    this.tabControlService.isLoading(true);

    const excelFile = this.generateExcelFileJSON();
    if (excelFile == 'error') {
      this.tabControlService.viewToast('error', 'A critical error occurred');
      return;
    }
    try {
      const data = {
        Amount: extractNumericValue(this.paymentForm.controls['amount'].value),
        BankName: this.paymentForm.value.bank,
        BankBranch: this.paymentForm.value.bankBranch,
        AccountName: this.paymentForm.value.accName,
        AccountNo: this.paymentForm.value.acc,
        BankSlipUrl: this.slipObject,
        Name: this.tabControlService.basicData.FirstName,
        Email: this.tabControlService.basicData.Email,
        CcEmails: this.ccEmails,
        Summary: this.summary,
        Docs: this.tabControlService.docSummary,
        ExcelData: excelFile,
        TrustName: this.tabControlService.trustInfo.TrustName.replace(' ', '-'),
        TrustID: this.tabControlService.TrustID
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.rtgsPaymentURL, data);

      if (response.Status === 1) {
        this.tabControlService.viewToast('success', 'Payment Slip received');

      } else {
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    
    this.tabControlService.isLoading(false);
    this.loading = false;
  }

  generateExcelFileJSON(): string {
    let formattedData = this.tabControlService.stripSavedObjects(this.tabControlService.storageObject);
    formattedData = this.processObject(formattedData);
    
    let formattedAuthorisers = null;
    let formattedContactPersons: any = [];
    let formattedGuardianBens: any = [];

    if (formattedData.basicData.hasOwnProperty('Verified')) {
      delete formattedData.basicData['Verified'];
    }

    // Settlors
    if (formattedData.settlors[0].Authorisers && formattedData.settlors[0].Authorisers.length > 0) {
      formattedAuthorisers = formattedData.settlors[0].Authorisers;

      if (formattedData.settlors[0].hasOwnProperty('Authorisers')) {
        delete formattedData.settlors[0]['Authorisers'];
      }
    }
    for (let i=0;i<formattedData.settlors.length; i++) {
      if (formattedData.settlors[i].hasOwnProperty('infoDone')) {
        delete formattedData.settlors[i]['infoDone'];
      }
      if (formattedData.settlors[i].hasOwnProperty('verified')) {
        delete formattedData.settlors[i]['verified'];
      }
      if (formattedData.settlors[i].hasOwnProperty('authDone')) {
        delete formattedData.settlors[i]['authDone'];
      }
      if (formattedData.settlors[i].hasOwnProperty('docsDone')) {
        delete formattedData.settlors[i]['docsDone'];
      }
      if (formattedData.settlors[i].hasOwnProperty('done')) {
        delete formattedData.settlors[i]['done'];
      }
      if (formattedData.settlors[i].hasOwnProperty('IDNoImage')) {
        delete formattedData.settlors[i]['IDNoImage'];
      }
      if (formattedData.settlors[i].hasOwnProperty('KraPinImage')) {
        delete formattedData.settlors[i]['KraPinImage'];
      }
      if (this.tabControlService.settlorType === 1 && formattedData.settlors[i].hasOwnProperty('Principal')) {
        delete formattedData.settlors[i]['Principal'];
      }
    }

    // Beneficiaries
    for (let i=0; i<formattedData.beneficiaries.length; i++) {
      if (formattedData.beneficiaries[i].ContactPersons && formattedData.beneficiaries[i].ContactPersons.length > 0) {
        for (let j=0; j<formattedData.beneficiaries[i].ContactPersons.length; j++) {
          let contact = formattedData.beneficiaries[i].ContactPersons[j];
          contact = {...contact, ...{'Linked Beneficiary': formattedData.beneficiaries[i].BeneficiaryID} };

          formattedContactPersons.push(contact);
        }
      }
      
      if (formattedData.beneficiaries[i].hasOwnProperty('ContactPersons')) {
        delete formattedData.beneficiaries[i]['ContactPersons'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('personalDone')) {
        delete formattedData.beneficiaries[i]['personalDone'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('contactsDone')) {
        delete formattedData.beneficiaries[i]['contactsDone'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('done')) {
        delete formattedData.beneficiaries[i]['done'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('IDNoImage')) {
        delete formattedData.beneficiaries[i]['IDNoImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('RegCertImage')) {
        delete formattedData.beneficiaries[i]['RegCertImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('KraPinImage')) {
        delete formattedData.beneficiaries[i]['KraPinImage'];
      }
    }
    if (formattedContactPersons.length === 0) {
      formattedContactPersons = null;
    }

    // Guardians
    for (let i=0; i<formattedData.guardians.length; i++) {
      if (formattedData.guardians[i].GuardianBens && formattedData.guardians[i].GuardianBens.length > 0) {
        for (let j=0; j<formattedData.guardians[i].GuardianBens.length; j++) {
          let contact = formattedData.guardians[i].GuardianBens[j];

          contact.GuardianID = formattedData.guardians[i].GuardianID;
          contact = {
            ...contact,
            ...{
              BeneficiaryName: ``,
              GuardianName: `${formattedData.guardians[i].FirstName} ${formattedData.guardians[i].LastName}` 
            } };

          if (contact.hasOwnProperty('BeneficiaryIDs')) {
            delete contact['BeneficiaryIDs'];
          }
          if (contact.hasOwnProperty('TrustID')) {
            delete contact['TrustID'];
          }
          if (contact.hasOwnProperty('ID')) {
            delete contact['ID'];
          }
          formattedGuardianBens.push(contact);
        }
      }
      
      if (formattedData.guardians[i].hasOwnProperty('GuardianBens')) {
        delete formattedData.guardians[i]['GuardianBens'];
      }

      if (formattedData.guardians[i].hasOwnProperty('verified')) {
        delete formattedData.guardians[i]['verified'];
      }
      if (formattedData.guardians[i].hasOwnProperty('done')) {
        delete formattedData.guardians[i]['done'];
      }
      if (formattedData.guardians[i].hasOwnProperty('IDNoImage')) {
        delete formattedData.guardians[i]['IDNoImage'];
      }
      if (formattedData.guardians[i].hasOwnProperty('KraPinImage')) {
        delete formattedData.guardians[i]['KraPinImage'];
      }
    }
    if (formattedGuardianBens.length === 0) {
      formattedGuardianBens = null;
    }

    let excelData = {
      'Personal Information': formattedData.basicData,
      'Settlors': formattedData.settlors, //array
      'Beneficiaries': formattedData.beneficiaries, //array
      'Guardians': formattedData.guardians, //array
      'Trust Information': formattedData.trustInfo,
    };

    if (formattedAuthorisers) {
      excelData = {...excelData, ...{'Settlor-Authorisers': formattedAuthorisers} };
    }
    if (formattedGuardianBens) {
      excelData = {...excelData, ...{'Beneficiary-Guardian-Link': formattedGuardianBens} };
    }
    if (formattedContactPersons) {
      excelData = {...excelData, ...{'Beneficiary-Contact-Persons': formattedContactPersons} };
    }

    try {
      return JSON.stringify(excelData);
    } catch (error) {
      console.error(error);
      return 'error';
    }
  }

  processObject(obj: any): any {
    // Check if the input is an array
    if (Array.isArray(obj)) {
        // If the array contains strings, join them with commas
        if (typeof obj[0] === 'string') {
            return obj.join(', ');
        }
        // If the array contains objects, process each object recursively
        else {
            return obj.map((item: any) => this.processObject(item));
        }
    }
    
    // If the input is an object, process its keys and values
    if (typeof obj === 'object' && obj !== null) {
        const result: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // Rename 'PercShare' to 'SharePercentage'
                const newKey = key === 'PercShare' ? 'SharePercentage' : key;
                // Recursively process nested objects or arrays
                result[newKey] = this.processObject(obj[key]);
            }
        }
        return result;
    }
    
    // If the input is neither an object nor an array, return it as is
    return obj;
}

  submitForm(): void {
    if (this.paymentForm.value.PaymentMode === 'M-Pesa') {
      this.mpesaPayment();
    } else {
      this.rtgsPayment();
    }
    this.tabControlService.updateRecord(7, this.paymentForm.value);

    const nextPage = `milele-trust/success`;
    this.router.navigate([nextPage]);
  }

  async updateProgress(): Promise<void> {
    this.saveProgress();
  }
}
