export const ICEA_FUND_NAME : string = 'ICEA LION TRUST COMPANY LIMITED - MILELE A/C';
export const ICEA_ACCOUNT_NUM: string = '1904210019';
export const ICEA_BANK: string = 'NCBA BANK KENYA plc';
export const ICEA_BANK_BRANCH: string = 'CITY CENTER';
export const ICEA_BANK_CODE: string = '101';
export const ICEA_BRANCH_CODE: string = '07000';
export const ICEA_CLEARING: string = 'CBAFKENX';

export const estatePlanningBrochureName: string = 'Estate Planning.pdf';
export const trustFundBrochureName: string = 'Trust Fund.pdf';

const path = '/Api';
// URL PATHS:
export const otpRequestURL: string = `${path}/otp/request`;
export const otpValidateURL: string = `${path}/otp/validate`;

export const banksURL: string = `${path}/data/banks`;
export const bankBranchesURL: string = `${path}/data/banks/branches`;

export const fundSourceURL: string = `${path}/data/fund-source`;
export const purposeURL: string = `${path}/data/trust-purpose`;
export const instructorOptURL: string = `${path}/data/account-mandate`;
export const prefModeURL: string = `${path}/data/preffered-modes`; // missing
export const upkeepURL: string = `${path}/data/upkeep`;
export const businessNatureURL: string = `${path}/data/business-nature`;

export const salutationsURL: string = `${path}/data/salutations`;
export const countriesURL: string = `${path}/data/countries`;
export const countiesURL: string = `${path}/data/counties`;

export const consultantsURL: string = `${path}/data/consultants`;
export const intermediaryURL: string = `${path}/data/intermediaries?`;

export const saveSettlorURL: string = `${path}/save/settlor`;
export const saveBenURL: string = `${path}/save/beneficiary`;
export const saveGuardianURL: string = `${path}/save/guardian`;
export const saveTrustURL: string = `${path}/save/trust`;

export const bookAppointmentEstateURL: string = `${path}/estate/help/book-appointment`;
export const saveAppointmentSettlorsEstateURL: string = `${path}/estate/help/book-appointment/settlors`;

export const linkGuardianBeneficiaryURL: string = `${path}/link/guardian-beneficiary`;

export const deleteRecordURL: string = `${path}/records/remove`;

export const continueLaterURL: string = `${path}/progress/save`;
export const continueNowURL: string = `${path}/progress/resume`;
export const bookCallURL: string = `${path}/help/book-call`;

export const mpesaPaymentURL: string = `${path}/payment/mpesa/initiate`;
export const mpesaConfirmURL: string = `${path}/payment/mpesa/confirm`;
export const rtgsPaymentURL: string = `${path}/payment/rtgs`;
