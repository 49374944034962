import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { checkTime } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';


@Component({
  selector: 'app-step1-settlor',
  templateUrl: './step1-settlor.component.html',
  styleUrls: ['./step1-settlor.component.scss']
})
export class Step1SettlorComponent implements OnInit {

  checkerPath: string = '../../../../assets/fi_check.png';

  formTitle: string = 'Settlor Information';
  formSubtitle: string = 'Please enter the settlor details below';

  contactInfoTitle: string = 'Settlor Contact Information';
  detailsTitle: string = 'Settlor Details';

  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  postalLabel: string = 'Postal Adress';
  postalHint: string = 'Enter Postal Address';

  addSettlorText: string = '+  Add Another Settlor';
  removeSettlorText: string = 'Remove Settlor';

  requiredFieldString: string = 'Required field';

  loading: boolean = false;
  loadingText: string = 'Requesting OTP';
  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Settlor';

  otpVerified: boolean = false;

  formErrors: { [key: string]: string } = {
    Phone: '',
    Email: '',
    
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    IDNo: '',
    KraPin: '',
    Address: '',
    PostalAdress: '',
  };

  validationMessages: { [key: string]: {} } = {
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing settlor' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing settlor' },

    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing settlor' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing settlor' },

    Address: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },
  };

  settlorForm = this._formBuilder.group({
    settlors: this._formBuilder.array([
      this.createSettlorsFormGroup()
    ]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    await this.populateForm();
  }

  get settlors(): any {
    return this.tabControlService.settlorsEstate;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async populateForm(): Promise<void> {
    if (this.settlors && this.settlors.length > 0) {
      let settle = this.settlors;

      settle.forEach(async (settlor: any, index: number) => {
        if (index > 0 && !settlor.verified) {
          settle.splice(index, 1);
          await this.tabControlService.updateRecordEstate(1, settle);
        }
      });

      // if (this.settlors.length > 1 && this.settlors[0] && this.settlors[0].verified == false) {
      //   const truncated = this.settlors.slice(0,1);
      //   await this.tabControlService.updateRecordEstate(1, truncated);
      // }

      if (this.settlors[0] && this.settlors[0].verified && this.settlors[0].verified == true) {
        const settlorsArray = this.settlorForm.get('settlors') as FormArray;

        // Clear existing settlors
        while (settlorsArray.length !== 0) {
          settlorsArray.removeAt(0);
        }
        
        // Add settlors from storage
        this.settlors.forEach((settlor: any) => {
          if (settlor.verified) settlorsArray.push(this.createSettlorsFormGroup(settlor));
        });
      }
    }
  }

  createSettlorsFormGroup(settlor: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', settlor);

    if (settlor) {
      form = this._formBuilder.group({
        Phone: [`${settlor.Phone || ''}`, Validators.required],
        Email: [`${settlor.Email || ''}`, [Validators.required, Validators.email]],
        
        Title: [`${settlor.Title || ''}`],
        FirstName: [`${settlor.FirstName || ''}`],
        MiddleName: [`${settlor.MiddleName || ''}`,],
        LastName: [`${settlor.LastName || ''}`],
        IDNo: [`${settlor.IDNo || ''}`],
        KraPin: [`${settlor.KraPin || ''}`],
        
        Address: [`${settlor.Address || ''}`],
        PostalAddress: [`${settlor.PostalAddress || ''}`],
      });

      if (settlor.verified == true) {
        this.otpVerified = true;
        form.get('Title')?.addValidators([Validators.required]);
        form.get('FirstName')?.addValidators([Validators.required]);
        form.get('LastName')?.addValidators([Validators.required]);
        form.get('IDNo')?.addValidators([Validators.required, Validators.min(4)]);
        form.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
        form.get('Address')?.addValidators([Validators.required]);
        form.get('PostalAddress')?.addValidators([Validators.required]);

        if (this.settlorForm && this.getSettlors().length > 0 && settlor.done == false) {
          this.validationService.addOrRemove3Validators(
            true,
            form.get('Email')!,
            [Validators.required,
              Validators.email,
              createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemoveSpecificValidators(
            true,
            form.get('Phone')!,
            [Validators.required,
              createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemove3Validators(
            true,
            form.get('IDNo')!,
            [Validators.required,
              Validators.min(4),
              createIDsValidatorEstate('ID', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemove3Validators(
            true,
            form.get('KraPin')!,
            [Validators.required,
              Validators.pattern(/^[AP]\d{9}[A-Z]$/),
              createIDsValidatorEstate('PIN', this.getSettlors(), this.settlors)
            ]
          );
        }
      }

      // console.log('Current record verified: ', this.currentRecordVerified, '\n', settlor);
    } else {
      if (
        this.settlors && this.settlors.length > 0 && this.settlors[0].verified == false
      ) {
          this.tabControlService.refreshRecordEstate(1);
      }

      this.otpVerified = false;
      let currentSettlors = this.settlors;
      let currentRecord: any = {verified: false, done: false};

      form = this._formBuilder.group({
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        
        Title: [''],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        IDNo: [''],
        KraPin: [''],
        Address: [''],
        PostalAddress: [''],
      });

      if (this.settlorForm && this.getSettlors().length > 0) {
        this.validationService.addOrRemove3Validators(
          true,
          form.get('Email')!,
          [Validators.required,
            Validators.email,
            createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemoveSpecificValidators(
          true,
          form.get('Phone')!,
          [Validators.required,
            createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
          ]
        );
      }

      if (
        this.settlors.length == 0 ||
        this.settlors.length > 0 && this.settlors[this.settlors.length-1].done == true
      ) {
        currentSettlors.push(currentRecord);
        this.tabControlService.updateRecordEstate(1, currentSettlors);
      }
      
    }

    form.updateValueAndValidity();

    return form;
  };

  async addNewSettlor() {
    const fields = this.settlorForm.get('settlors') as FormArray;
    fields.push(this.createSettlorsFormGroup());
    this.settlorForm.setControl('settlors', fields);
  }
  // TODO: Update Remove function for Estate Settlors
  async removeSettlor(i:number) {
    this.currentIndex = i;
    const fields = this.settlorForm.get('settlors') as FormArray;
    let currentSettlors = this.settlors;
    
    if (currentSettlors[i] && currentSettlors[i].SettlorID) {
      this.removeButtonText = 'Removing Settlor';
      this.loadingRemove = true;
      
      try {
        if (this.tabControlService.TrustID !== null) {
          const result = await this.tabControlService.removeRecord(
            'Settlers',
            currentSettlors[i].SettlorID,
            parseInt(this.tabControlService.TrustID));

          if (result === 1) {
            this.tabControlService.viewToast('success', 'Settlor removed successfully');
            fields.removeAt(i);
            // Remove settlor at index
            currentSettlors.splice(i, 1);;
            await this.tabControlService.updateRecordEstate(1, currentSettlors);
          } else {
            this.tabControlService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Settlor';
      this.loadingRemove = false;
    } else {
      if (currentSettlors[i] && currentSettlors[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentSettlors.splice(i, 1);;
        await this.tabControlService.updateRecordEstate(1, currentSettlors);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getSettlors() : FormArray {  
    return this.settlorForm.get("settlors") as FormArray  
  }
  getSettlorFormGroup(index: number): FormGroup {
    const settlors = this.settlorForm.get('settlors') as FormArray;
    return settlors.at(index) as FormGroup;
  }

  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.loading = true;
    this.tabControlService.isLoading(true);

    try {
      const records = this.settlorForm.controls['settlors'].value;
      let data = {
        FirstName: 'Settlor',
        LastName: '',
        Phone: records[records.length-1].Phone,
        Email: records[records.length-1].Email
      };

      this.tabControlService.OTPMessage = 'In order to proceed, please enter the One Time Password (OTP) sent to the Settlor\'s email and phone';
      // console.log('Dat ', data);

      let savedTime = this.tabControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.tabControlService.OTPValid = checkTime(savedTime.time);

      if (this.tabControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        let currentSettlors = this.settlors;
        let currentRecord = currentSettlors[currentSettlors.length-1];

        currentRecord = {...currentRecord, ...records[records.length-1]};
        
        Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        await this.tabControlService.updateRecordEstate(1, currentSettlors);
        
        if (!this.tabControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.tabControlService.OTPRequestStats = timeStats;
          this.tabControlService.OTPValid = true;

          this.tabControlService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.tabControlService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.tabControlService.OTPRequestPage = 'SettlorEstate';
        this.tabControlService.OTPIndex = this.settlors.length-1;
        
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.group('OTP Dialog ', this.otpVerified);
          if (this.otpVerified == true) {
            let currentSettlors = this.settlors;
            let currentRecord = currentSettlors[currentSettlors.length-1];
            currentRecord.verified = true;

            Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
            this.tabControlService.updateRecordEstate(1, currentSettlors);

            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('Title')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('FirstName')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('LastName')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('Address')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('PostalAddress')!);

            this.validationService.addOrRemoveSpecificValidators(
              true,
              this.getSettlorFormGroup(this.settlors.length - 1).get('IDNo')!,
              [Validators.required, Validators.min(4)]
            );
            this.validationService.addOrRemoveSpecificValidators(
              true,
              this.getSettlorFormGroup(this.settlors.length - 1).get('KraPin')!,
              [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]
            );

            if (this.settlorForm && this.getSettlors().length > 0) {
              this.validationService.addOrRemove3Validators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('IDNo')!,
                [Validators.required,
                  Validators.min(4),
                  createIDsValidatorEstate('ID', this.getSettlors(), this.settlors)
                ]
              );
              this.validationService.addOrRemove3Validators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('KraPin')!,
                [Validators.required,
                  Validators.pattern(/^[AP]\d{9}[A-Z]$/),
                  createIDsValidatorEstate('PIN', this.getSettlors(), this.settlors)
                ]
              );
            }

          } else {
            this.tabControlService.viewToast('info', 'Please verify your details to proceed');
          }
          console.groupEnd();
        });
      } else {
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'OTP request failed');

    }
    this.tabControlService.isLoading(false);
    this.loading = false;
  }
  async saveSettlor(): Promise<void> {
    this.loadingText = 'Saving Settlor';

    this.loading = true;
    this.tabControlService.isLoading(true);

    try {
      const settlors = this.settlorForm.get('settlors') as FormArray;
      const record: FormGroup = this.getSettlorFormGroup(settlors.length - 1) as FormGroup;

      if (this.tabControlService.appointmentID === null) {
        const _data = {
          AppointmentType: '',
          Email: '',
          Date: '',
          Time: '',
          NumberOfPersons: '',
          Location: '',
          BookingID: '',
        };

        const _response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bookAppointmentEstateURL, _data, false);

        if (_response.Status === 1 && _response.BookingID) {
          this.tabControlService.appointmentID = _response.BookingID;
          
        } else {
          console.error(_response);
          this.tabControlService.viewToast('error', 'Could not save your details', _response.Message);
          return;
        }
      }
      
      let data = {
        ...record.value,
        ...{
          BookAppoitmentID: this.tabControlService.appointmentID,
          TrustType: 'Settler'
        }
      };

      let currentSettlors = this.tabControlService.settlorsEstate;

      if (currentSettlors[currentSettlors.length - 1].SettlorID) {
        data = {...data, ...{SettlorID: currentSettlors[currentSettlors.length - 1].SettlorID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const latestRecord = currentSettlors[currentSettlors.length - 1];
        let updatedRecord = {...latestRecord, ...record.value, ...{SettlorID: response.SettlorID}};
        updatedRecord.done = true;
        Object.assign(currentSettlors[currentSettlors.length - 1], updatedRecord);

        await this.tabControlService.updateRecordEstate(1, currentSettlors);
        console.log('>> New Settlors !! \n', this.tabControlService.settlors);

        this.tabControlService.viewToast('success', 'Settlor saved successfully');

      } else {
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'An unexpected error occurred');
    }
    this.tabControlService.isLoading(false);
    this.loading = false;
  }

  async submitForm(): Promise<void> {
    if (this.settlors[this.settlors.length-1].done) {
      this.navigateFunction();
    } else {
      if (this.settlors[this.settlors.length-1].verified) {
        this.saveSettlor();
      } else {
        this.openOtpModal();
      }
    }
  }
  async navigateFunction(): Promise<void> {
    await this.tabControlService.changeTabEstate(2);
    this.tabControlService.page1DoneEstate = true;
  }
}
