import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../constants/constants";
import { checkTime } from 'src/app/util/Helper';

@Component({
  selector: 'app-step1-basic',
  templateUrl: './step1-basic.component.html',
  styleUrls: ['./step1-basic.component.scss']
})
export class Step1BasicComponent {
  title: string = 'Milele Trust Application Process';
  subtitle: string = 'Tell us about yourself';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Your email address';

  disclaimer: string = 'Please note that we will be saving your information as you proceed along the steps';

  requiredFieldString: string = 'Required field';

  formErrors: { [key: string]: string } = {
    Title: '',
    firstName: '',
    middleName: '',
    surname: '',
    phone: '',
    email: '',
  };
  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    firstName: { required: this.requiredFieldString },
    middleName: { required: this.requiredFieldString },
    surname: { required: this.requiredFieldString },
    phone: { required: this.requiredFieldString, invalid:'Invalid phone number' },
    email: { required: this.requiredFieldString, email: "Invalid email address" },
  };

  // Form
  basicForm = this._formBuilder.group({
    Title: [''],
    firstName: ['', Validators.required],
    middleName: [''],
    surname: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  otpVerified: boolean = false;
  basicVerified: boolean = false;
  loading: boolean = false;
  loadingText: string = 'Requesting OTP';


  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
  }

  async ngOnInit(): Promise<void> {
    const storageData = await this.tabControlService.getData(this.tabControlService.storageKey);
    if (storageData && storageData.basicData) {
      const basicData = storageData.basicData;

      this.basicForm = this._formBuilder.group({
        Title: [basicData.Title || ''],
        firstName: [`${basicData.FirstName || ''}`, Validators.required],
        middleName: [`${basicData.MiddleName || ''}`],
        surname: [`${basicData.LastName || ''}`, Validators.required],
        phone: [`${basicData.Phone || ''}`, Validators.required],
        email: [`${basicData.Email || ''}`, [Validators.required, Validators.email]],
      });

      if (basicData.Verified) {
        this.basicVerified = true;
      }
    }

    if (this.tabControlService.activeTab == 1) {
      this.populateData();
    } else if (this.tabControlService.activeTab > 1) {
      this.otpVerified = true;
      this.basicVerified = true;
    }
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  get basicData(): any {
    return this.tabControlService.basicData;
  }
  
  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList().then(()=> {
        if (this.TitleOptions.length === 0) this.basicForm.get('Title')?.disable();
      });
      this.tabControlService.loadingTitles = false;
    }
  }
  

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.basicForm,
      this.formErrors,
      this.validationMessages
    );
  }

  // Request OTP and open modal
  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.loading = true;
    this.tabControlService.isLoading(true);
    try {
      const data = {
        FirstName: this.basicForm.controls['firstName'].value,
        LastName: this.basicForm.controls['surname'].value,
        Phone: this.basicForm.controls['phone'].value,
        Email: this.basicForm.controls['email'].value
      };
  
      this.tabControlService.updateRecord(1,
        {
          ...data,
          ...{
            MiddleName: this.basicForm.controls['middleName'].value,
            Title: this.basicForm.controls['Title'].value,
            Verified: false
          }
        });
  
      // console.log('GRR ', this.tabControlService.basicData);

      let savedTime = this.tabControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.tabControlService.OTPValid = checkTime(savedTime.time);

      if (this.tabControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        if (!this.tabControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.tabControlService.OTPRequestStats = timeStats;
          this.tabControlService.OTPValid = true;

          this.tabControlService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.tabControlService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.tabControlService.OTPRequestPage = 'Basic';
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(() => {
          console.group('OTP Dialog');
          this.basicVerified = this.otpVerified;
          
          if (this.basicVerified) {
            this.tabControlService.updateRecord(1,
              {
                ...data,
                ...{
                  MiddleName: this.basicForm.controls['middleName'].value,
                  Title: this.basicForm.controls['Title'].value,
                  Verified: true
                }
              });

              this.submitForm();
            
          } else {
            this.tabControlService.viewToast('info', 'Please verify your details to proceed');
          }

          console.groupEnd();
        });
      } else {
        console.log('>>> Error !!')
        this.tabControlService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.tabControlService.viewToast('error', 'OTP request failed');
    }
    this.tabControlService.isLoading(false);
    this.loading = false;
  }

  confirmValidationStatus(): boolean {
    if (this.basicData && this.basicData.FirsTName && this.basicData.LastName && this.basicData.Phone && this.basicData.Email) {
      if (
        this.basicData.FirsTName === this.basicForm.get('firstName')?.value
        && this.basicData.LastName === this.basicForm.get('surname')?.value
        && this.basicData.Phone === this.basicForm.get('phone')?.value
        && this.basicData.Email === this.basicForm.get('email')?.value
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  
  async submitForm(): Promise<void> {
    if (this.basicVerified) {
      this.tabControlService.page1Done = true;
      await this.tabControlService.changeTab(2);
    }
  }

}
