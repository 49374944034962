import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './pages/onboarding/start/start.component';
import { TrustComponent } from './pages/onboarding/trust/trust.component';
import { EstateComponent } from './pages/onboarding/estate/estate.component';
import { TrustFundComponent } from './pages/info/trust-fund/trust-fund.component';
import { EstatePlanningComponent } from './pages/info/estate-planning/estate-planning.component';
import { Step8CompleteComponent } from './pages/onboarding/trust/step8-complete/step8-complete.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/get-started'},
  { path: 'get-started', component: StartComponent},
  { path: 'trust-fund/set-up', component: TrustComponent},
  { path: 'milele-trust/success', component: Step8CompleteComponent},
  { path: 'estate-planning', component: EstateComponent},

  { path: 'info/trust-fund', component: TrustFundComponent},
  { path: 'info/estate-planning', component: EstatePlanningComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
